import React, { useEffect } from 'react';
import { useActions } from 'kea';
import { Redirect, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { QueryParamProvider } from 'use-query-params';

import Route from './utils/PrivateRoute';
import { ScrollToTop } from './utils/ScrollToTop';
import CaptureErrorBoundary from './utils/CaptureErrorBoundary';
import CaptureRouteNotFound from './utils/CaptureRouteNotFound';

import Main from 'pages/Main';
import { Landing } from 'pages/Landing';
import { Instruction } from 'pages/Instruction';
import { About } from 'pages/About';
import { Contacts } from 'pages/Contacts';
import { Settings } from 'pages/Setting';
import { CalcHistory } from 'pages/CalcHistory';
import { FourButtons } from 'pages/FourButtons';
import { FieldsPlayground } from 'pages/FieldsPlayground';
import { Calc1 } from 'pages/Calc1';
import { Calc2 } from 'pages/Calc2';
import { Calc3 } from 'pages/Calc3';
import { Calc4 } from 'pages/Calc4';
import { ActivationUser } from 'pages/ActivationUser';
import { ResetPassword } from 'pages/ResetPassword';
import Matrix from 'pages/Matrix';
import Configurator from 'pages/Configurator';

import { ActivationModal } from 'components/modals/ActivationModal';
import { ForgotPasswordModal } from 'components/modals/ForgotPasswordModal';
import { ResetPasswordModal } from 'components/modals/ResetPasswordModal';
import { ReportsModal } from 'components/modals/ReportsModal';

import { history } from 'store/initStore';

import authLogic from 'store/auth';

const MainRouter = () => {
    const { tryToDoInit, createRefresh, refreshToken } = useActions(authLogic);

    useEffect(() => {
        createRefresh();
        refreshToken();
        tryToDoInit();
    }, [tryToDoInit, createRefresh, refreshToken]);

    return (
        <ConnectedRouter history={history}>
            <QueryParamProvider history={history} ReactRouterRoute={Route}>
                <CaptureErrorBoundary>
                    <CaptureRouteNotFound>
                        <ScrollToTop />
                        <ReportsModal />
                        <ActivationModal />
                        <ForgotPasswordModal />
                        <ResetPasswordModal />
                        <Switch>
                            <Route exact path="/" blankContainer title="Главная" component={Landing} />
                            <Route exact path="/main" title="Главная" component={Main} />
                            <Route
                                exact
                                path="/hidden/user-activate/:uid/:token"
                                title="Активация пользователя"
                                component={ActivationUser}
                            />
                            <Route
                                exact
                                path="/hidden/password-reset/:uid/:token"
                                title="Сброс пароля"
                                component={ResetPassword}
                            />
                            <Route exact path="/landing" blankContainer isPrivate title="Главная" component={Landing} />
                            <Route exact path="/instruction" title="Инструкция" component={Instruction} />
                            <Route exact path="/about" title="О программе" component={About} />
                            <Route exact path="/contacts" title="Контакты" component={Contacts} />
                            <Route exact path="/settings" title="Личные данные" isPrivate component={Settings} />
                            <Route
                                exact
                                path="/buttons"
                                title="Шаги расчета"
                                isPrivate
                                blankContainer
                                component={FourButtons}
                            />
                            <Route
                                exact
                                path="/calc-history"
                                title="История расчетов"
                                isPrivate
                                component={CalcHistory}
                            />
                            <Route exact path="/calc1" isPrivate title="Новый расчет" component={Calc1} />
                            <Route exact path="/calc2" isPrivate title="Новый расчет" component={Calc2} />
                            <Route exact path="/calc3" isPrivate title="Новый расчет" component={Calc3} />
                            <Route exact path="/calc4" isPrivate title="Новый расчет" component={Calc4} />
                            {/* START sandbox routes - must be deleted in the end*/}
                            <Route exact path="/fields" title="fields-playground" component={FieldsPlayground} />
                            <Route exact path="/matrix" title="Демо матрица" component={Matrix} />
                            <Route exact path="/configurator" title="Демо конфигуратор" component={Configurator} />
                            {/* END sandbox routes - must be deleted in the end*/}
                            <Redirect to={{ state: { notFoundError: true } }} />
                        </Switch>
                    </CaptureRouteNotFound>
                </CaptureErrorBoundary>
            </QueryParamProvider>
        </ConnectedRouter>
    );
};

export default MainRouter;
