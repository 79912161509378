import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import colors from 'colors';

export { Button } from './Button';
export { Field } from './Field';
export { Checkbox } from './Checkbox';
export { GlobalModal } from './modals/GlobalModal';
export { GlobalStyles } from './GlobalStyles';
export { Loader } from './Loader';

export const Paper = styled.div`
    padding: 40px;
    box-shadow: 0 4px 40px ${colors.grayScale_200};
    background-color: ${colors.grayScale_0};
`;

export const PaperBlock = styled(Paper)`
    margin-bottom: 68px;
`;

export const Container = styled.div`
    margin: 0 auto;
    max-width: 1486px;
    width: 100%;
    padding: 0 20px;
`;

export const H1 = styled.h1`
    font-size: 36px;
    font-weight: 600;
`;

export const H2 = styled.h2`
    font-size: 30px;
    font-weight: 600;
    color: ${({ color }) => colors[color] || color || colors.main_400};
    &:after {
        content: '';
        display: block;
        margin-top: 1em;
        height: 4px;
        width: 100%;
        max-width: 9em;
        background-color: currentColor;
    }
`;
H2.propTypes = {
    color: PropTypes.string
};

export const H3 = styled.h3`
    font-size: 26px;
`;

export const TestComponent = () => <h1>This is the test component. Delete it if You see it</h1>;
