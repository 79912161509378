import styled from '@emotion/styled';
import { colors } from 'colors';

export const StyledWrap = styled.div`
    display: flex;
    flex-direction: column;
`;
export const StyledAboutWrap = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 800px;
    font-size: 16px;
    line-height: 24px;
`;

export const StyledLink = styled.a`
    display: inline-block;
    text-decoration: none;
    font-weight: bold;
    color: ${colors.main_400};
    &:hover {
        text-decoration: underline;
    }
    &:focus {
        text-decoration: underline;
    }
`;
