import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useActions, useValues } from 'kea';

import calcLogic from 'store/calculation';
import { H1, H2 } from '../index';
import colors from 'colors';
import { isNil } from 'utils/index';

const EditIcon = (props) => (
    <svg viewBox="0 0 24 24" aria-hidden="true" {...props}>
        <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
    </svg>
);

const StyledH2 = styled(H2)`
    max-width: 100%;
    margin-top: 0;
    position: relative;
`;

const StyledInput = styled.input`
    font-size: inherit;
    font-weight: inherit;
    background: inherit;
    -webkit-appearance: none;
    cursor: text;
    border: none;
    line-height: inherit;
    letter-spacing: inherit;
    color: inherit;
    width: 100%;
    padding: 0;
    position: absolute;
    &:focus {
        z-index: 10;
    }
`;

const InvisibleText = styled.span`
    display: inline-block;
    opacity: 0;
    white-space: nowrap;
    max-width: 100%;
    position: relative;
    &:before {
        content: '';
        position: absolute;
        width: 30px;
        top: 0;
        bottom: 0;
        left: 100%;
    }
`;

const StyledIcon = styled(EditIcon)`
    padding: 3px;
    fill: currentColor;
    border: 1px solid currentColor;
    color: ${colors.grayScale_400};
    border-radius: 50%;
    width: 30px;
    position: absolute;
    z-index: 5;
    top: 0;
    transform: translateY(-30%);
    cursor: pointer;
    pointer-events: none;
    opacity: 0;
    transition-property: color, opacity;
    transition-duration: 0.3s;
    transition-timing-function: ease;
    *:hover + &,
    &:hover {
        pointer-events: initial;
        opacity: 1;
    }
    &:hover {
        color: inherit;
    }
`;

export const CalcHeadings = () => {
    const {
        finalName,
        data: { id }
    } = useValues(calcLogic);
    const { setData } = useActions(calcLogic);

    const isEditable = isNil(id);

    const [draftName, setDraftName] = useState(finalName);
    useEffect(() => {
        setDraftName(finalName);
    }, [finalName]);

    const [isEditing, setIsEditing] = useState(false);
    const inputRef = useRef();

    const handleIconClick = async () => {
        await setIsEditing(true);
        inputRef.current.focus();
    };

    const handleChange = ({ target: { value } }) => setDraftName(value);

    const handleBlur = (e) => {
        if (e.type !== 'keydown' || e.key === 'Enter') {
            e.preventDefault();
            setIsEditing(false);
            setData({ name: draftName });
        }
    };

    return (
        <>
            <H1>Новый расчет</H1>
            <StyledH2>
                <StyledInput
                    type="text"
                    ref={inputRef}
                    value={draftName}
                    disabled={!isEditing}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onKeyDown={handleBlur}
                />
                <InvisibleText>{draftName}</InvisibleText>
                {!isEditing && isEditable && <StyledIcon onClick={handleIconClick} />}
            </StyledH2>
        </>
    );
};

export const MemoizedCalcHeadings = React.memo(CalcHeadings);
