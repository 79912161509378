import styled from '@emotion/styled';
import colors from 'colors';

import { Button } from 'components/Button';
import { Field } from 'components/Field';
import {
    StyledLabelText as StyledLabelTextDefault,
    StyledLabelWrapper as StyledLabelWrapperDefault
} from 'components/Field/units';
import { Checkbox } from 'components/Checkbox';

export const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
`;

export const StyledHeader = styled.div`
    font-weight: bold;
    font-size: 48px;
    line-height: 36px;
    margin-bottom: 20px;
`;

export const StyledHint = styled.div`
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: ${colors.grayScale_600};
    margin-bottom: 56px;
`;

export const StyledLink = styled.a`
    text-decoration: underline;
    cursor: pointer;
    color: ${colors.main_300};
    &:hover {
        color: ${colors.main_400};
    }
`;

export const StyledSubmit = styled(Button)`
    width: 280px;
    margin-top: 56px;
`;

export const StyledField = styled(Field)`
    max-width: 540px;
    width: 100%;
    &:not(:last-of-type) {
        margin-bottom: 20px;
    }
`;

export const StyledCheckbox = styled(Checkbox)`
    min-width: 25px;
    margin-right: 24px;
`;

export const StyledLabelText = styled(StyledLabelTextDefault)`
    max-width: 400px;
    transform: none;
    left: auto;
    position: relative;
`;

export const StyledLabelWrapper = styled(StyledLabelWrapperDefault)`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;
