import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Field } from 'components/Field';
import colors from 'colors';
import { hexToRGBA } from 'utils/index';
import { useMemo } from 'react';

const StyledWrapper = styled.div`
    display: flex;
    align-items: stretch;
    pointer-events: ${({ isBlocked }) => (isBlocked ? 'none' : 'initial')};
    opacity: ${({ isBlocked }) => (isBlocked ? '0.7' : 1)};
`;

const StyledLabel = styled.label`
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    padding: 10px 15px;
    border-radius: 5px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: ${colors.main_400};
    background-color: ${hexToRGBA(colors.main_100, 0.2)};
    cursor: ${({ onClick }) => (onClick ? 'pointer' : 'initial')};
    ${({ styles, ...rest }) => (typeof styles === 'function' ? styles(rest) : styles)};
`;

const inputStyles = css`
    text-align: center;
    font-weight: 500;
    opacity: 1 !important;
    color: ${colors.main_400};
`;

const labelWrapperStyles = css`
    display: flex;
    align-items: center;
`;

const borderConfig = {
    borderColor: colors.main_100,
    borderHoverColor: colors.main_300,
    borderFocusColor: colors.main_400
};

const wrapperStyles = css`
    max-width: 200px;
`;

export const HorizontalField = ({
    id,
    label,
    className,
    horizontalLabelStyles,
    onLabelClick,
    isBlocked = false,
    inputStyles: propInputStyles,
    ...restProps
}) => {
    const mergedInputStyles = useMemo(
        () => css`
            ${inputStyles};
            ${propInputStyles}
        `,
        [propInputStyles]
    );
    return (
        <StyledWrapper isBlocked={isBlocked} className={className}>
            <StyledLabel styles={horizontalLabelStyles} onClick={onLabelClick} htmlFor={id}>
                {label}
            </StyledLabel>
            <Field
                id={id}
                inputStyles={mergedInputStyles}
                {...{ borderConfig, wrapperStyles, labelWrapperStyles, ...restProps }}
            />
        </StyledWrapper>
    );
};

HorizontalField.propTypes = {
    id: PropTypes.string.isRequired,
    isBlocked: PropTypes.bool
};
