import PropTypes from 'prop-types';
import { kea } from 'kea';
import { toast } from 'react-toastify';

import axios from 'utils/axiosWrapper';

import petrovich from 'petrovich';

const getFormalName = (first, last) => [first, last].filter((key) => key).join(' ');

export default kea({
    path: () => ['scenes', 'user', 'data'],

    loaders: ({ values }) => ({
        userData: [
            {},
            {
                fetchUserData: async ({ silent }) => {
                    const { status, data: result = [] } = await axios({
                        method: 'get',
                        url: '/users/current_user/'
                    }).catch((e) => e);

                    if (status === 200) {
                        return result || {};
                    } else {
                        if (!silent) {
                            const error = 'Ошибка получения данных пользователя';
                            toast.error(error);
                        }
                        return {};
                    }
                },
                updateUserData: async ({ params = {} }) => {
                    if (!values.userData?.id) {
                        return values.userData;
                    }
                    const { status } = await axios({
                        method: 'patch',
                        url: `/users/${values.userData?.id}/`,
                        data: {
                            ...params
                        }
                    }).catch((e) => e);

                    if (status === 200) {
                        return { ...values.userData, ...(params || {}) };
                    } else {
                        const error = 'Ошибка получения данных пользователя';
                        toast.error(error);
                        return values.userData;
                    }
                }
            }
        ]
    }),
    selectors: ({ selectors }) => ({
        nominative: [
            () => [selectors.userData],
            ({ first_name: first, last_name: last }) => (!first && !last ? null : getFormalName(first, last)),
            PropTypes.string
        ],
        accusative: [
            () => [selectors.userData],
            ({ first_name: first, last_name: last, patronymic: middle }) => {
                try {
                    if (!middle) {
                        return null;
                    } else {
                        const result = petrovich({ first, last, middle }, 'accusative');
                        return result.gender === 'androgynous' ? null : getFormalName(result.first, result.last);
                    }
                } catch {
                    return null;
                }
            },
            PropTypes.string
        ]
    })
});
