import styled from '@emotion/styled';

const ROW_HEADER_WIDTH = 466;

const StyledWrapper = styled.div`
    display: flex;
    padding: 8px 0 8px ${({ noHeader }) => (noHeader ? ROW_HEADER_WIDTH : 0)}px;
`;

const StyledRowHeaderWrapper = styled.div`
    flex: none;
    width: ${ROW_HEADER_WIDTH}px;
    font-size: 16px;
`;

const StyledContentWrapper = styled.div`
    position: relative;
    display: flex;
    flex: 1 1;
    align-items: center;
    margin-left: 71px;
    & > * {
        flex: 1;
    }
`;

export const RowBase = ({ rowHeader, children, ...rest }) => {
    return (
        <StyledWrapper noHeader={!rowHeader} {...rest}>
            {rowHeader && <StyledRowHeaderWrapper>{rowHeader}</StyledRowHeaderWrapper>}
            <StyledContentWrapper>{children}</StyledContentWrapper>
        </StyledWrapper>
    );
};
