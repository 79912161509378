import axios from 'axios';

import { DADATA_TOKEN } from 'config';

const instanceOfAxios = axios.create({
    baseURL: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs',
    method: 'post',
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Token ' + DADATA_TOKEN
    }
});

instanceOfAxios.interceptors.request.use(
    async (config) => {
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

instanceOfAxios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (axios.isCancel(error)) {
            return Promise.reject(error);
        }
        error.errorText = 'Ошибка сети';

        if (error.code === 'ECONNRESET' || !error.response)
            return Promise.reject({
                errorText: 'Запрошенный функционал или сервер в данный момент недоступен'
            });
        if (error.response.status >= 500) {
            error.errorText = 'Произошла внутренняя ошибка сервиса поиска адресов';
        }
        // add 502 handle, because nginx send it on lost connect with aggrigate
        if (error.response.status === 504 || error.response.status === 502) {
            error.errorText = 'Запрошенный функционал или сервер в данный момент недоступен';
        }
        if (error.response.status === 400) {
            error.errorText = 'Некорректный запрос (невалидный JSON или XML)';
        }
        if (error.response.status === 401) {
            error.errorText = 'В запросе отсутствует API-ключ';
        }
        if (error.response.status === 403) {
            error.errorText =
                'В запросе указан несуществующий API-ключ\n' +
                'Или не подтверждена почта\n' +
                'Или исчерпан дневной лимит по количеству запросов';
        }
        if (error.response.status === 405) {
            error.errorText = 'Запрос сделан с неизвестным методом';
        }
        if (error.response.status === 413) {
            error.errorText = 'Слишком большая длина запроса или слишком много условий для сервиса поиска адресов';
        }
        if (error.response.status === 429) {
            error.errorText = 'Слишком много запросов в секунду или новых соединений в минуту к сервису поиска адресов';
        }
        if (error.response.status === 404) {
            error.errorText = 'Не найдено';
        }
        if (error.response.status === 401) {
            error.errorText = 'Неавторизованный запрос';
        }
        // Do something with request error
        return Promise.reject(error);
    }
);

export default instanceOfAxios;
