import { useCallback, useEffect, useReducer, useRef } from 'react';
import { TIMEOUT_DURATION } from './config';

const getGap = (section, itemsCount) => section / itemsCount / 2;

export const getEventPosition = (event, container) => {
    if (!container || !event) return null;

    const { clientX } = event;
    const { left, right, width } = container.getBoundingClientRect();
    switch (true) {
        case clientX <= left:
            return 0;
        case clientX >= right:
            return 100;
        default:
            return ((clientX - left) / width) * 100;
    }
};

export const getPositionFromPrice = (price, milestones) => {
    const start = getGap(100, milestones.length);
    let addition;

    const rightIndex = milestones.findIndex((milestone) => milestone >= price);
    switch (rightIndex) {
        case -1:
            addition = 75;
            break;
        case 0:
            addition = 0;
            break;
        default:
            const step = 100 / milestones.length;
            // add previous sections percentage
            addition = step * (rightIndex - 1);

            const left = milestones[rightIndex - 1];
            const right = milestones[rightIndex];
            addition += ((price - left) / (right - left)) * step;
    }

    return start + addition;
};

export const getPriceFromPosition = (position, milestones) => {
    const gap = getGap(100, milestones.length);
    // percentage of space between edge milestones
    const subPosition = (position - gap) * (1 + (2 * gap) / (100 - 2 * gap));

    const lastIndex = milestones.length - 1;

    switch (true) {
        case subPosition <= 0:
            return [milestones[0], milestones[0]];
        case subPosition >= 100:
            return [milestones[lastIndex], milestones[lastIndex]];
        default:
            const share = 100 / lastIndex;
            const leftIndex = Math.floor(subPosition / share);

            const leftMilestone = milestones[leftIndex];
            const rightMilestone = milestones[leftIndex + 1];

            const fraction = (subPosition - leftIndex * share) / share;

            const price = (rightMilestone - leftMilestone) * fraction + leftMilestone;
            const milestonePrice = fraction < 0.5 ? leftMilestone : rightMilestone;

            return [Math.round(price), milestonePrice];
    }
};

export const useDrag = (dragListener, onDragEnd) => {
    const [isDrag, dispatchIsDrag] = useReducer(
        (_, { type, target }) => type === 'mousedown' && !!target.firstElementChild,
        false
    );

    // to prevent to often invalidation of callback fn
    const onDragEndRef = useRef(onDragEnd);
    onDragEndRef.current = onDragEnd;
    const handleMouseUp = useCallback((e) => {
        dispatchIsDrag(e);
        setTimeout(() => {
            onDragEndRef.current && onDragEndRef.current();
        }, TIMEOUT_DURATION);
    }, []);

    useEffect(() => {
        if (isDrag) {
            window.addEventListener('mouseup', handleMouseUp);
            window.addEventListener('mousemove', dragListener);
            return () => {
                window.removeEventListener('mouseup', handleMouseUp);
                window.removeEventListener('mousemove', dragListener);
            };
        }
    }, [isDrag, dragListener, handleMouseUp]);
    return [isDrag, dispatchIsDrag];
};

export const validateInput = (value, milestones, magnetMilestones) => {
    const numericValue = Number(value);
    switch (true) {
        case numericValue <= milestones[0]:
            return milestones[0];
        case numericValue >= milestones[milestones.length - 1]:
            return milestones[milestones.length - 1];
        case magnetMilestones:
            const rightIndex = milestones.findIndex((milestone) => milestone > numericValue);
            const left = milestones[rightIndex - 1];
            const right = milestones[rightIndex];
            return (right - left) / (numericValue - left) < 2 ? right : left;
        default:
            return numericValue;
    }
};

export const blurOnEnterPress = ({ code, target }) => code === 'Enter' && target.blur();

export const numberToCurrency = (number) =>
    number.toLocaleString('ru-RU', {
        style: 'currency',
        minimumFractionDigits: 0,
        currency: 'RUB'
    });
