import styled from '@emotion/styled';
import { Button } from 'components/Button';
import colors from 'colors';
import { Loader } from 'components/Loader';
import { css } from '@emotion/react';
import { useMemo } from 'react';

export const StyledSubmit = styled(Button)`
    width: 280px;
`;

export const StyledHeader = styled.div`
    font-weight: bold;
    font-size: 48px;
    line-height: 36px;
    color: ${colors.grayScale_600};
    margin-bottom: 36px;
`;

export const StyledTable = styled.div`
    display: grid;
    grid-template-columns: 70% 30%;
    min-width: 800px;
    margin-bottom: 32px;
`;

export const StyledLoader = styled(Loader)`
    display: block;
    margin: 0 auto;
`;

export const StyledTableHeader = styled.div`
    font-weight: 600;
    font-size: 20px;
    line-height: 60px;
    color: ${colors.main_400};
    border-bottom: 1px solid ${colors.grayScale_600};
    white-space: nowrap;
`;

export const StyledTableRow = styled.div`
    font-weight: normal;
    font-size: 16px;
    line-height: 60px;
    color: ${colors.grayScale_900};
    border-bottom: 1px solid ${colors.grayScale_200};
    white-space: nowrap;
`;

export const StyledTableAction = styled.div`
    font-weight: normal;
    font-size: 16px;
    line-height: 60px;
    color: ${colors.grayScale_900};
    border-bottom: 1px solid ${colors.grayScale_200};
    white-space: nowrap;
    display: flex;
    align-items: center;
`;

export const StyledText = styled.div`
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 80px;
    max-width: 900px;
    color: ${({ error }) => error && colors.error_soft};
`;

export const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
`;

export const StyledHintText = styled.div`
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 20px;
    max-width: 900px;
`;

export const RateTypeButton = ({ isActive, ...restProps }) => {
    const styles = useMemo(
        () => css`
            pointer-events: ${isActive ? 'none' : 'initial'};
            color: ${isActive ? colors.main_400 : colors.grayScale_600};
            box-shadow: 0 10px 40px rgba(51, 51, 51, 0.1);
            &:not(:first-of-type) {
                margin-top: 16px;
            }
        `,
        [isActive]
    );
    return (
        <Button
            css={styles}
            color={isActive ? colors.main_400 : colors.grayScale_0}
            outlined
            rounded
            size={'md'}
            {...restProps}
        />
    );
};
