import React from 'react';
import styled from '@emotion/styled';
import { Link, useLocation } from 'react-router-dom';

import colors from 'colors';

const ItemWrap = styled(Link)`
    cursor: ${({ to, onClick }) => (to || onClick ? 'pointer' : 'default')};
    text-decoration: none;
    color: ${colors.grayScale_900};
    display: flex;
    align-items: center;
    padding: 0 24px;
    user-select: none;
    &:focus {
        text-decoration: underline;
    }
`;

const ItemText = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
`;

export const MenuItem = ({ children, onClick, to }) => {
    const location = useLocation();

    return (
        <ItemWrap {...{ to, onClick, replace: location.pathname === to }}>
            <ItemText>{children}</ItemText>
        </ItemWrap>
    );
};
