import React from 'react';
import ReactModal from 'react-modal';

const Modal = ({ className, ...props }) => {
    const [name] = (className && className.split(' ')) || [''];
    const styles = name
        ? {
              portalClassName: name,
              overlayClassName: `${name}__Overlay`,
              className: `${name}__Content`
          }
        : {};

    return <ReactModal {...styles} {...props} />;
};

export { Modal };
