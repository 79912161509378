import { useEffect, useRef, useCallback } from 'react';

export { useAnchor } from './useAnchor';

//функция "замеса" цветов для создания прозрачных тонов, того же цвета
export const hexToRGBA = (hex, alpha = false) => {
    let hexAlpha = false,
        h = hex.slice(hex.startsWith('#') ? 1 : 0);
    if (h.length === 3) h = [...h].map((x) => x + x).join('');
    else if (h.length === 8) hexAlpha = true;
    h = parseInt(h, 16);
    return (
        'rgb' +
        (alpha || hexAlpha ? 'a' : '') +
        '(' +
        (h >>> (hexAlpha ? 24 : 16)) +
        ', ' +
        ((h & (hexAlpha ? 0x00ff0000 : 0x00ff00)) >>> (hexAlpha ? 16 : 8)) +
        ', ' +
        ((h & (hexAlpha ? 0x0000ff00 : 0x0000ff)) >>> (hexAlpha ? 8 : 0)) +
        (alpha !== false ? `, ${alpha}` : '') +
        ')'
    );
};

export const useThrottle = (func, time = 0) => {
    const timeoutRef = useRef();
    const functionRef = useRef(func);
    const argsRef = useRef();

    functionRef.current = func;

    useEffect(() => {
        return () => {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
            argsRef.current = null;
        };
    }, [time]);

    return useCallback(
        (...args) => {
            argsRef.current = args;
            if (!timeoutRef.current) {
                timeoutRef.current = setTimeout(() => {
                    const lastArgs = argsRef.current;
                    timeoutRef.current = null;
                    argsRef.current = null;
                    return functionRef?.current?.(...lastArgs);
                }, time);
            }
            return timeoutRef.current;
        },
        [time]
    );
};

export const isNil = (value) => value === null || value === undefined;

export const toSnakeCase = (str) =>
    str &&
    str
        .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
        .map((x) => x.toLowerCase())
        .join('_');

export const toCamelCase = (str) => {
    let s =
        str &&
        str
            .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
            .map((x) => x.slice(0, 1).toUpperCase() + x.slice(1).toLowerCase())
            .join('');
    return s.slice(0, 1).toLowerCase() + s.slice(1);
};

export const objectToFormData = (obj) =>
    Object.entries(obj).reduce((formData, pair) => {
        formData.append(...pair);
        return formData;
    }, new FormData());

export const isEqual = (a, b) => JSON.stringify(a) === JSON.stringify(b);

export const doBlurOnEnter = ({ target, key, type }) => key === 'Enter' && type === 'keypress' && target.blur();
