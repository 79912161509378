import React from 'react';
import styled from '@emotion/styled';
import { Link, useLocation } from 'react-router-dom';

import colors from 'colors';

const LogoWrap = styled(Link)`
    text-decoration: none;
    color: ${colors.grayScale_900};
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0 40px;
    &:focus {
        color: ${colors.main_400};
    }
`;

const LogoText = styled.div`
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
`;

export const Logo = ({ onClick }) => {
    const location = useLocation();

    return (
        <LogoWrap {...{ to: '/', onClick, replace: location.pathname === '/' }}>
            <LogoText>CommunalCa</LogoText>
        </LogoWrap>
    );
};
