import styled from '@emotion/styled';
import colors from 'colors';
import { hexToRGBA } from 'utils/index';

export const StyledWrap = styled.div`
    display: flex;
    flex-direction: column;
`;
export const StyledHeader = styled.div`
    font-weight: bold;
    font-size: 64px;
    line-height: 78px;
    color: ${colors.grayScale_600};
    margin-bottom: 36px;
`;
export const StyledDivider = styled.div`
    height: 1px;
    border: 1px solid ${colors.grayScale_600};
    margin-bottom: 40px;
    max-width: 400px;
`;
export const StyledError = styled.div`
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: ${colors.error_soft};
    margin-bottom: 40px;
`;
export const StyledText = styled.div`
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 80px;
    max-width: 900px;
`;
export const StyledBottomWrap = styled.div`
    display: flex;
    justify-content: space-between;
`;
export const StyledButtonsWrap = styled.div`
    display: flex;
    flex-direction: column;

    padding: 20px 0;
`;
export const StyledDisabledPanel = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: ${hexToRGBA(colors.grayScale_300, 0.2)};
`;
