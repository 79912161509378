import React, { useState, useMemo } from 'react';
import { usePopper } from 'react-popper';

const isFragment = (element) => element && element.type === React.Fragment;

const mergeRefs = (positionerRefSetter, propRef) => (element) => {
    positionerRefSetter(element);
    switch (typeof propRef) {
        case 'function':
            propRef(element);
            break;
        case 'object':
            if (propRef && propRef?.hasOwnProperty('current')) {
                propRef.current = element;
            }
            break;
        default:
    }
};

const mergeProps = (propsValue, positionerValue) => {
    if (typeof propsValue !== typeof positionerValue) {
        return positionerValue;
    }

    switch (typeof propsValue) {
        case 'object':
            return { ...propsValue, ...positionerValue };
        case 'function':
            return (...args) => {
                positionerValue(...args);
                return propsValue(...args);
            };
        default:
            return positionerValue;
    }
};

const extendedCloneElement = (node, positionerProps) => {
    const { props } = node;
    const override = {};

    Object.keys(positionerProps).forEach((key) => {
        switch (true) {
            case key === 'ref':
                override[key] = mergeRefs(positionerProps[key], node.ref);
                break;
            case !!props[key]:
                override[key] = mergeProps(props[key], positionerProps[key]);
                break;
            default:
                override[key] = positionerProps[key];
        }
    });

    return React.cloneElement(node, override);
};

const renderNode = (node, positionerProps, toClone) => {
    if (toClone) {
        if (!React.isValidElement(node) || isFragment(node)) {
            throw new Error(
                'Node for clone must be a react element and be able to receive ref to set on DOM element. ' +
                    'You may disable cloning via Positioner props'
            );
        }
        return extendedCloneElement(node, positionerProps);
    }
    return <div {...positionerProps}>{node}</div>;
};

const mergePopperOptions = (defaultOptions, additionsOptions) => {
    let resultOptions = { ...defaultOptions };
    Object.entries(additionsOptions).forEach(([key, value]) => {
        if (key === 'modifiers' && Array.isArray(defaultOptions[key])) {
            const resultModifiers = [...defaultOptions[key]];
            value.forEach((modifier) => {
                const modifierIndex = resultModifiers.findIndex(({ name }) => name === modifier.name);
                if (modifierIndex === -1) {
                    resultModifiers.push(modifier);
                } else {
                    resultModifiers[modifierIndex] = modifier;
                }
            });
            resultOptions[key] = resultModifiers;
        } else {
            resultOptions[key] = value;
        }
    });
    return resultOptions;
};

const handleAdditionalPopperOptions = (defaultOptions, additionsOptions) => {
    switch (typeof additionsOptions) {
        case 'function':
            return additionsOptions(defaultOptions);
        case 'object':
            return mergePopperOptions(defaultOptions, additionsOptions);
        default:
            return defaultOptions;
    }
};

export const Positioner = ({
    trigger,
    children,
    triggerProps = {},
    childrenProps = {},
    isOpen,
    popperOptions,
    cloneTrigger = true,
    cloneChildren = true
}) => {
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const [arrowElement, setArrowElement] = useState(null);

    const finalOptions = useMemo(
        () =>
            handleAdditionalPopperOptions(
                {
                    placement: 'auto',
                    strategy: 'fixed',
                    modifiers: [
                        {
                            name: 'flip',
                            options: {
                                padding: 15
                            }
                        },
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 15]
                            }
                        },
                        {
                            name: 'preventOverflow',
                            options: {
                                padding: 15
                            }
                        },
                        { name: 'arrow', options: { element: arrowElement } }
                    ]
                },
                popperOptions
            ),
        [popperOptions, arrowElement]
    );

    const { styles, attributes } = usePopper(referenceElement, popperElement, finalOptions);

    return (
        <>
            {renderNode(trigger, { ...triggerProps, ref: setReferenceElement }, cloneTrigger)}
            {isOpen &&
                renderNode(
                    children,
                    {
                        ...childrenProps,
                        ref: setPopperElement,
                        style: styles.popper,
                        arrowProps: { ref: setArrowElement, style: styles.arrow },
                        ...attributes.popper
                    },
                    cloneChildren
                )}
        </>
    );
};
