import React, { useState, useEffect, useRef } from 'react';
import { useValues } from 'kea';

import colors from 'colors';

import { LoginRegModal } from 'components/modals/LoginRegModal';
import { PageSectionHeader } from 'components/PageSectionHeader';

import guestImg from 'assets/img/guest.png';
import residentImg from 'assets/img/resident.png';
import managerImg from 'assets/img/manager.png';

import mainImg from 'assets/img/mainImg.png';

import authLogic from 'store/auth';

import {
    StyledSlideOne,
    StyledSlideTwo,
    StyledSlideWrap,
    StyledCardsWrap,
    StyledCardsInner,
    StyledBackgroundShape,
    StyledLeftArrowIcon,
    StyledRightArrowIcon,
    StyledMainImg,
    StyledFirstTextWrap,
    StyledFirstHeadWrap,
    StyledFirstHead,
    StyledFirstCaption,
    StyledFirstMainText,
    StyledBackgroundWrap,
    StyledArrowWrap,
    StyledArrowRightWrap,
    StyledCardImg,
    CalcCard
} from './units';

const SlidePanel = () => {
    const LeftArrowRef = useRef();
    const RightArrowRef = useRef();
    const slideTouchedRef = useRef(false);
    const [loginModal, setLoginModal] = useState('');
    const [isFirstActive, setFirstActive] = useState(true);
    const { isAuth } = useValues(authLogic);

    useEffect(() => {
        setTimeout(() => {
            if (!slideTouchedRef.current) {
                setFirstActive(false);
            }
        }, 5 * 1000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <StyledBackgroundWrap>
                <StyledBackgroundShape isActive={isFirstActive} />
            </StyledBackgroundWrap>
            <StyledSlideWrap>
                <StyledSlideOne isActive={isFirstActive}>
                    <div style={{ overflowX: 'hidden' }}>
                        <StyledMainImg src={mainImg} alt="Резидент" />
                        {isFirstActive && (
                            <StyledArrowRightWrap ref={RightArrowRef}>
                                <StyledRightArrowIcon
                                    onClick={() => {
                                        setFirstActive((state) => !state);
                                        slideTouchedRef.current = true;
                                        RightArrowRef.current.blur();
                                    }}
                                />
                            </StyledArrowRightWrap>
                        )}
                        <StyledFirstTextWrap>
                            <StyledFirstHeadWrap>
                                <StyledFirstHead>
                                    CommunalCa<sup>®</sup>
                                </StyledFirstHead>
                                <StyledFirstCaption>Патент №2747236</StyledFirstCaption>
                            </StyledFirstHeadWrap>
                            <StyledFirstMainText>
                                Расчет нормативной ставки за управление и <br />
                                техническую эксплуатацию многоквартирного дома.
                                <br />
                                <br />
                                Обеспечивает транспарантный прогноз затрат и доходов управляющей компании и резидентов
                                многоквартирного дома на основе уникальной методики управляющей классностью и стадией
                                жизненного цикла, услуги по управлению технической эксплуатации <br />
                                многоквартирного дома.
                            </StyledFirstMainText>
                        </StyledFirstTextWrap>
                    </div>
                </StyledSlideOne>
                <StyledSlideTwo isActive={!isFirstActive}>
                    {!isFirstActive && (
                        <StyledArrowWrap ref={LeftArrowRef}>
                            <StyledLeftArrowIcon
                                onClick={() => {
                                    setFirstActive((state) => !state);
                                    slideTouchedRef.current = true;
                                    LeftArrowRef.current.blur();
                                }}
                            />
                        </StyledArrowWrap>
                    )}
                    <PageSectionHeader>Расчет нормативной ставки</PageSectionHeader>
                    <StyledCardsWrap>
                        <StyledCardsInner>
                            <CalcCard
                                title={'Гость'}
                                image={() => <StyledCardImg src={guestImg} alt="Гость" />}
                                onClick={() => setLoginModal('guest')}
                                hint={
                                    'Для демонстрации возможностей сервиса, предлагаем осуществить расчет в роли Гостя'
                                }
                            />
                            <CalcCard
                                title={'Резидент'}
                                image={() => <StyledCardImg src={residentImg} alt="Резидент" />}
                                buttonColor={colors.main_300}
                            />
                            <CalcCard
                                title={'Менеджер'}
                                image={() => <StyledCardImg src={managerImg} alt="Менеджер" />}
                                buttonColor={colors.main_200}
                            />
                            <LoginRegModal
                                isReg
                                isSuccessLabel
                                isOpen={!!loginModal}
                                extraInfo={loginModal}
                                isNeedAuth={!isAuth}
                                onRequestClose={() => setLoginModal('')}
                            />
                        </StyledCardsInner>
                    </StyledCardsWrap>
                </StyledSlideTwo>
            </StyledSlideWrap>
        </>
    );
};

export { SlidePanel };
