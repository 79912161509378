import React, { useEffect, useRef } from 'react';
import VanillaTilt from 'vanilla-tilt';

import {
    StyledTiltElement,
    StyledTiltInner,
    StyledNumberWrapper,
    StyledNumberInner,
    StyledTiltHintWrapper,
    StyledTiltHintHeader,
    StyledTiltHintText,
    StyledDisableShadow,
    StyledLink,
    StyledButtonWrap
} from './units';

const ButtonLink = ({ to, disable, children }) => {
    return disable ? (
        children
    ) : (
        <StyledLink to={to} disabled={disable}>
            {children}
        </StyledLink>
    );
};

const TiltButton = ({ children, to, color, number, hintText, hintHeader, disable, highlight, shadowColor }) => {
    const buttonRef = useRef();

    useEffect(() => {
        VanillaTilt.init(buttonRef.current, {
            max: 8,
            speed: 200
        });
    }, []);

    return (
        <StyledButtonWrap disabled={disable}>
            <ButtonLink to={to} disable={disable}>
                <StyledTiltElement
                    highlight={highlight}
                    disable={disable}
                    color={color}
                    shadowColor={shadowColor}
                    ref={buttonRef}
                    data-tilt>
                    <StyledTiltInner>{children}</StyledTiltInner>
                    <StyledTiltHintWrapper>
                        <StyledTiltHintHeader>{hintHeader}</StyledTiltHintHeader>
                        <StyledTiltHintText>{hintText}</StyledTiltHintText>
                    </StyledTiltHintWrapper>
                    <StyledNumberWrapper>
                        <StyledNumberInner>{number}</StyledNumberInner>
                    </StyledNumberWrapper>
                    <StyledDisableShadow isActive={disable} />
                </StyledTiltElement>
            </ButtonLink>
        </StyledButtonWrap>
    );
};

export { TiltButton };
