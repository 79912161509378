import React from 'react';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';

import { Button } from 'components/Button';
import colors from 'colors';

import { StyledHeadWrap, StyledHeader } from './units';

const PageSectionHeader = ({ children, isInstruction, innerRef }) => {
    return (
        <StyledHeadWrap>
            <StyledHeader ref={innerRef}>{children}</StyledHeader>
            {isInstruction && (
                <Link to="/instruction" style={{ display: 'contents', textDecoration: 'none' }}>
                    <Button
                        size={'sm'}
                        outlined
                        color={colors.main_400}
                        css={css`
                            flex-basis: 20%;
                            max-width: 302px;
                        `}>
                        Инструкция
                    </Button>
                </Link>
            )}
        </StyledHeadWrap>
    );
};

PageSectionHeader.defaultProps = {
    isInstruction: true
};

export { PageSectionHeader };
