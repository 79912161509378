// Packages
import React from 'react';
import PropTypes from 'prop-types';
import hash from 'string-hash';
import color from 'tinycolor2';

const ReactHashAvatar = React.memo(({ name, height, width, radius = 0 }) => {
    const n = hash(name);
    const c1 = color({ h: n % 360, s: 0.95, l: 0.5 });
    const c1_ = c1.toHexString();
    const c2 = c1.triad()[1].toHexString();

    return (
        <svg width={width} height={height} style={{ borderRadius: radius }} viewBox="0 0 80 80">
            <defs>
                <linearGradient x1="0%" y1="0%" x2="100%" y2="100%" id={name}>
                    <stop stopColor={c1_} offset="0%" />
                    <stop stopColor={c2} offset="100%" />
                </linearGradient>
            </defs>
            <g stroke="none" strokeWidth="1" fill="none">
                <rect fill={`url(#${name})`} x="0" y="0" width="80" height="80" />
            </g>
        </svg>
    );
});

ReactHashAvatar.propTypes = {
    name: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    radius: PropTypes.number
};

export default ReactHashAvatar;
