export const createPopperOptions = (fieldRef, maxWidthFromProps) => {
    const getMaxWidth = () => {
        const maxWidth = maxWidthFromProps || fieldRef.current?.offsetWidth;
        console.log(maxWidth);
        return maxWidth === undefined ? maxWidth : `${maxWidth - 4}px`;
    };
    return {
        placement: 'bottom-end',
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [8, 15]
                }
            },
            {
                name: 'maxWidth',
                enabled: true,
                phase: 'beforeWrite',
                requires: ['computeStyles'],
                fn: ({ state }) => {
                    state.maxWidth = getMaxWidth();
                },

                effect: ({ state }) => {
                    let { maxWidth } = state;
                    if (maxWidth === undefined) maxWidth = getMaxWidth();
                    if (maxWidth !== undefined) state.elements.popper.style.maxWidth = maxWidth;
                }
            }
        ]
    };
};

export const transformValue = (value, type) => {
    if (type !== 'number') {
        return value;
    } else {
        if (value === '') {
            return null;
        } else {
            const _value = Number(value);
            return Number.isNaN(_value) ? null : _value;
        }
    }
};
