import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

const ResetPassword = () => {
    const { uid, token } = useParams();
    const history = useHistory();
    useEffect(() => {
        history.replace('/?reset_password=1', {
            resetPassword: true,
            uid,
            token
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return null;
};

export { ResetPassword };
