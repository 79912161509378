import styled from '@emotion/styled';

import { SlidePanel } from './SlidePannel';

export const StyledLandingWrap = styled.div`
    position: relative;
    margin-top: -64px;
    width: 100%;
    height: calc(100% + 64px);
`;

export const StyledTextWrap = styled.div`
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 0 100px;
    margin: 120px auto 184px;
    max-width: 1600px;
`;

export const StyledHeader = styled.div`
    font-weight: normal;
    font-size: 36px;
    line-height: 44px;
    @media (max-width: 1440px) {
        & br {
            display: none;
        }
    }
`;
export const StyledInfoWrap = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 64px;
`;
export const StyledTextColumnWrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 30%;
    max-width: 400px;
`;
export const StyledTextColumnHeader = styled.div`
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
`;

export const StyledTextColumnText = styled.div`
    margin-top: 32px;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
`;

export const StyledPartials = styled.div`
    position: absolute;
    height: 100%;
    width: 100vw;
    z-index: 2;
`;

export { SlidePanel };
