import React, { useContext, useRef } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { useValues } from 'kea';

import colors from 'colors';
import { isNil } from 'utils/index';

import { Field } from 'components/Field';
import { HorizontalField } from 'components/HorizontalField';

import userLogic from 'store/user';
import calcLogic from 'store/calculation';

import { CURRENCY_SIGN } from 'config';

export const StyledTwoColumns = styled.div`
    display: grid;
    grid-gap: 60px;
    grid-template-columns: repeat(2, 1fr);
    padding-top: 25px;
`;

export const StyledFieldsGrid = styled.div`
    display: grid;
    grid-gap: 25px;
    align-items: center;
    position: relative;
`;

export const StyledDataButtonsGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

export const StyledDataButton = styled.div`
    width: calc(50% - 12px);
    margin-bottom: 24px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${colors.grayScale_50};
    border: 1px solid ${colors.grayScale_300};
    box-sizing: border-box;
    border-radius: 5px;
    text-align: center;
    font-size: 16px;
    ${({ onClick }) =>
        onClick &&
        css`
            cursor: pointer;
            margin-bottom: 0;
            flex-grow: 1;
            color: ${colors.grayScale_0};
            text-decoration: none;
            background-color: ${colors.main_400};
        `}
    &:last-of-type {
        margin-bottom: 0;
        flex-grow: 1;
        color: ${colors.grayScale_0};
        text-decoration: none;
        background-color: ${colors.main_400};
    }
    &[href] ${({ onClick }) => onClick && ', &'} {
        cursor: pointer;
    }
`;

export const StyledFieldsCaption = styled.span`
    position: absolute;
    bottom: calc(100% + 15px);
`;

export const StyledManual = styled.div`
    font-size: 16px;
    align-self: self-start;
    padding: 40px;
    background-color: ${colors.grayScale_50};
`;

export const StyledNotice = styled.div`
    padding: 30px 0 10px;
`;

export const StyledSelect = styled(Field)`
    width: 100%;
`;

const BriefDataPrefixContext = React.createContext('brief-data');

const SummaryField = ({ value, id, suffix, ...props }) => {
    let { prefix, isLoading } = useContext(BriefDataPrefixContext);
    if (!isNil(value) || !isLoading) isLoading = false;

    if (typeof value === 'number' && !Number.isNaN(value)) value = value.toLocaleString('ru-RU');
    value = isNil(value) || Number.isNaN(value) ? '-' : isNil(suffix) ? value : `${value} ${suffix}`;

    return <HorizontalField disabled id={`${prefix}-${id}`} value={value} isLoading={isLoading} {...props} />;
};

export const SummaryFields = ({ isLoading, rate, rateLabel, toasterKey }) => {
    const { data } = useValues(calcLogic);
    const {
        userData: { area }
    } = useValues(userLogic);
    const prefixRef = useRef(Math.random());

    const monthRate = rate * area;
    const yearRate = monthRate * 12;

    return (
        <BriefDataPrefixContext.Provider value={{ prefix: prefixRef.current, isLoading }}>
            <SummaryField id="rate" suffix={CURRENCY_SIGN} label={rateLabel} value={rate} />
            <SummaryField id="area" label="Площадь" suffix="м²" value={area} />
            <SummaryField id="month" label="Ставка в месяц" suffix={CURRENCY_SIGN} value={monthRate} />
            <SummaryField id="year" label="Ставка в год" suffix={CURRENCY_SIGN} value={yearRate} />
            <SummaryField id="toaster" label="Коэффициент Тостера" value={data[toasterKey]} />
        </BriefDataPrefixContext.Provider>
    );
};
