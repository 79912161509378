import styled from '@emotion/styled';
import { colors } from 'colors';

export const StyledWrap = styled.div`
    display: flex;
    flex-direction: column;
`;
export const StyledContactsWrap = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0 auto;
    max-width: 800px;
`;
export const StyledContactsInner = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-bottom: 64px;
    margin-top: 24px;
`;
export const StyledContactsRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 12px 0;
`;

export const StyledLink = styled.a`
    text-decoration: none;
    font-weight: bold;
    color: ${colors.grayScale_900};
    &:hover {
        text-decoration: underline;
    }
    &:focus {
        text-decoration: underline;
    }
`;
