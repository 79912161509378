import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { RowsMainHeading, RegularCell, ColHeadingCell, RowHeadingCell, StyledTable, colgroup } from './units';

export const Matrix = ({ activeItem, onChange, stages = [], tariffs }) => {
    // [x, y]; x - column index. y - row index. Both start from 0. -1 - not hovered.
    const [hover, setHover] = useState([-1, -1]);
    const removeHover = useCallback(() => setHover([-1, -1]), []);

    return (
        <div>
            <StyledTable onMouseLeave={removeHover}>
                {colgroup}
                <thead>
                    <tr>
                        <th colSpan={2} onMouseEnter={removeHover} />
                        {tariffs.map(({ name, hint }, tariffIndex) => (
                            <ColHeadingCell {...{ hover, setHover, tariffIndex, hint, key: name }}>
                                {name}
                            </ColHeadingCell>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {stages.map(({ name, hint }, stageIndex) => (
                        <tr key={name}>
                            {stageIndex ? null : <RowsMainHeading rowSpan={stages.length} onMouseEnter={removeHover} />}
                            <RowHeadingCell {...{ hover, setHover, stageIndex, hint }}>{name}</RowHeadingCell>
                            {tariffs.map((_, tariffIndex) => (
                                <RegularCell
                                    key={tariffIndex}
                                    isSelected={
                                        activeItem && activeItem[0] === tariffIndex && activeItem[1] === stageIndex
                                    }
                                    {...{ hover, setHover, onChange, tariffIndex, stageIndex }}
                                />
                            ))}
                        </tr>
                    ))}
                </tbody>
            </StyledTable>
        </div>
    );
};

Matrix.propTypes = {
    activeItem: PropTypes.arrayOf(PropTypes.number),
    onChange: PropTypes.func
};
