import { hexToRGBA } from './utils';

const colors = {
    main_800: '#0E1326',
    main_600: '#173559',
    main_400: '#0F4C81',
    main_300: '#4E8CBF',
    main_200: '#5FA9D8',
    main_100: '#91BDD8',
    grayScale_900: '#000000',
    grayScale_800: '#2D3133',
    grayScale_700: '#3E4345',
    grayScale_600: '#5E6366',
    grayScale_400: '#919699',
    grayScale_300: '#C6CACC',
    grayScale_200: '#E3E5E5',
    grayScale_100: '#ECECEC',
    grayScale_50: '#F4F4F4',
    grayScale_0: '#ffffff',
    warning: '#F2C165',
    error: '#721818',
    error_soft: '#EE5150',
    success: '#36B37E'
};

colors.error_soft_trans = hexToRGBA(colors.error_soft, 0.1);
colors.grayScale_50_trans = hexToRGBA(colors.grayScale_50, 0.3);

export { colors };
export default colors;
