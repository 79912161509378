import React from 'react';

import { history } from 'store/initStore';

class CaptureErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(/*error*/) {
        // Update state on error
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // can catch error information and log it
        console.error(error, errorInfo);
    }

    componentDidMount() {
        // Update state on route change to error false
        history.listen(() => {
            this.setState(() => ({ ...this.state, hasError: false }));
        });
    }

    render() {
        if (this.state.hasError) {
            return (
                <div>
                    Sorry! Something went wrong... <br />
                    Please, try to reload the page
                </div>
            );
        }

        return this.props.children;
    }
}

export default CaptureErrorBoundary;
