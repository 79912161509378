import React from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from 'components/Button';

import {
    StyledWrap,
    StyledHeader,
    StyledDivider,
    StyledError,
    StyledText,
    StyledBottomWrap,
    StyledButtonsWrap,
    StyledDisabledPanel
} from './units';
import colors from 'colors';
import { useActions } from 'kea';
import calcLogic from 'store/calculation';

const InfoCalcPanel = ({ disabled, error, header, text, image: Image }) => {
    const history = useHistory();
    const { clearData } = useActions(calcLogic);

    return (
        <StyledWrap>
            <StyledHeader>{header}</StyledHeader>
            <StyledDivider />
            {error && (
                <StyledError>
                    Необходима <br /> регистрация в системе
                </StyledError>
            )}
            <StyledText>{text}</StyledText>
            <StyledBottomWrap>
                <StyledButtonsWrap>
                    <Button
                        css={{
                            width: '100%',
                            marginBottom: 36,
                            color: disabled ? colors.grayScale_600 : undefined,
                            boxShadow: '0px 10px 40px rgba(51, 51, 51, 0.1)'
                        }}
                        rounded
                        color={disabled ? colors.grayScale_0 : undefined}
                        size={'md'}
                        onClick={() => {
                            clearData();
                            history.push('/buttons');
                        }}>
                        Новый расчет
                    </Button>
                    <Button
                        onClick={() => history.push('/calc-history')}
                        css={{
                            width: '100%',
                            color: disabled ? colors.grayScale_600 : colors.main_400,
                            boxShadow: '0px 10px 40px rgba(51, 51, 51, 0.1)'
                        }}
                        rounded
                        size={'md'}
                        color={colors.grayScale_0}>
                        История расчетов
                    </Button>
                </StyledButtonsWrap>
                {Image && <Image style={{ opacity: 0.4, filter: disabled ? 'grayscale(1)' : undefined }} />}
            </StyledBottomWrap>
            {disabled && <StyledDisabledPanel />}
        </StyledWrap>
    );
};

export { InfoCalcPanel };
