import React from 'react';
import { v4 } from 'uuid';
import ReactHashAvatar from './AvatarHash';
import styled from '@emotion/styled';

import { colors } from 'colors';

const StyledBase = styled.div`
    height: ${({ size }) => size}px;
    width: ${({ size }) => size}px;
    background: ${colors.grayScale_300};
    border-radius: ${({ size }) => size}px;
    text-align: center;
    overflow: hidden;
    position: relative;
`;

const StyledText = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    font-weight: 500;
    font-size: ${({ size }) => size / 3}px;
    color: ${colors.grayScale_0};
    line-height: ${({ size }) => size}px;
`;

const StyledFilter = styled.div`
    filter: saturate(0.7);
`;

const defaultValue = v4();

const Avatar = ({ name, text, size }) => {
    return (
        <StyledBase size={size}>
            <StyledFilter size={size}>
                <ReactHashAvatar isBlueOnly name={name || defaultValue} width={size} height={size} size={size} />
            </StyledFilter>
            <StyledText size={size}>{text || ''}</StyledText>
        </StyledBase>
    );
};

Avatar.defaultProps = {
    size: 40,
    name: defaultValue
};

export { Avatar };
