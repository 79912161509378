import React, { useEffect, useRef, useState } from 'react';
import { useQueryParam, BooleanParam } from 'use-query-params';
import { useActions, useValues } from 'kea';
import { Helmet } from 'react-helmet-async';

import { GlobalModal } from 'components/modals/GlobalModal';
import { Field } from 'components/Field';

import authLogic from 'store/auth';
import userLogic from 'store/user';

import { StyledHeader, StyledSubmit, StyledText, StyledForm, StyledHintText } from './units';

const MODAL_CLOSE_TIMEOUT = 300;

const ActivatePhoneModal = ({ ...rest }) => {
    const { sendOTP, phoneActivation } = useActions(authLogic);
    const { isLoading } = useValues(authLogic);
    const { fetchUserData } = useActions(userLogic);
    const { userData } = useValues(userLogic);

    const activationForm = useRef();

    const [error, setError] = useState();
    const [otp, setOtp] = useState('');
    const [uid, setUid] = useState('');
    const [isOpen, setIsOpen] = useQueryParam('phone_activate', BooleanParam);

    const hintMessage =
        error &&
        Object.values(error).reduce((acc, errorField) => {
            return acc + (errorField || []).join('\n');
        }, '');

    useEffect(() => {
        if (isOpen) {
            sendOTP({
                userId: userData.id,
                onSuccess: ({ uid }) => setUid(uid)
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, sendOTP, setUid]);

    const onRequestClose = () => {
        setTimeout(() => {
            setError({});
        }, MODAL_CLOSE_TIMEOUT);
        setIsOpen(undefined, 'replaceIn');
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        phoneActivation({
            uid,
            otp,
            onSuccess: () => {
                fetchUserData({ silent: true });
                onRequestClose();
            },
            onFailure: (reason) => setError(reason)
        });
    };
    return (
        <GlobalModal closeTimeoutMS={MODAL_CLOSE_TIMEOUT} {...{ isOpen: isOpen || false, onRequestClose, ...rest }}>
            <Helmet>
                <title>CommunalCa | Активация телефона</title>
            </Helmet>
            <StyledHeader>Активация телефона</StyledHeader>
            <StyledText error={error}>{hintMessage}</StyledText>
            <StyledHintText>Вам на телефон отправленно сообщение с кодом</StyledHintText>
            <StyledForm ref={activationForm} onSubmit={handleSubmit} action={''} method={'POST'}>
                <Field
                    required
                    isRequired
                    variant={'filled'}
                    style={{ marginBottom: 32 }}
                    label={'Код из смс'}
                    name={'otp'}
                    value={otp}
                    onChange={setOtp}
                />
                <StyledSubmit rounded size={'md'} disabled={!otp} loading={isLoading}>
                    Подтвердить телефон
                </StyledSubmit>
            </StyledForm>
        </GlobalModal>
    );
};

export { ActivatePhoneModal };
