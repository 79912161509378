import { toCamelCase, toSnakeCase } from 'utils/index';

export const objectKeysToCamelCase = (obj) =>
    Object.entries(obj).reduce((acc, [key, value]) => {
        acc[toCamelCase(key)] = value;
        return acc;
    }, {});

export const objectKeysToSnakeCase = (obj) =>
    Object.entries(obj).reduce((acc, [key, value]) => {
        acc[toSnakeCase(key)] = value;
        return acc;
    }, {});
