import styled from '@emotion/styled';

import { Button } from 'components/Button';
import React from 'react';

export const StyledButton = styled(Button)`
    display: block;
    min-width: 315px;
    margin: 40px 0 0 auto;
`;

export const ButtonNext = (props) => <StyledButton size="md-lg" rounded children="Продолжить" {...props} />;
