import React from 'react';
import PropsTypes from 'prop-types';

import { RowBase, StyledHeading, StyledWrapper } from './units';

import { MainRow } from './units';

export const Configurator = ({
    headings,
    rows = [],
    linePosition,
    prices,
    magnetMilestones,
    onChange,
    onDragEnd,
    disableInputs = false,
    ...rest
}) => (
    <StyledWrapper {...rest}>
        {headings && (
            <RowBase>
                {headings.map((heading, index) => (
                    <StyledHeading key={index}>{heading}</StyledHeading>
                ))}
            </RowBase>
        )}
        {rows.map(({ id, ...restRowProps }, rowIndex) => (
            <MainRow
                key={id}
                id={id}
                price={prices[id]}
                isFirstLine={!rowIndex}
                isLastLine={rowIndex === rows.length - 1}
                {...{ linePosition, magnetMilestones, onChange, disableInputs, onDragEnd, ...restRowProps }}
            />
        ))}
    </StyledWrapper>
);

Configurator.propTypes = {
    headings: PropsTypes.arrayOf(PropsTypes.node),
    rows: PropsTypes.arrayOf(
        PropsTypes.shape({
            id: PropsTypes.oneOfType([PropsTypes.string, PropsTypes.number]),
            heading: PropsTypes.node,
            hint: PropsTypes.string,
            milestones: PropsTypes.arrayOf(PropsTypes.number)
        })
    ),
    linePosition: PropsTypes.number,
    prices: PropsTypes.objectOf(PropsTypes.number),
    magnetMilestones: PropsTypes.bool,
    onChange: PropsTypes.func,
    disableInputs: PropsTypes.bool,
    /** blocks interactions with the component */
    isBlocked: PropsTypes.bool
};

export default Configurator;
