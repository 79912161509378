import { useHistory } from 'react-router-dom';
import React from 'react';

import { H2, PaperBlock } from 'components/index';
import { StyledAboutWrap, StyledLink, StyledWrap } from './units';

const About = () => {
    const history = useHistory();
    return (
        <StyledWrap>
            <H2 as={'h1'}>О программе</H2>
            <PaperBlock id="main-settings" style={{ paddingTop: 60 }}>
                <StyledAboutWrap>
                    <span>
                        Программный комплекс CommunalCa® предназначен для широкого круга потребителей, которые
                        ответственно относятся к месту своего проживания, то есть к своей квартире, в которой они
                        проводят значительную часть своей жизни.
                        <br />
                        <br /> Квартира в современном обществе обладает магическим свойством объединять в себе несколько
                        ипостасей: с одной стороны – серьезные инвестиционные вложения, с другой – то самое убежище, в
                        котором человек отдыхает от мирской суеты, наслаждается отдыхом, покоем, любовью, едой. С первой
                        точки зрения, его потребность состоит в том, чтобы сохранить и преумножить свои инвестиции. С
                        другой точки зрения, проживание должно сопровождаться безопасностью, комфортом и высоким уровнем
                        жизнеобеспечения.
                        <br />
                        <br /> Для поддержания вышеуказанных характеристик жилая недвижимость должна содержаться
                        соответствующим образом. Это возможно только тогда, когда собственник платит за содержание
                        соответствующие деньги.
                        <br />
                        <br /> Программный комплекс CommunalCa® позволяет определить нормативную ставку за услуги по
                        управлению многоквартирными домами с их технической эксплуатацией, которая, с одной стороны,
                        обеспечивает сохранность инвестиций, то есть не приводит к снижению стоимости жилья из-за
                        неработающих инженерных систем, немытых фасадов, разрушенных подъездов; с другой стороны,
                        обеспечивает нормативный уровень безопасности, комфорта и жизнеобеспечения.
                        <br />
                        <br /> Так как современные многоквартирные дома строятся по индивидуальным проектам и сильно
                        отличаются друг от друга, в программе применена методика, позволяющая определять нормативную
                        стоимость услуг по управлению и технической эксплуатации жилой недвижимости в зависимости от
                        класса дома, класса услуги и от того, на какой стадии жизненного цикла находится многоквартирный
                        дом.
                        <br />
                        <br /> Методика защищена патентом №2747236 (дата регистрации — 29.04.2021).
                        <br />
                        <br /> Краткое описание методики по{' '}
                        <StyledLink
                            href={
                                'https://www1.fips.ru/registers-doc-view/fips_servlet?DB=RUPAT&DocNumber=2747236&TypeFile=html'
                            }
                            target="_blank">
                            ссылке
                        </StyledLink>
                        .
                        <br />
                        <br /> Правила пользования программой определяются{' '}
                        <StyledLink
                            href={'/instruction'}
                            onClick={(e) => {
                                e.preventDefault();
                                history.push('/instruction');
                            }}>
                            инструкцией
                        </StyledLink>
                        .
                    </span>
                </StyledAboutWrap>
            </PaperBlock>
        </StyledWrap>
    );
};

export { About };
