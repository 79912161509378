import styled from '@emotion/styled';
import colors from 'colors';
import { hexToRGBA } from 'utils';

export const StyledCardBase = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    //height: 624px;
    //width: 424px;
    margin: 0 24px;
    padding: 48px 32px 48px 32px;
    border-radius: 10px;
    background: ${colors.grayScale_0};
    box-shadow: 0 2px 40px ${hexToRGBA(colors.grayScale_900, 0.1)};
    @media (max-height: 680px) {
        padding: 28px 16px 36px 16px;
    }
`;

export const StyledCardHeader = styled.div`
    text-align: center;
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 16px;
    @media (max-height: 680px) {
        font-size: 24px;
        line-height: 32px;
    }
`;

export const StyledHint = styled.div`
    margin-top: 12px;
    max-width: 300px;
    text-align: center;
    color: ${colors.grayScale_400};
`;
