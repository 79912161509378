import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { colors } from 'colors';

const config = {
    L: css`
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 18px;
    `,
    M: css`
        width: 25px;
        height: 25px;
        line-height: 25px;
        font-size: 16px;
    `
};

const StyledHintWrapper = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    ${({ size }) => config[size]};
    border: 1px solid currentColor;
    border-radius: 50%;
    color: ${colors.grayScale_400};
    user-select: none;
    cursor: help;
    background: ${({ background }) => background};
`;

export const Hint = React.forwardRef(({ icon = '?', size = 'L', children, ...rest }, ref) => (
    <StyledHintWrapper ref={ref} title={children} {...{ size, ...rest }}>
        {icon}
    </StyledHintWrapper>
));

Hint.propTypes = {
    icon: PropTypes.node,
    size: PropTypes.oneOf(['L', 'M']),
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    background: PropTypes.string
};

export default Hint;
