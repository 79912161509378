import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import { colors } from 'colors';
import { hexToRGBA } from 'utils';

import { Logo } from './Logo';
import { MenuItem } from './MenuItem';

export const StyledHeaderWrapper = styled.div`
    display: flex;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1000;
    justify-content: space-between;
    height: 64px;
    background: ${colors.grayScale_0};
    box-shadow: 0 2px 12px ${hexToRGBA(colors.grayScale_900, 0.15)};
`;

export const StyledHeaderLeftGroup = styled.div`
    display: flex;
    flex-shrink: 0;
    flex-wrap: nowrap;
`;

export const StyledHeaderRightGroup = styled.div`
    display: flex;
    align-items: center;
    margin-right: 40px;
`;

export const StyledUserInfoWrap = styled(Link)`
    display: flex;
    align-items: center;
    padding: 12px 16px;
    text-decoration: none;
    color: ${colors.grayScale_900};
    &:focus {
        text-decoration: underline;
    }
`;

export const StyledAvatarWrap = styled.div`
    margin-right: 16px;
`;

export { Logo, MenuItem };
