import React from 'react';

import {
    StyledFooterWrapper,
    StyledFooterLeftGroup,
    StyledFooterRightGroup,
    StyledLink,
    Logo,
    InfoItem
} from './units';

import { ReactComponent as MapPointerIcon } from 'assets/icons/mapPointer.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/phone.svg';
import { ReactComponent as MailIcon } from 'assets/icons/mail.svg';

const Footer = () => {
    return (
        <StyledFooterWrapper>
            <StyledFooterLeftGroup>
                <Logo />
            </StyledFooterLeftGroup>
            <StyledFooterRightGroup>
                <InfoItem icon={MapPointerIcon}>
                    125047, г. Москва
                    <br />
                    <br />
                    ул. 2-я Брестская, д.6,
                </InfoItem>
                <InfoItem icon={PhoneIcon}>
                    <StyledLink href="tel:+74956883818">+7(495) 688-3818</StyledLink>
                    <br />
                    <br />
                </InfoItem>
                <InfoItem style={{ marginTop: -10 }} icon={MailIcon}>
                    <div style={{ marginTop: 10 }}>
                        <StyledLink href="mailto:info@lab-cifra.ru">info@lab-cifra.ru</StyledLink>
                    </div>
                </InfoItem>
            </StyledFooterRightGroup>
        </StyledFooterWrapper>
    );
};

export { Footer };
