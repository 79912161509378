import React, { useReducer, useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { useActions, useValues } from 'kea';

import calcLogic from 'store/calculation';
import dictsLogic from 'store/dicts';
import { StyledLoader } from 'pages/Calc3/units';
import { isEqual } from 'utils/index';

// all this stuff needed just to avoid recalculations for blurs when value has not changed
export const useAutoHarmValues = () => {
    const { autoData } = useValues(calcLogic);
    const { fetchAutoHarmonization, setHarmonization } = useActions(calcLogic);
    const rateFromStore = autoData?.rate;

    const [autoHarmInputValue, setAutoHarmInputValue] = useState(rateFromStore);
    useEffect(() => {
        setAutoHarmInputValue(rateFromStore);
    }, [autoData]);

    const currentRef = useRef(rateFromStore);
    currentRef.current = autoHarmInputValue;

    const onFocus = useCallback(() => autoData && setHarmonization('auto'), [!!autoData]);

    const onBlur = useCallback(() => {
        if (rateFromStore !== currentRef.current && currentRef.current) {
            setHarmonization('auto');
            fetchAutoHarmonization(currentRef.current);
        } else {
            setAutoHarmInputValue(rateFromStore);
        }
    }, [rateFromStore, setHarmonization, fetchAutoHarmonization]);

    return [autoHarmInputValue || null, setAutoHarmInputValue, onFocus, onBlur];
};

const pricesReducer = (state, payload) => ({ ...state, ...payload });
export const useConfiguratorValues = () => {
    const { rateClasses } = useValues(dictsLogic);
    const { expertData, autoData, normativeData, harmonization, services, isLoading } = useValues(calcLogic);
    const { fetchExpertHarmonization } = useActions(calcLogic);

    const currentStorePrices = !harmonization
        ? normativeData?.prices
        : harmonization === 'expert'
        ? expertData?.prices
        : autoData?.prices;

    const [prices, dispatchPrices] = useReducer(pricesReducer, currentStorePrices);
    useEffect(() => {
        currentStorePrices && dispatchPrices(currentStorePrices);
    }, [currentStorePrices]);

    const [pricesForConfigurator, rowsForConfigurator] = useMemo(() => {
        const techMilestones = [...Array(rateClasses.length).keys()];
        const rateClassesHashMap = rateClasses.reduce(
            (map, rateClass, index) => map.set(rateClass.id, { ...rateClass, index }),
            new Map()
        );

        const pricesForConfigurator = {};
        const rowsForConfigurator = [];

        services.forEach((service) => {
            const { id } = service;

            pricesForConfigurator[id] = rateClassesHashMap.get(prices?.[id]?.rateClass)?.index;
            rowsForConfigurator.push({
                ...service,
                milestones: techMilestones,
                priceToShow: prices?.[id]?.value ?? (isLoading ? <StyledLoader /> : <div />)
            });
        });

        return [pricesForConfigurator, rowsForConfigurator];
    }, [prices, isLoading, services, rateClasses]);

    const onPriceChange = useCallback(
        ({ id, price }) => {
            dispatchPrices({
                [id]: {
                    rateClass: rateClasses[price].id,
                    value: undefined
                }
            });
        },
        [rateClasses, dispatchPrices]
    );

    const pricesRef = useRef(prices);
    pricesRef.current = prices;
    const runExpertHarm = useCallback(() => {
        const isSameValues = isEqual(pricesRef.current, currentStorePrices);
        !isSameValues &&
            fetchExpertHarmonization(
                Object.entries(pricesRef.current).reduce((acc, [key, { rateClass }]) => {
                    acc[key] = rateClass;
                    return acc;
                }, {})
            );
    }, [currentStorePrices, fetchExpertHarmonization]);

    return [pricesForConfigurator, rowsForConfigurator, onPriceChange, runExpertHarm];
};
