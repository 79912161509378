import React, { useEffect } from 'react';
import 'particles.js';

import { partialsConf } from './config';

import {
    StyledLandingWrap,
    SlidePanel,
    StyledTextWrap,
    StyledHeader,
    StyledInfoWrap,
    StyledTextColumnWrap,
    StyledTextColumnHeader,
    StyledTextColumnText,
    StyledPartials
} from './units';

const Landing = () => {
    useEffect(() => {
        window.particlesJS('particles-js', partialsConf);
    }, []);
    return (
        <StyledLandingWrap>
            <StyledPartials id="particles-js" />
            <SlidePanel />
            <StyledTextWrap>
                <StyledHeader>
                    Нормативные расходы на услуги высокого класса для жителей <br /> многоквартирных домов
                </StyledHeader>
                <StyledInfoWrap>
                    <StyledTextColumnWrap>
                        <StyledTextColumnHeader>Простой расчет</StyledTextColumnHeader>
                        <StyledTextColumnText>
                            Нормативные расходы - залог безопасности, комфорта и жизнеобеспечения, которые вы
                            заслуживаете.
                        </StyledTextColumnText>
                    </StyledTextColumnWrap>
                    <StyledTextColumnWrap>
                        <StyledTextColumnHeader>Эффективная методика</StyledTextColumnHeader>
                        <StyledTextColumnText>
                            Нормативная ставка обеспечивает сохранение стоимости жилья и поддержание в штатном состоянии
                            инженерных систем и конструктивных элементов здания.
                        </StyledTextColumnText>
                    </StyledTextColumnWrap>
                    <StyledTextColumnWrap>
                        <StyledTextColumnHeader>Стандартные сметы</StyledTextColumnHeader>
                        <StyledTextColumnText>
                            Применение ставок ниже нормативных приводит к накоплению проблем с жизнеобеспечением здания,
                            безопасностью и комфортом проживания и, как следствие, снижением стоимости жилья.
                        </StyledTextColumnText>
                    </StyledTextColumnWrap>
                </StyledInfoWrap>
            </StyledTextWrap>
        </StyledLandingWrap>
    );
};

export { Landing };
