import React, { useReducer } from 'react';

import { Configurator } from 'components/Configurator';
import { TARIFFS } from 'config';

const milestonesMock = [5000, 10000, 15000, 50000, 100000];
const rowsMock = [
    { id: 0, heading: 'Клининг общий', milestones: milestonesMock },
    { id: 1, heading: 'Вывоз ТБО', milestones: milestonesMock },
    { id: 2, heading: 'Обслуживание лифтов', milestones: milestonesMock },
    { id: 3, heading: 'Дератизация', milestones: milestonesMock },
    { id: 4, heading: 'Дератизация', milestones: milestonesMock }
];

const defaultPricesMock = {
    0: 5000,
    1: 10000,
    2: 15000,
    3: 50000,
    4: 100000
};

export const ConfiguratorPage = () => {
    const headings = TARIFFS.map(({ name }) => name);
    const [prices, dispatchPrices] = useReducer((prev, { id, price }) => ({ ...prev, [id]: price }), defaultPricesMock);
    return (
        <div style={{ background: '#fff', width: '100%', padding: '100px 20px' }}>
            <Configurator
                magnetMilestones={true}
                prices={prices}
                headings={headings}
                rows={rowsMock}
                linePosition={2}
                onChange={dispatchPrices}
            />
        </div>
    );
};

export default ConfiguratorPage;
