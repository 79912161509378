import React from 'react';
import PropTypes from 'prop-types';

import { StyledPart, StyledWrap } from './units';
import colors from 'colors';

const Loader = ({ color = colors.main_400, ...rest }) => (
    <StyledWrap {...rest}>
        <StyledPart color={color} />
        <StyledPart color={color} />
        <StyledPart color={color} />
        <StyledPart color={color} />
        <StyledPart color={color} />
        <StyledPart color={color} />
        <StyledPart color={color} />
        <StyledPart color={color} />
    </StyledWrap>
);

Loader.propTypes = {
    color: PropTypes.string
};

export { Loader };
