import { kea } from 'kea';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify';

import { AREAS_FIELDS, CALC_VISIBILITIES } from 'config';

import { isNil, objectToFormData } from 'utils';

import axios from 'utils/axiosWrapper';

import dictsLogic from 'store/dicts';
import userLogic from 'store/user';

import { objectKeysToCamelCase, objectKeysToSnakeCase } from 'store/utils';

const calc1Fields = ['lifeCycle', 'rateClass', 'zipCode', 'hcName', 'hcId', 'address'];
const calc2Fields = Object.keys(AREAS_FIELDS);

const calc1RequiredFields = ['lifeCycle', 'rateClass', 'address'];

const calculationFields = [...calc1Fields, ...calc2Fields];

const defaultCalcData = Object.assign(...calculationFields.map((key) => ({ [key]: null })));

const toPrecision = (number, precision = 0) => {
    const multiplier = 10 * precision ?? 1;
    return Math.round((number + Number.EPSILON) * multiplier) / multiplier;
};

const transformHarmData = ({ services, charge } = {}) => {
    const prices = services.reduce((acc, { id, value, rate_class_id: rateClass }) => {
        acc[id] = {
            value: Math.round(value),
            rateClass
        };
        return acc;
    }, {});
    return [prices, toPrecision(charge, 1)];
};

export default kea({
    path: () => ['scenes', 'calc', 'data'],

    connect: {
        props: [
            dictsLogic,
            ['lifeCycles', 'rateClasses', 'services'],
            userLogic,
            ['userData', 'nominative', 'accusative']
        ]
    },

    actions: ({ values }) => ({
        setData: (payload) => {
            const flags = { resetHarmonization: false, resetAreas: false };
            const currentData = values?.data || {};

            if (!isNil(currentData.id)) {
                flags.resetAreas =
                    (payload.hasOwnProperty('hcId') && payload.hcId !== currentData.hcId) ||
                    (payload.hasOwnProperty('lifeCycle') && payload.lifeCycle !== currentData.lifeCycle) ||
                    (payload.hasOwnProperty('rateClass') && payload.rateClass !== currentData.rateClass);
                flags.resetHarmonization =
                    flags.resetAreas ||
                    Object.keys(AREAS_FIELDS).some(
                        (area) => payload.hasOwnProperty(area) && payload[area] !== currentData[area]
                    );
            }
            return { payload, flags };
        },
        clearData: undefined,
        setCalc3Viewed: (bool) => bool,
        setHarmonization: (value) => value,
        setNormativeData: (data) => data,
        setAutoData: (data) => data,
        setExpertData: (data) => data,
        setAddress: (address) => address,
        setLastAddress: (address) => address,
        setIsLoading: (bool) => bool
    }),

    reducers: ({ actions }) => ({
        data: [
            defaultCalcData,
            PropTypes.object,
            { persist: true },
            {
                [actions.setData]: (state, { payload, flags: { resetHarmonization, resetAreas } }) => {
                    const id = resetAreas || resetHarmonization ? undefined : state.id;
                    const harmonizationResetObj = resetHarmonization
                        ? { expertRate: undefined, autoRate: undefined, normativeRate: undefined }
                        : {};
                    const areasResetObj = Object.keys(resetAreas ? AREAS_FIELDS : []).reduce((acc, key) => {
                        acc[key] = null;
                        return acc;
                    }, {});
                    return { ...state, id, ...harmonizationResetObj, ...areasResetObj, ...payload };
                },
                [actions.clearData]: () => defaultCalcData
            }
        ],
        address: [
            '',
            PropTypes.string,
            { persist: true },
            {
                [actions.setAddress]: (state, payload) => payload,
                [actions.clearData]: () => ''
            }
        ],
        lastAddress: [
            '',
            PropTypes.string,
            {
                [actions.setLastAddress]: (state, payload) => payload,
                [actions.clearData]: () => ''
            }
        ],
        calc3Viewed: [
            false,
            PropTypes.bool,
            { persist: true },
            {
                [actions.setCalc3Viewed]: (_, payload) => payload,
                [actions.clearData]: () => false,
                [actions.setData]: (state, { flags: { resetHarmonization } }) => resetHarmonization || state
            }
        ],
        harmonization: [
            null,
            PropTypes.string,
            { persist: true },
            {
                [actions.setHarmonization]: (_, payload) => payload,
                [actions.clearData]: () => null,
                [actions.setData]: (state, { flags: { resetHarmonization } }) => (resetHarmonization ? null : state)
            }
        ],
        normativeData: [
            null,
            PropTypes.shape({ rate: PropTypes.number, prices: PropTypes.object }),
            { persist: true },
            {
                [actions.setNormativeData]: (_, payload) => payload,
                [actions.clearData]: () => null,
                [actions.setData]: (state, { flags: { resetHarmonization } }) => (resetHarmonization ? null : state)
            }
        ],
        autoData: [
            null,
            PropTypes.shape({ rate: PropTypes.number, prices: PropTypes.object }),
            { persist: true },
            {
                [actions.setAutoData]: (_, payload) => payload,
                [actions.clearData]: () => null,
                [actions.setData]: (state, { flags: { resetHarmonization } }) => (resetHarmonization ? null : state)
            }
        ],
        expertData: [
            null,
            PropTypes.shape({ rate: PropTypes.number, prices: PropTypes.object }),
            { persist: true },
            {
                [actions.setExpertData]: (_, payload) => payload,
                [actions.clearData]: () => null,
                [actions.setData]: (state, { flags: { resetHarmonization } }) => (resetHarmonization ? null : state)
            }
        ],
        isLoading: [
            false,
            PropTypes.bool,
            { [actions.setIsLoading]: (_, payload = true) => payload, [actions.clearData]: () => false }
        ]
    }),

    selectors: ({ selectors }) => ({
        finalName: [
            () => [selectors.data, selectors.nominative, selectors.accusative],
            (data, nominative, accusative) => {
                switch (true) {
                    case !!data.name:
                        return data.name;
                    case !!accusative:
                        return `Расчет ${accusative}`;
                    case !!nominative:
                        return `Расчет: ${nominative}`;
                    default:
                        return 'Новый расчет';
                }
            },
            PropTypes.string
        ],
        lifeCycleId: [() => [selectors.data], (data) => data.lifeCycle, PropTypes.number],
        rateClassId: [() => [selectors.data], (data) => data.rateClass, PropTypes.number],
        lifeCycleIndex: [
            () => [selectors.lifeCycles, selectors.lifeCycleId],
            (lifeCycles, lifeCycleId) => lifeCycles.findIndex(({ id }) => id === lifeCycleId),
            PropTypes.number
        ],
        rateClassIndex: [
            () => [selectors.rateClasses, selectors.rateClassId],
            (rateClasses, rateClassId) => rateClasses.findIndex(({ id }) => id === rateClassId),
            PropTypes.number
        ],
        currentLifeCycle: [
            () => [selectors.lifeCycles, selectors.lifeCycleIndex],
            (lifeCycles, lifeCycleIndex) => lifeCycles[lifeCycleIndex],
            PropTypes.object
        ],
        currentRateClass: [
            () => [selectors.rateClasses, selectors.rateClassIndex],
            (rateClasses, rateClassIndex) => rateClasses[rateClassIndex],
            PropTypes.object
        ],
        calc1Done: [
            () => [selectors.data, selectors.userData],
            (data, { role }) =>
                !CALC_VISIBILITIES.calc1[role] || calc1RequiredFields.every((key) => data[key] || data[key] === 0),
            PropTypes.bool
        ],
        calc2Done: [
            () => [selectors.data, selectors.userData, selectors.calc1Done],
            (data, { role }, calc1Done) =>
                calc1Done &&
                !isNil(data.id) &&
                (!CALC_VISIBILITIES.calc2[role] || calc2Fields.every((key) => data[key] || data[key] === 0)),
            PropTypes.bool
        ],
        calc3Done: [
            () => [selectors.userData, selectors.calc2Done, selectors.calc3Viewed],
            ({ role }, calc2Done, calc3Viewed) => calc2Done && (!CALC_VISIBILITIES.calc3[role] || calc3Viewed),
            PropTypes.bool
        ]
    }),

    thunks: ({ actions, values }) => ({
        fetchAutoHarmonization: async (value) => {
            actions.setIsLoading();

            const { status, data: result } = await axios({
                method: 'post',
                url: `/auto_harm/${values.data.id}`,
                data: { target_charge: value }
            }).catch((e) => e);

            actions.setIsLoading(false);

            if (status === 200) {
                const [prices, rate] = transformHarmData(result);

                actions.setAutoData({ rate, prices });
                actions.setHarmonization('auto');
            } else {
                const error = 'Ошибка автоматической гармонизации';
                toast.error(error);
            }
        },
        fetchExpertHarmonization: async (data) => {
            actions.setIsLoading();
            actions.setHarmonization(data ? 'expert' : null);

            const { status, data: result } = await axios({
                method: 'post',
                url: `/manual_harm/${values.data.id}`,
                data
            }).catch((e) => e);

            actions.setIsLoading(false);

            if (status === 200) {
                const [prices, rate] = transformHarmData(result);

                if (data) {
                    actions.setExpertData({ rate, prices });
                } else {
                    actions.setNormativeData({ rate, prices });
                }
            } else {
                const error = 'Ошибка гармонизации';
                toast.error(error);
            }
        },
        createCalculation: async () => {
            if (!isNil(values.data.id)) return;

            actions.setIsLoading();
            const { cleaningArea1, cleaningArea2, cleaningArea3, hcId, ...restValues } = values.data;
            const { status, data: { id } = {} } = await axios({
                method: 'post',
                url: '/calculate',
                headers: {
                    Accept: '*/json'
                },
                data: objectToFormData({
                    address: values.address,
                    name: values.finalName,
                    ...objectKeysToSnakeCase(restValues),
                    cleaning_area_1: cleaningArea1,
                    cleaning_area_2: cleaningArea2,
                    cleaning_area_3: cleaningArea3
                })
            }).catch((e) => e);
            actions.setIsLoading(false);
            if (status === 200 && !isNil(id)) {
                actions.setData({ id });
            } else {
                const error = 'Ошибка создания расчёта';
                toast.error(error);
                return { isError: true };
            }
        },
        fetchExtraData: async () => {
            if (isNil(values.data.id)) return;

            const { status, data: result } = await axios({
                method: 'get',
                url: `/calculations/${values.data.id}`
            }).catch((e) => e);
            // cut result values to not have something that will break BE calc
            const {
                normativeRate,
                autoRate,
                expertRate,
                date,
                toster,
                autoHarmToster,
                manHarmToster
            } = objectKeysToCamelCase(result);
            if (status === 200)
                actions.setData({ normativeRate, autoRate, expertRate, date, toster, autoHarmToster, manHarmToster });
        },
        fetchAddress: async (_address) => {
            const address = _address || values.data.address;
            if (values.lastAddress === address) return;
            if (isNil(address)) return actions.setAddress('');
            actions.setLastAddress(address);

            actions.setAddress(address);
        },
        fetchAreasByTotal: async () => {
            const { totalArea, rateClass } = values.data;
            const { status, data: result } = await axios({
                method: 'post',
                url: '/get_squares',
                data: objectToFormData(objectKeysToSnakeCase({ totalArea, rateClass }))
            }).catch((e) => e);
            if (status === 200) actions.setData(objectKeysToCamelCase(result));
        }
    }),
    listeners: ({ actions }) => ({
        setData: ({ payload }) => actions.fetchAddress(payload.address)
    })
});
