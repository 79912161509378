import React from 'react';
import styled from '@emotion/styled';

import { colors } from 'colors';
import Hint from 'components/Hint';

const StyledWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 87px;
    padding: 10px 70px;
    position: relative;
    text-align: center;
    background-color: ${colors.grayScale_50};
`;

const StyledHint = styled(Hint)`
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
`;

export const RowHeading = ({ children, hint }) => {
    return (
        <StyledWrapper>
            <StyledHint background={colors.grayScale_0}>{hint ?? children}</StyledHint>
            {children}
        </StyledWrapper>
    );
};
