import styled from '@emotion/styled';
import colors from 'colors';

import { Button } from 'components/Button';
import { Field } from 'components/Field';

export const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
`;

export const StyledButtonWrap = styled.div`
    display: flex;
    margin-top: 56px;
    justify-content: space-between;
    align-items: center;
`;

export const StyledHeader = styled.div`
    font-weight: bold;
    font-size: 48px;
    line-height: 36px;
    margin-bottom: 20px;
`;

export const StyledHint = styled.div`
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: ${colors.grayScale_600};
`;

export const StyledErrorLabel = styled.div`
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: ${colors.error_soft};
    margin-top: 16px;
`;

export const StyledLink = styled.a`
    text-decoration: underline;
    cursor: pointer;
    white-space: nowrap;
    color: ${colors.main_300};
    &:hover {
        color: ${colors.main_400};
    }
`;

export const StyledSubmit = styled(Button)`
    max-width: 280px;
    width: ${({ isSingleForm }) => (isSingleForm ? '280px' : '100%')};
`;

export const StyledField = styled(Field)`
    max-width: 540px;
    width: 100%;
`;

export const StyledSuccessLabel = styled.div`
    font-weight: bold;
    font-size: 48px;
    line-height: 59px;
    letter-spacing: -0.015em;
    margin: 80px 0;

    color: ${colors.main_400};
`;
