import React, { Fragment, useEffect } from 'react';
import { useQueryParam, StringParam, withDefault } from 'use-query-params';
import { useActions, useValues } from 'kea';
import { Helmet } from 'react-helmet-async';
import JsFileDownloader from 'js-file-downloader';
import { toast } from 'react-toastify';

import { GlobalModal } from 'components/modals/GlobalModal';

import userLogic from 'store/user';
import historyLogic from 'store/calculationHistory';

import {
    StyledHeader,
    StyledSubmit,
    StyledTableHeader,
    StyledTable,
    StyledTableRow,
    StyledTableAction,
    StyledLoader,
    RateTypeButton
} from './units';

import { ReactComponent as MailToIcon } from 'assets/icons/mail_to.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';
import { ReactComponent as PrintIcon } from 'assets/icons/print.svg';

const MODAL_CLOSE_TIMEOUT = 300;

const RATE_TYPES = Object.freeze({
    NORMATIVE: 'normative',
    AUTO: 'auto_harm',
    MANUAL: 'manual_harm'
});

const ReportsModal = ({ ...rest }) => {
    const { reports, reportsLoading } = useValues(historyLogic);
    const { fetchReports } = useActions(historyLogic);
    const { userData: { role = '', is_superuser: isSuperuser = false } = {} } = useValues(userLogic);

    const [calcId, setCalcId] = useQueryParam('reports_modal', StringParam);
    const [rateType, setRateType] = useQueryParam('rateType', withDefault(StringParam, 'normative'));

    useEffect(() => {
        fetchReports({
            id: calcId
        });
        if (!calcId) {
            setRateType(RATE_TYPES.NORMATIVE);
        }
    }, [calcId, fetchReports, setRateType]);

    const onRequestClose = () => {
        setCalcId(undefined, 'replaceIn');
    };

    const onDownload = (url) => {
        new JsFileDownloader({ url }).catch((e) => {
            console.log(e);
            toast.error('Неудалось скачать файл');
        });
    };

    const reportsList = reports[rateType] ?? [];

    return (
        <GlobalModal closeTimeoutMS={MODAL_CLOSE_TIMEOUT} {...{ isOpen: !!calcId || false, onRequestClose, ...rest }}>
            <Helmet>
                <title>CommunalCa | Отчеты и сметы</title>
            </Helmet>
            <StyledHeader>Отчеты и сметы</StyledHeader>
            <StyledTable>
                <StyledTableHeader>Название</StyledTableHeader>
                <StyledTableHeader>Операция</StyledTableHeader>
                {reportsLoading ? (
                    <StyledLoader />
                ) : (
                    reportsList.map((report, index) => {
                        const [[name, link]] = Object.entries(report);
                        const demoName = 'Смета по формату для жителей';
                        const isDemoReport = demoName === name;
                        return (
                            <Fragment key={index}>
                                <StyledTableRow>{name}</StyledTableRow>
                                <StyledTableAction>
                                    <DownloadIcon
                                        onClick={() => isDemoReport && onDownload(link)}
                                        style={{
                                            marginRight: 24,
                                            cursor: isDemoReport ? 'pointer' : 'not-allowed',
                                            opacity: isDemoReport ? 1 : 0.4
                                        }}
                                    />
                                    <MailToIcon
                                        style={{
                                            marginRight: 24,
                                            cursor: 'not-allowed',
                                            opacity: 0.4
                                        }}
                                    />
                                    <PrintIcon style={{ cursor: 'not-allowed', opacity: 0.4 }} />
                                </StyledTableAction>
                            </Fragment>
                        );
                    })
                )}
            </StyledTable>

            {(isSuperuser || role === 'manager') && (
                <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
                    <StyledSubmit rounded size={'md'}>
                        Подробные отчеты
                    </StyledSubmit>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <RateTypeButton
                            isActive={rateType === RATE_TYPES.NORMATIVE}
                            onClick={() => setRateType(RATE_TYPES.NORMATIVE)}>
                            Нормативная ставка
                        </RateTypeButton>
                        {!!reports[RATE_TYPES.AUTO]?.length && (
                            <RateTypeButton
                                isActive={rateType === RATE_TYPES.AUTO}
                                onClick={() => setRateType(RATE_TYPES.AUTO)}>
                                Автоматическая гармонизация
                            </RateTypeButton>
                        )}
                        {!!reports[RATE_TYPES.MANUAL]?.length && (
                            <RateTypeButton
                                isActive={rateType === RATE_TYPES.MANUAL}
                                onClick={() => setRateType(RATE_TYPES.MANUAL)}>
                                Экспертная гармонизация
                            </RateTypeButton>
                        )}
                    </div>
                </div>
            )}
        </GlobalModal>
    );
};

export { ReportsModal };
