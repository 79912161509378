import React from 'react';
import ReactDOM from 'react-dom';
import styled from '@emotion/styled';

import { ScrollHider } from 'components/ScrollHider';
import { Loader } from 'components/Loader';

import colors from 'colors';
import { hexToRGBA } from 'utils/index';

const StyledWrapper = styled.div`
    position: fixed;
    z-index: 9999999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${hexToRGBA(colors.grayScale_600, 0.3)};
`;

const StyledLoader = styled(Loader)`
    transform: scale(1);
`;

export const ModalLoader = React.memo((props) => {
    return ReactDOM.createPortal(
        <StyledWrapper>
            <StyledLoader {...props} />
            <ScrollHider />
        </StyledWrapper>,
        document.body
    );
});
