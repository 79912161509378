import React, { useEffect, useRef, useState } from 'react';
import { useQueryParam, BooleanParam } from 'use-query-params';
import { useLocation } from 'react-router-dom';
import { useActions, useValues } from 'kea';

import { H3 } from 'components';
import { GlobalModal } from 'components/modals/GlobalModal';
import { Field } from 'components/Field';

import { StyledHeader, StyledSubmit, StyledText, StyledForm } from './units';

import authLogic from 'store/auth';
import { Helmet } from 'react-helmet-async';

const MODAL_CLOSE_TIMEOUT = 300;

const ResetPasswordModal = ({ ...rest }) => {
    const location = useLocation();
    const { resetPasswordConfirm } = useActions(authLogic);
    const { isLoading } = useValues(authLogic);

    const activationForm = useRef();

    const [error, setError] = useState();
    const [isSuccess, setIsSuccess] = useState('');
    const [password, setPassword] = useState('');
    const [pswdConfirm, setPswdConfirm] = useState('');
    const [isOpen, setIsOpen] = useQueryParam('reset_password', BooleanParam);

    const { state: { uid, token } = {} } = location;

    const hintMessage =
        password !== pswdConfirm
            ? 'Пароли не совпадают'
            : error &&
              Object.values(error).reduce((acc, errorField) => {
                  return acc + (errorField || []).join('\n');
              }, '');

    useEffect(() => {
        if (!location.state || !location.state.resetPassword) {
            setIsOpen(undefined, 'replaceIn');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);
    const onRequestClose = () => {
        setTimeout(() => {
            setError({});
            setIsSuccess(false);
        }, MODAL_CLOSE_TIMEOUT);
        setIsOpen(undefined, 'replaceIn');
        location.state = { ...location.state, resetPassword: false };
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        resetPasswordConfirm({
            uid,
            token,
            password,
            onSuccess: () => {
                setError({});
                setIsSuccess(true);
            },
            onFailure: (reason) => setError(reason)
        });
    };
    return (
        <GlobalModal closeTimeoutMS={MODAL_CLOSE_TIMEOUT} {...{ isOpen: isOpen || false, onRequestClose, ...rest }}>
            <Helmet>
                <title>CommunalCa | Создание пароля</title>
            </Helmet>
            <StyledHeader>Создайте новый пароль</StyledHeader>
            <StyledText error={error}>{hintMessage}</StyledText>
            {!isSuccess ? (
                <>
                    <StyledForm ref={activationForm} onSubmit={handleSubmit} action={''} method={'POST'}>
                        <Field
                            isError={password !== pswdConfirm || error}
                            required
                            isRequired
                            type={'password'}
                            variant={'filled'}
                            style={{ marginBottom: 32 }}
                            label={'Новый пароль'}
                            name={'password'}
                            value={password}
                            onChange={setPassword}
                        />
                        <Field
                            isError={password !== pswdConfirm || error}
                            required
                            isRequired
                            type={'password'}
                            variant={'filled'}
                            style={{ marginBottom: 32 }}
                            label={'Подтвердите пароль'}
                            name={'pswdConfirm'}
                            value={pswdConfirm}
                            onChange={setPswdConfirm}
                        />
                        <StyledSubmit
                            rounded
                            size={'md'}
                            disabled={!password || password !== pswdConfirm}
                            loading={isLoading}>
                            Сменить пароль
                        </StyledSubmit>
                    </StyledForm>
                </>
            ) : (
                <>
                    <H3>Пароль успешно сменен</H3>
                    <StyledSubmit rounded size={'md'} onClick={onRequestClose}>
                        Закрыть
                    </StyledSubmit>
                </>
            )}
        </GlobalModal>
    );
};

export { ResetPasswordModal };
