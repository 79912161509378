import React, { useRef, useState } from 'react';
import { useQueryParam, BooleanParam } from 'use-query-params';
import { useActions, useValues } from 'kea';

import { H3 } from 'components/index';
import { GlobalModal } from 'components/modals/GlobalModal';
import { Field } from 'components/Field';

import { StyledHeader, StyledSubmit, StyledText, StyledForm } from './units';

import authLogic from 'store/auth';
import { Helmet } from 'react-helmet-async';

const MODAL_CLOSE_TIMEOUT = 300;

const ChangePasswordModal = ({ ...rest }) => {
    const { changePassword } = useActions(authLogic);
    const { isLoading } = useValues(authLogic);

    const activationForm = useRef();

    const [error, setError] = useState();
    const [isSuccess, setIsSuccess] = useState('');

    const [passwordOld, setPasswordOld] = useState('');
    const [password, setPassword] = useState('');
    const [pswdConfirm, setPswdConfirm] = useState('');

    const [isOpen, setIsOpen] = useQueryParam('change_password', BooleanParam);

    const hintMessage =
        password !== pswdConfirm
            ? 'Пароли не совпадают'
            : error &&
              Object.values(error).reduce((acc, errorField) => {
                  return acc + (errorField || []).join('\n');
              }, '');

    const onRequestClose = () => {
        setTimeout(() => {
            setError({});
            setIsSuccess(false);
        }, MODAL_CLOSE_TIMEOUT);
        setIsOpen(undefined, 'replaceIn');
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        changePassword({
            passwordOld,
            password,
            onSuccess: () => {
                setError({});
                setIsSuccess(true);
            },
            onFailure: (reason) => setError(reason)
        });
    };
    return (
        <GlobalModal closeTimeoutMS={MODAL_CLOSE_TIMEOUT} {...{ isOpen: isOpen || false, onRequestClose, ...rest }}>
            <Helmet>
                <title>CommunalCa | Смена пароля</title>
            </Helmet>
            <StyledHeader>Смена пароля</StyledHeader>
            <StyledText error={error}>{hintMessage}</StyledText>
            {!isSuccess ? (
                <>
                    <StyledForm ref={activationForm} onSubmit={handleSubmit} action={''} method={'POST'}>
                        <Field
                            isError={(password && (password === passwordOld || pswdConfirm === passwordOld)) || error}
                            required
                            isRequired
                            type={'password'}
                            variant={'filled'}
                            style={{ marginBottom: 32 }}
                            label={'Старый пароль'}
                            name={'password'}
                            value={passwordOld}
                            onChange={setPasswordOld}
                        />
                        <Field
                            isError={
                                (password &&
                                    (password === passwordOld ||
                                        pswdConfirm === passwordOld ||
                                        password !== pswdConfirm)) ||
                                error
                            }
                            required
                            isRequired
                            type={'password'}
                            variant={'filled'}
                            style={{ marginBottom: 32 }}
                            label={'Новый пароль'}
                            name={'new_password'}
                            value={password}
                            onChange={setPassword}
                        />
                        <Field
                            isError={
                                (password &&
                                    (password === passwordOld ||
                                        pswdConfirm === passwordOld ||
                                        password !== pswdConfirm)) ||
                                error
                            }
                            required
                            isRequired
                            type={'password'}
                            variant={'filled'}
                            style={{ marginBottom: 32 }}
                            label={'Подтвердите пароль'}
                            name={'pswdConfirm'}
                            value={pswdConfirm}
                            onChange={setPswdConfirm}
                        />
                        <StyledSubmit
                            rounded
                            size={'md'}
                            disabled={!passwordOld || !password || password !== pswdConfirm}
                            loading={isLoading}>
                            Изменить пароль
                        </StyledSubmit>
                    </StyledForm>
                </>
            ) : (
                <>
                    <H3>Пароль успешно сменен</H3>
                    <StyledSubmit rounded size={'md'} onClick={onRequestClose}>
                        Закрыть
                    </StyledSubmit>
                </>
            )}
        </GlobalModal>
    );
};

export { ChangePasswordModal };
