import React, { useState } from 'react';

import { Matrix } from '../../components/Matrix';

export const MainPage = () => {
    const [activeItem, setActiveItem] = useState([2, 2]);
    return (
        <div style={{ background: '#fff', width: '100%', padding: '100px 20px' }}>
            <Matrix activeItem={activeItem} onChange={setActiveItem} />
        </div>
    );
};

export default MainPage;
