import React from 'react';

import { H2, PaperBlock } from 'components/index';

import { StyledWrap, StyledSectionHeader, StyledSectionText } from './units';
import colors from 'colors';

import instruction1 from 'assets/img/instruction/1.png';
import instruction2 from 'assets/img/instruction/2.png';
import instruction3 from 'assets/img/instruction/3.png';
import instruction4 from 'assets/img/instruction/4.png';
import instruction5 from 'assets/img/instruction/5.png';
import instruction6 from 'assets/img/instruction/6.png';
import instruction7 from 'assets/img/instruction/7.png';
import instruction8 from 'assets/img/instruction/8.png';
import instruction9 from 'assets/img/instruction/9.png';
import instruction10 from 'assets/img/instruction/10.png';
import instruction11 from 'assets/img/instruction/11.png';
import instruction12 from 'assets/img/instruction/12.png';
import instruction13 from 'assets/img/instruction/13.png';
import instruction14 from 'assets/img/instruction/14.png';
import instruction15 from 'assets/img/instruction/15.png';
import instruction16 from 'assets/img/instruction/16.png';
import instruction17 from 'assets/img/instruction/17.png';

const Instruction = () => {
    return (
        <StyledWrap>
            <H2 as={'h1'}>Инструкция</H2>
            <PaperBlock id="instruction" style={{ paddingTop: 60, display: 'flex', justifyContent: 'center' }}>
                <div style={{ maxWidth: 800 }}>
                    <StyledSectionHeader isFirst>Шаг 1. Регистрация</StyledSectionHeader>
                    <StyledSectionText>
                        <ol>
                            <li>
                                Если пользователь, не имея «Лицевого счета» и «Ключа» менеджера, хочет воспользоваться
                                продуктом по расчету ставки обслуживания многоквартирного дома, то регистрация возможна
                                только под уровнем&nbsp;<b>«Гость»</b>
                                <br />
                                <i>
                                    Лицензия «Гость» доступна любому пользователю.
                                    <br />
                                    Она позволяет осуществить расчет ставки управления и технического обслуживания по
                                    двум независимым многоквартирным домам в удельных (руб/м<sup>2</sup>; мес; год) и
                                    абсолютных величинах, в пересчете на определенное помещение в многоквартирном доме.
                                </i>
                            </li>
                            <li>
                                Если пользователь имеет «Лицевой счет», то регистрация возможна под уровнем&nbsp;
                                <b>«Резидент»</b>
                                <br />
                                <i>
                                    Лицензия «Резидент» доступна жителям, владеющим имуществом в многоквартирных домах, информация об управлении которых внесена в CommunalCa.
                                    <br />
                                    Позволяет осуществить расчет нормативной ставки за управление и техническую
                                    эксплуатацию многоквартирного дома. Сравнить нормативную и фактическую ставки
                                    обслуживания.
                                </i>
                            </li>
                            <li>
                                Если пользователь имеет «Ключ» менеджера, то регистрация возможна под уровнем&nbsp;
                                <b>«Менеджер»</b>
                                <br />
                                <i>
                                    Лицензия «Менеджер» позволяет осуществить расчет нормативной ставки за управление и
                                    техническую эксплуатацию многоквартирного дома. Сравнить нормативную и фактическую
                                    ставки обслуживания многоквартирного дома
                                    <br />
                                    Лицензия «Менеджер» доступна сотрудникам Управляющей организации, с
                                    соответствующими корпоративными документами, позволяющими осуществить исследование
                                    ставки по управлению и техническому обслуживанию многоквартирного дома. Пользователю
                                    доступен расчетный пакет отчетных документов. Расчет нормативных ставок
                                    осуществляется для вышеупомянутых целей и является коммерческой тайной.
                                </i>
                            </li>
                        </ol>
                        <img
                            src={instruction1}
                            alt="Форма регистрации"
                            height={300}
                            style={{ display: 'block', margin: '32px 0' }}
                        />
                        <i>
                            Обязательными являются поля, выделенные красной звёздочкой{' '}
                            <span style={{ color: colors.error_soft }}>*</span>
                        </i>
                    </StyledSectionText>
                    <StyledSectionHeader>Шаг 2. Личный кабинет</StyledSectionHeader>
                    <StyledSectionText>
                        В «Личный кабинет» можно войти, нажав на имя пользователя или иконку в правом верхнем углу
                        сайта.
                        <img src={instruction2} alt="Профиль в хедере" style={{ margin: '12px 0' }} />
                        В личном кабинете требуется подтвердить/активировать номер телефона и почту, по которой
                        зарегистрировался пользователь, нажав на кнопку «Активировать».
                        <img src={instruction3} alt="Настройки" style={{ margin: '24px 0' }} />
                        Через личный кабинет возможно открыть «Историю расчетов», нажав на соответствующую кнопку. В
                        открывшемся окне требуется выбрать расчет, данные по которому должен получить пользователь.
                        <img src={instruction4} alt="История" />
                    </StyledSectionText>
                    <StyledSectionHeader>Шаг 3. Начало расчета</StyledSectionHeader>
                    <StyledSectionText>
                        Пользователь должен выбрать уровень расчета («Гость», «Резидент» или «Менеджер»),
                        соответствующий его уровню его лицензии.
                        <br />
                        Далее нажать на кнопку «Рассчитать» -> «Новый расчет»
                        <div style={{ display: 'flex', alignItems: 'flex-start', marginTop: 24 }}>
                            <img src={instruction5} alt="Гость расчет" style={{ marginRight: 12 }} />
                            <img src={instruction6} alt="Гость модалка" style={{ maxHeight: 400 }} />
                        </div>
                    </StyledSectionText>
                    <StyledSectionHeader>Шаг 4. Расчет</StyledSectionHeader>
                    <StyledSectionText>
                        <ol>
                            <li>
                                Выбрать соответствующую доступную стадию расчета, начиная с первой «Выбор класса
                                обслуживания многоквартирных домов».
                                <img
                                    src={instruction7}
                                    alt="Клавиша 1"
                                    style={{ display: 'block', margin: '32px 0' }}
                                />
                            </li>
                            <li>
                                В «Матрице услуг по управлению и технической эксплуатации МКД» выбрать ячейку,
                                соответствующую классу и жизненному циклу многоквартирного дома.
                                <img
                                    src={instruction8}
                                    alt="Матрица классов здания"
                                    style={{ display: 'block', margin: '32px 0' }}
                                />
                                <i>
                                    Выделенная синим цветом ячейка подтверждает выбор стадии жизненного цикла и класса
                                    объекта
                                </i>
                            </li>
                            <li>
                                Ввести адрес многоквартирного дома.
                                <img
                                    src={instruction9}
                                    alt="Ввод адреса"
                                    style={{ display: 'block', margin: '32px 0' }}
                                />
                                <i style={{ textAlign: 'center', display: 'inline-block' }}>
                                    Если выбрать/ввести жилой комплекс, существующий в системе, появится выпадающее окно
                                    с выбором адресов в этом ЖК
                                </i>
                            </li>
                            <li>
                                Выбрать соответствующую доступную стадию расчета - «Ввод параметров многоквартирных
                                домов».
                                <img
                                    src={instruction10}
                                    alt="Клавиша 2"
                                    style={{ display: 'block', margin: '32px 0' }}
                                />
                            </li>
                            <li>
                                Ввести все известные пользователю значения в соответствующие ячейки.
                                <img
                                    src={instruction11}
                                    alt="Ввод площадей"
                                    style={{ display: 'block', margin: '32px 0' }}
                                />
                                <i>
                                    Если пользователь не знает всех данных по многоквартирному дому, в таком случае,
                                    необходимо ввести только «Общую площадь» МКД, далее нажать на кнопку «Продолжить»
                                </i>
                                <img
                                    src={instruction12}
                                    alt="Предупреждение об автоматическом вводе площадей"
                                    style={{ display: 'block', margin: '32px auto' }}
                                />
                                <i style={{ textAlign: 'center', display: 'inline-block' }}>
                                    Если нажать «Да», то система предложит подставить значения оставшихся ячеек из
                                    справочника программы
                                </i>
                            </li>
                            <li>
                                Выбрать соответствующую доступную стадию расчета - «Нормированная ставка обслуживания».
                                <img
                                    src={instruction13}
                                    alt="Клавиша 3"
                                    style={{ display: 'block', margin: '32px 0' }}
                                />
                            </li>
                            <li>
                                При нажатии на кнопку «Автоматическая гармонизированная ставка» предлагается ввести в
                                соседнем правом поле желаемую ставку обслуживания многоквартирного дома. После этого
                                система сама рассчитает и покажет классы услуг, доступные для данного значения ставки.
                                <img
                                    src={instruction14}
                                    alt="Автоматическая гармонизированная ставка"
                                    style={{ display: 'block', margin: '32px auto' }}
                                />
                            </li>
                            <li>
                                При нажатии на кнопку «Экспертная гармонизированная ставка» пользователю предлагается:
                                <br />
                                <div style={{ paddingLeft: 16 }}>
                                    a. Ввести значение ставки по любой выбранной услуге;
                                    <br />
                                    b. Перетащить «бегунок со значением» соответствующий выбранным услугам на
                                    предполагаемое пользователем место.
                                </div>
                                <img
                                    src={instruction15}
                                    alt="Выбор услуг"
                                    style={{ display: 'block', margin: '32px 0' }}
                                />
                                <i style={{ textAlign: 'center', display: 'inline-block' }}>
                                    Если пользователь будет перетаскивать «бегунок со значением» или вводить значение
                                    ставки по любой выбранной услуге, то автоматически включится режим «Экспертной
                                    гармонизированной ставки»
                                </i>
                            </li>
                            <li>
                                Выбрать соответствующую доступную стадию расчета - «Экспертная гармонизированная ставка
                                ТО».
                                <img
                                    src={instruction16}
                                    alt="Клавиша 4"
                                    style={{ display: 'block', margin: '32px 0' }}
                                />
                            </li>
                            <li>
                                При нажатии на кнопку «Отчеты и сметы» пользователю предлагается скачать, отправить по
                                почте или распечатать соответствующий документ, основанный на совершенном расчете. В
                                правом нижнем углу пользователю предлагается выбрать на основании каких данных получать
                                отчет:
                                <ul>
                                    <li>
                                        Нормативная ставка <br />
                                        <b>Нормативная ставка</b> обеспечивает управление и техническую эксплуатацию
                                        недвижимости, достаточную для сохранения стоимости квартиры на протяжении
                                        долгого периода и поддержания самого высокого уровня безопасности, комфорта и
                                        жизнеобеспечения, который позволяет обеспечить набор инженерных систем
                                        многоквартирного дома.
                                    </li>
                                    <li>
                                        Последняя рассчитанная гармонизированная ставка
                                        <br />
                                        <b>Гармонизированная ставка</b> – это компромисс между желанием собственника
                                        платить меньше и уровнем обслуживания, который не обеспечивает сохранение
                                        вышеуказанных параметров, но все еще приемлем с точки зрения потребителя.
                                    </li>
                                </ul>
                                <img
                                    src={instruction17}
                                    alt="Отчеты"
                                    style={{ display: 'block', margin: '32px auto', maxHeight: 400 }}
                                />
                            </li>
                        </ol>
                    </StyledSectionText>
                </div>
            </PaperBlock>
        </StyledWrap>
    );
};

export { Instruction };
