import React, { useState, useMemo } from 'react';
import styled from '@emotion/styled';

import { Positioner } from 'components/Positioner';
import debounce from 'utils/debounce';
import colors from 'colors';

const StyledTooltipPaper = styled.div`
    position: relative;
    padding: 12px;
    background: ${colors.grayScale_0};
    box-shadow: 0 0 10px ${colors.grayScale_300};
    border-radius: 3px;
    font-size: 16px;
`;

const StyledArrow = styled.div`
    width: 20px;
    height: 20px;
    z-index: 1;
    overflow: hidden;
    pointer-events: none;
    &:before {
        content: '';
        position: absolute;
        top: 4px;
        right: 4px;
        bottom: 4px;
        left: 4px;
        background: #ffffff;
        box-shadow: 0 0 10px #c6cacc;
    }
    [data-popper-placement^='top'] > & {
        top: 100%;
        &:before {
            transform: translateY(-10px) rotate(45deg);
        }
    }
    [data-popper-placement^='bottom'] > & {
        bottom: 100%;
        &:before {
            transform: translateY(10px) rotate(45deg);
        }
    }
    [data-popper-placement^='left'] > & {
        left: 100%;
        &:before {
            transform: translateX(-10px) rotate(45deg);
        }
    }
    [data-popper-placement^='right'] > & {
        right: 100%;
        &:before {
            transform: translateX(10px) rotate(45deg);
        }
    }
`;

const StyledTooltipWrapper = styled.div`
    z-index: 100;
`;

const TooltipMapper = React.forwardRef(({ children, arrowProps, ...rest }, ref) => (
    <StyledTooltipWrapper ref={ref} {...rest}>
        <StyledArrow {...arrowProps} />
        <StyledTooltipPaper>{children}</StyledTooltipPaper>
    </StyledTooltipWrapper>
));

export const Tooltip = ({ children, trigger, popperOptions, isOpen: isOpenProp }) => {
    const [isOpenState, setIsOpenState] = useState(false);
    const debouncedDispatchIsOpen = useMemo(
        () => debounce(({ type }) => setIsOpenState(type !== 'mouseleave'), 200),
        []
    );

    const isOpen = isOpenProp ?? isOpenState;

    const additionalProps = {
        isOpen,
        onMouseEnter: debouncedDispatchIsOpen,
        onMouseLeave: debouncedDispatchIsOpen
    };

    return (
        <Positioner triggerProps={additionalProps} {...{ isOpen, trigger, popperOptions }}>
            <TooltipMapper {...additionalProps}>{children}</TooltipMapper>
        </Positioner>
    );
};
