import React, { useEffect, useState } from 'react';
import { useActions, useValues } from 'kea';
import { toast } from 'react-toastify';

import { Link } from 'react-router-dom';
import { PaperBlock } from 'components';
import { MemoizedBriefData } from 'components/BriefData';

import { MemoizedCalcHeadings } from 'components/CalcHeadings';
import { FinishModal, StyledButton, StyledButtonsSplit } from './units';

import calcLogic from 'store/calculation';
import authLogic from 'store/auth';
import userLogic from 'store/user';

import { CALC_VISIBILITIES } from 'config';

export const Calc4 = ({ history }) => {
    const { isAuth } = useValues(authLogic);
    const { userData: { role, is_superuser: isSuperuser } = {} } = useValues(userLogic);
    const { calc1Done, calc2Done, calc3Done } = useValues(calcLogic);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const { clearData } = useActions(calcLogic);

    useEffect(() => {
        if (isAuth) {
            const roleVisibilities = Object.entries(CALC_VISIBILITIES).reduce((result, [entry, value]) => {
                result[entry] = isSuperuser || value[role];
                return result;
            }, {});
            if (!calc1Done && roleVisibilities['calc1']) {
                toast.error('Вы не завершили первый шаг, поэтому мы вернули вас на него');
                history.push('/calc1');
            } else if (!calc2Done && roleVisibilities['calc2']) {
                toast.error('Вы не завершили второй шаг, поэтому мы вернули вас на него');
                history.push('/calc2');
            } else if (!calc3Done && roleVisibilities['calc3']) {
                toast.error('Вы не завершили третий шаг, поэтому мы вернули вас на него');
                history.push('/calc3');
            }
        }
    }, [isAuth]);

    return (
        <>
            <MemoizedCalcHeadings />
            <PaperBlock>
                <MemoizedBriefData fetchExtra />
                <StyledButtonsSplit>
                    <StyledButton outlined color="main_400" onClick={() => setIsModalOpen(true)}>
                        Завершить
                    </StyledButton>
                    <StyledButton rounded={true} as={Link} to="/buttons">
                        Редактировать
                    </StyledButton>
                </StyledButtonsSplit>
                <FinishModal
                    isOpen={isModalOpen}
                    onClose={() => {
                        history.push('/');
                        clearData();
                    }}
                />
            </PaperBlock>
        </>
    );
};

export default Calc4;
