import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import colors from 'colors';
import { Loader } from 'components';

const StyledTilesWrapper = styled.div`
    display: grid;
    grid-gap: 25px;
    grid-template-columns: repeat(${({ count }) => count}, 1fr);
    min-height: 100px;
`;

const StyledTile = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 27px;
    background-color: ${colors.grayScale_50};
    text-align: center;
    color: inherit;
    text-decoration: none;
    transition: background-color 0.3s ease;
    ${({ to }) =>
        to &&
        css`
            &:hover {
                background-color: ${colors.grayScale_100};
            }
        `}
`;

export const Tiles = ({ items }) => (
    <StyledTilesWrapper count={items.length}>
        {items.map(({ title, to }, index) => {
            return (
                <StyledTile key={index} to={to} as={to ? Link : undefined}>
                    {title ?? <Loader />}
                </StyledTile>
            );
        })}
    </StyledTilesWrapper>
);
