import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { transformValue } from './utils';

import {
    StyledWrapper,
    StyledLabelWrapper,
    StyledInput,
    StyledLabelText,
    StyledBorder,
    StyledCheckbox,
    Suffix,
    FieldHint,
    Select,
    StyledLoader
} from './units';

export const Field = ({
    value,
    onChange,
    className,
    style,
    label,
    isSuccess,
    isError,
    disabled = false,
    required = false,
    options,
    type = 'text',
    suffix,
    hint,
    hintMaxWidth,
    callbackId,
    variant = 'normal',
    borderConfig = {},
    inputStyles,
    wrapperStyles,
    labelWrapperStyles,
    nativeEvent,
    isLoading,
    ...props
}) => {
    const ref = useRef();
    const isCheckbox = type === 'checkbox';
    const isSelect = type === 'select';

    let input;
    switch (true) {
        case isCheckbox:
            input = (
                <StyledCheckbox
                    nestingLabel
                    checked={value}
                    {...{ onChange, disabled, hint, callbackId, isLoading, ...props }}
                />
            );
            break;
        case isSelect:
            input = <Select {...{ value, onChange, options, disabled, hint, callbackId, isLoading }} />;
            break;
        default:
            input = (
                <StyledInput
                    onChange={(event) =>
                        onChange?.(nativeEvent ? event : transformValue(event.target.value, type), {
                            event,
                            callbackId
                        })
                    }
                    value={value === null ? '' : String(value)}
                    {...{ type, label, disabled, required, inputStyles, isLoading, ...props }}
                />
            );
    }

    return (
        <StyledWrapper {...{ className, wrapperStyles, isError, isSuccess, variant, style, ref }}>
            <StyledLabelWrapper {...{ label, hint, disabled, labelWrapperStyles, isCheckbox }}>
                {isLoading && <StyledLoader />}
                {input}
                {!!suffix && <Suffix {...{ value, suffix, disabled }} />}
                {label && (
                    <StyledLabelText {...{ isError, disabled, value, required, isCheckbox }}>{label}</StyledLabelText>
                )}
                {variant !== 'filled' && (
                    <StyledBorder role="presentation" {...{ isError, isSuccess, disabled, ...borderConfig }} />
                )}
            </StyledLabelWrapper>
            {hint && (
                <FieldHint maxWidth={hintMaxWidth} wrapperRef={ref}>
                    {hint}
                </FieldHint>
            )}
        </StyledWrapper>
    );
};

Field.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.oneOf([null])]),
    variant: PropTypes.oneOf(['normal', 'filled']),
    type: PropTypes.oneOf([
        'select',
        'checkbox',
        'text',
        'tel',
        'password',
        'email',
        'number',
        'date',
        'datetime-local',
        'month',
        'time',
        'url',
        'week'
    ]),
    options: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                value: PropTypes.string.isRequired,
                label: PropTypes.string,
                view: PropTypes.node,
                disabled: PropTypes.bool
            })
        ])
    ),
    hintMaxWidth: PropTypes.number,
    callbackId: PropTypes.any,
    inputStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    borderConfig: PropTypes.shape({
        borderFocusColor: PropTypes.string,
        borderHoverColor: PropTypes.string,
        borderColor: PropTypes.string
    })
};

export const MemoizedField = React.memo(Field);
MemoizedField.propTypes = Field.propTypes;
