import styled from '@emotion/styled';
import colors from 'colors';

export { RowBase } from './RowBase';
export { MainRow } from './MainRow';

export const StyledHeading = styled.div`
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    color: ${colors.main_400};
`;

export const StyledWrapper = styled.div`
    pointer-events: ${({ isBlocked }) => (isBlocked ? 'none' : 'initial')};
    opacity: ${({ isBlocked }) => (isBlocked ? 0.7 : 1)};
`;
