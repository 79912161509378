import styled from '@emotion/styled';
import colors from 'colors';

export { RowHeading } from './RowHeading';
export { Price } from './Price';

export const Milestone = styled.div`
    position: relative;
    min-height: 100%;
    &:before {
        content: '';
        height: 2px;
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: ${colors.main_100};
    }
    &:after {
        content: '';
        position: absolute;
        z-index: 2;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: ${colors.grayScale_0};
        box-shadow: 0 1px 5px ${colors.main_300};
    }
`;

export const Line = styled.div`
    position: absolute;
    z-index: 1;
    left: 50%;
    top: ${({ isFirstLine }) => (isFirstLine ? '50%' : '-8px')};
    bottom: ${({ isLastLine }) => (isLastLine ? '50%' : '-8px')};
    transform: translateX(-50%);
    width: 1px;
    background-color: ${colors.success};
`;
