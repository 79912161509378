import React from 'react';
import { useValues } from 'kea';
import { Route } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
// import { useHistory } from 'react-router-dom';

import { LoginRegModal } from 'components/modals/LoginRegModal';

import { Layout } from 'components/Layout';

import authLogic from 'store/auth';

const PrivateRoute = ({ component: Component, headerPosition, title, isPrivate, blankContainer, ...restProps }) => {
    const { isAuth } = useValues(authLogic);
    // const history = useHistory();
    return (
        <Route
            {...restProps}
            render={(props) => (
                <>
                    <Helmet>
                        <title>CommunalCa{title ? ` | ${title}` : ''}</title>
                    </Helmet>
                    <Layout blankContainer={blankContainer}>
                        <Component {...props} />
                        {isPrivate && (
                            <LoginRegModal
                                isNeedAuth
                                isOpen={!isAuth}
                                // onRequestClose={() => history.push('/')}
                            />
                        )}
                    </Layout>
                </>
            )}
        />
    );
};

export default PrivateRoute;
