import React, { useState, useEffect, useRef, useMemo } from 'react';

import { useThrottle, isNil } from 'utils';

import { StyledPriceWrapper, StyledInputWrapper, StyledInput, cursorGlobal } from './units';
import {
    getPositionFromPrice,
    getEventPosition,
    getPriceFromPosition,
    validateInput,
    numberToCurrency,
    useDrag,
    blurOnEnterPress
} from './utils';
import { TIMEOUT_DURATION } from './config';

export const Price = ({
    id,
    price,
    milestones,
    onDragEnd,
    onChange,
    magnetMilestones = true,
    disableInputs,
    priceToShow,
    isEditable,
    ...rest
}) => {
    const [inputPrice, setInputPrice] = useState(price);

    useEffect(() => {
        setInputPrice(price);
    }, [price]);

    const position = useMemo(() => getPositionFromPrice(price, milestones), [price, milestones]);
    const wrapperRef = useRef();

    const runOnChange = (newPrice = inputPrice) => {
        if (newPrice !== price) onChange({ id, price: newPrice });
    };

    const dragListener = useThrottle((e) => {
        if (wrapperRef.current) {
            const position = getEventPosition(e, wrapperRef.current?.parentNode, milestones.length);
            const [price, milestonePrice] = getPriceFromPosition(position, milestones);
            runOnChange(magnetMilestones ? milestonePrice : price);
        }
    }, TIMEOUT_DURATION);

    const handleBlur = async () => {
        const newPrice = validateInput(inputPrice, milestones, magnetMilestones);
        await setInputPrice(price);
        runOnChange(newPrice);
    };

    const handleInputOnChange = ({ target: { value } }) => setInputPrice(Number(value));

    const [isDrag, dispatchIsDrag] = useDrag(dragListener, onDragEnd);

    const currency = isNil(priceToShow)
        ? numberToCurrency(price)
        : typeof priceToShow === 'object'
        ? priceToShow
        : numberToCurrency(priceToShow);

    return (
        <>
            <StyledPriceWrapper
                style={{ left: position + '%' }}
                ref={wrapperRef}
                onMouseDown={isEditable ? dispatchIsDrag : undefined}
                {...{ isDrag, isEditable, ...rest }}>
                <StyledInputWrapper noEvents={disableInputs}>
                    {currency}
                    {isNil(priceToShow) && (
                        <StyledInput
                            disableInputs={disableInputs}
                            type="number"
                            onBlur={handleBlur}
                            onKeyPress={blurOnEnterPress}
                            onChange={handleInputOnChange}
                            value={inputPrice}
                            min={milestones[0]}
                            max={milestones[milestones.length - 1]}
                            {...{ isDrag }}
                        />
                    )}
                </StyledInputWrapper>
            </StyledPriceWrapper>
            {isDrag && cursorGlobal}
        </>
    );
};
