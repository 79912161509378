import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import { colors } from 'colors';
import { hexToRGBA } from 'utils';

export const StyledLink = styled(Link)`
    text-decoration: none;
`;

export const StyledButtonWrap = styled.div`
    flex-basis: 50%;
`;

export const StyledTiltInner = styled.div`
    flex: 1;
    transform: translateZ(25px);
    color: #fff;
    font-weight: bold;
    font-size: 40px;
    will-change: transform, opacity;
    transition: 0.3s ease transform, 0.3s ease opacity, 0.3s ease visibility;
`;

export const StyledTiltHintWrapper = styled.div`
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateY(10px) translateZ(25px);
    opacity: 0;
    visibility: hidden;
    color: #fff;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    padding: 60px 32px;
    text-align: left;
    will-change: transform, opacity;
    transition: 0.3s ease transform, 0.3s ease opacity, 0.3s ease visibility;
`;

export const StyledTiltHintHeader = styled.div`
    width: 100%;
    flex: 1;
`;

export const StyledTiltHintText = styled.div`
    margin-top: 46px;
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    & a {
        text-decoration: underline;
        font-weight: bold;
        color: ${colors.grayScale_0};
        transition: 0.3s ease color;
        &:hover {
            color: ${colors.grayScale_300};
        }
    }
`;

export const StyledNumberWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 60px;
    border: 1px solid #fff;
    position: absolute;
    top: 30px;
    right: 30px;
    color: ${hexToRGBA(colors.grayScale_0, 0.65)};
    transform: translateZ(25px);
    will-change: transform, opacity;
    transition: 0.3s ease transform, 0.3s ease opacity, 0.3s ease visibility;
`;

export const StyledNumberInner = styled.div`
    font-weight: bold;
    font-size: 36px;
    line-height: 44px;
    ${({ children }) =>
        !children &&
        css`
            &:before {
                content: counter(tilt-button);
                counter-increment: tilt-button;
            }
        `};
`;

export const StyledTiltElement = styled.div`
    position: relative;
    min-width: 500px;
    //width: 638px;
    height: 285px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    transform-style: preserve-3d;
    background: ${({ color }) => colors[color] || color || 'linear-gradient(179deg, #0F4C81 -40.09%, #56CCF2 136.48%)'};
    border-radius: 15px;
    padding: 60px 24px;
    box-shadow: 0 2px 40px #e5e5e5
        ${({ highlight, shadowColor }) =>
            highlight && `, 0 0 10px 7px ${colors[shadowColor] || shadowColor || colors.main_400}`};
    margin: 0 32px 64px;
    overflow: hidden;
    text-decoration: none;
    pointer-events: ${({ disable }) => (disable ? 'none' : 'auto')};
    &:hover ${StyledTiltInner} {
        transform: translateY(-10px);
        opacity: 0;
        visibility: hidden;
    }

    &:hover ${StyledNumberWrapper} {
        transform: translateY(-20px);
        opacity: 0;
        visibility: hidden;
    }

    &:hover ${StyledTiltHintWrapper} {
        transform: translateY(0px) translateZ(25px);
        opacity: 1;
        visibility: visible;
    }
`;

export const StyledDisableShadow = styled.div`
    position: absolute;
    cursor: not-allowed;
    z-index: 100;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: ${({ isActive }) => (isActive ? 'block' : 'none')};
    background: ${hexToRGBA(colors.grayScale_0, 0.7)};
`;
