import React, { useEffect } from 'react';
import styled from '@emotion/styled';

import { H3, Button } from 'components';
import { GlobalModal } from 'components/modals/GlobalModal';

export const StyledButtonsSplit = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
`;

export const StyledButton = styled(Button)`
    min-width: 290px;
`;

export const FinishModal = ({ onClose, timeout = 4000, isOpen }) => {
    useEffect(() => {
        if (isOpen) {
            const timer = setTimeout(onClose, timeout);
            return () => clearTimeout(timer);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    return (
        <GlobalModal isOpen={isOpen} onRequestClose={onClose}>
            <H3>Спасибо за обращение!</H3>
        </GlobalModal>
    );
};
