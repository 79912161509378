import React, { useState, useRef } from 'react';
import { useActions, useValues } from 'kea';
import { useHistory } from 'react-router-dom';

import authLogic from 'store/auth';

import {
    StyledForm,
    StyledHeader,
    StyledHint,
    StyledLink,
    StyledSubmit,
    StyledField,
    StyledErrorLabel,
    StyledButtonWrap,
    StyledSuccessLabel
} from './units';

const LoginForm = ({ onSuccess, onClose, onRegistrationRequest, isSuccessLabel, isSingleForm }) => {
    const history = useHistory();
    const loginForm = useRef();
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const { logIn } = useActions(authLogic);
    const { error, isAuth, isLoading, validationError } = useValues(authLogic);

    const onForgotPasswordRequest = () => {
        onClose && onClose();
        history.push('/?forgot=1', { forgotEmail: login });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await logIn({
            login,
            password,
            onSuccess: () => {
                onSuccess && onSuccess();
                setLogin('');
                setPassword('');
            }
        });
    };
    return isAuth && isSuccessLabel ? (
        <StyledSuccessLabel>
            Спасибо!
            <br />
            <br />
            Вы успешно авторизовались
        </StyledSuccessLabel>
    ) : (
        <StyledForm ref={loginForm} onSubmit={handleSubmit} action={''} method={'POST'}>
            <StyledHeader>Вход</StyledHeader>
            {onRegistrationRequest && (
                <StyledHint style={{ marginBottom: 56 }}>
                    Нет личного кабинета?{' '}
                    <StyledLink onClick={() => onRegistrationRequest()}>Зарегистрироваться</StyledLink>
                </StyledHint>
            )}
            <StyledField
                style={{ marginBottom: 22 }}
                type="text"
                name="username"
                label="Логин"
                variant={'filled'}
                value={login}
                onChange={setLogin}
                isRequired
            />
            <StyledField
                type="password"
                label="Пароль"
                name="password"
                variant={'filled'}
                value={password}
                onChange={setPassword}
                isRequired
            />
            {error && <StyledErrorLabel>{Object.values(validationError).join(' ')}</StyledErrorLabel>}
            <StyledButtonWrap>
                <StyledSubmit isSingleForm={isSingleForm} rounded size={'md'} loading={isLoading}>
                    Войти
                </StyledSubmit>
                <StyledHint>
                    {onForgotPasswordRequest && (
                        <StyledLink style={{ marginLeft: 40 }} onClick={onForgotPasswordRequest}>
                            Забыли пароль?
                        </StyledLink>
                    )}
                </StyledHint>
            </StyledButtonWrap>
        </StyledForm>
    );
};

export { LoginForm };
