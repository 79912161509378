import React, { useEffect } from 'react';
import { useActions, useValues } from 'kea';

import colors from 'colors';

import { H2, H3, Loader, PaperBlock } from 'components/index';
import { AutoComplete } from 'components/AutoComplete';
import { BriefData } from 'components/BriefData';

import authLogic from 'store/auth';
import historyLogic from 'store/calculationHistory';
import calculationLogic from 'store/calculation';

import { StyledWrap } from './units';

const calcName = (option) => {
    return `${option.name} от ${new Date(option.date).toLocaleDateString('ru-Ru')} ${new Date(
        option.date
    ).toLocaleTimeString('ru-Ru')}`;
};

const CalcHistory = () => {
    const { isAuth } = useValues(authLogic);
    const { fetchHistory, fetchCalculation } = useActions(historyLogic);
    const { calculation, calculationLoading } = useValues(historyLogic);

    const { setData, clearData } = useActions(calculationLogic);

    useEffect(() => {
        clearData();
        setData(calculation);
    }, [calculation, setData, clearData]);

    if (!isAuth) return null;

    return (
        <StyledWrap>
            <H2>Личные данные</H2>
            <PaperBlock id="main-settings" style={{ paddingTop: 60 }}>
                <AutoComplete
                    getOptionValue={(option) => option.id}
                    placeholder={'Введите название расчета'}
                    onChange={fetchCalculation}
                    getOptionLabel={calcName}
                    getSuggestions={(value) => {
                        return fetchHistory({ name: value });
                    }}
                />
                {calculationLoading ? (
                    <Loader />
                ) : (
                    calculation &&
                    calculation.id && (
                        <>
                            <H3 style={{ color: colors.main_400 }}>Расчет №{calculation.id}</H3>
                            <BriefData historicData />
                        </>
                    )
                )}
            </PaperBlock>
        </StyledWrap>
    );
};

export { CalcHistory };
