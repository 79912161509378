import { colors } from 'colors';
import { hexToRGBA } from 'utils/index';

export const defaultStyles = {
    container: (provided) => {
        return { ...provided, fontSize: 16, borderWidth: 1, outline: 'none' };
    },
    dropdownIndicator: (provided, state) => {
        return { ...provided, color: state.isFocused ? colors.main_400 : colors.grayScale_400 };
    },
    clearIndicator: (provided, state) => {
        return { ...provided, color: state.isFocused ? colors.main_400 : colors.grayScale_400 };
    },

    placeholder: (provided) => {
        return { ...provided, color: colors.grayScale_800 };
    },
    indicatorSeparator: () => {
        return { display: 'nones' };
    },
    input: (provided) => {
        return { ...provided, '& input': { lineHeight: '1.2', letterSpacing: '-0.015em' } };
    },
    control: (provided, state) => {
        return {
            ...provided,
            fontSize: 16,
            borderWidth: state.selectProps.variant === 'filled' ? '0px !important' : '1px !important',
            background: state.selectProps.variant === 'filled' ? colors.grayScale_50 : colors.grayScale_0,
            boxShadow: 'none',
            borderColor: state.isFocused ? colors.main_400 : colors.grayScale_400,
            '&:hover': {
                borderColor: state.isFocused ? colors.main_400 : colors.grayScale_700
            }
        };
    },
    menu: (provided) => {
        return { ...provided, boxShadow: `0 2px 12px ${hexToRGBA(colors.grayScale_900, 0.15)}` };
    },
    option: (provided, state) => {
        return {
            ...provided,
            height: 60,
            display: 'flex',
            alignItems: 'center',
            fontSize: 16,
            cursor: 'pointer',
            outline: 'none',
            color: state.isSelected ? colors.grayScale_0 : state.data?.data?.housingComplex && colors.success
        };
    }
};

export const defaultColors = {
    neutral0: colors.grayScale_0,
    neutral5: colors.grayScale_50,
    neutral10: colors.grayScale_50,
    neutral20: colors.grayScale_100,
    neutral30: colors.grayScale_200,
    neutral40: colors.grayScale_300,
    neutral50: colors.grayScale_400,
    neutral60: colors.grayScale_600,
    neutral70: colors.grayScale_700,
    neutral80: colors.grayScale_800,
    neutral90: colors.grayScale_900,
    danger: colors.error_soft,
    primary25: colors.main_100,
    primary50: colors.main_200,
    primary75: colors.main_300,
    primary: colors.main_400
};
