import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import colors from 'colors';

import { getHoverStatus } from './utils';
import { hexToRGBA } from 'utils';
import { Hint } from 'components/Hint';

export const StyledTable = styled.table`
    width: 100%;
    padding: 15px 15px 15px 0;
    border-spacing: 0;
    table-layout: fixed;
    font-size: 20px;
`;

const StyledRowsMainHeadingCell = styled.td`
    position: relative;
    z-index: 1;
    padding: 0;
    border-radius: 6px;
    background: ${colors.main_400};
    color: ${colors.grayScale_0};
`;
const StyledRowsMainHeadingInner = styled.div`
    min-width: 80px;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    display: flex;
    align-items: center;
    justify-content: center;
`;
export const RowsMainHeading = (props) => (
    <StyledRowsMainHeadingCell {...props} scope="row">
        <StyledRowsMainHeadingInner>Стадия жизненного цикла</StyledRowsMainHeadingInner>
    </StyledRowsMainHeadingCell>
);

const StyledCell = styled.td`
    height: 136px;
    padding: 40px;
    position: relative;
    text-align: center;
    font-weight: 700;
    color: ${({ isSelected }) => (isSelected ? colors.grayScale_0 : 'initial')};
    cursor: ${({ onClick }) => (onClick ? 'pointer' : 'inherit')};
    font-size: 1.5em;
    // text on card
    & > span {
        position: relative;
        z-index: 5;
    }
    // card
    &:before {
        content: '';
        position: absolute;
        z-index: 1;
        top: 20px;
        left: 20px;
        bottom: 20px;
        right: 20px;
        border-radius: 6px;
        background: ${({ isSelected }) => (isSelected ? colors.main_400 : colors.grayScale_0)};
        box-shadow: 0 1px 10px rgba(130, 130, 130, 0.15);
    }
`;

const Hover = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
`;
const ColHover = styled(Hover)`
    background-color: ${hexToRGBA(colors.main_100, 0.2)};
    // to prolong hover on column start
    th > & {
        top: -15px;
        border-radius: 10px 10px 0 0;
    }
    // to prolong hover on column end
    tr:last-of-type > td > & {
        bottom: -15px;
        border-radius: 0 0 10px 10px;
    }
`;
const RowHover = styled(Hover)`
    background-color: ${colors.grayScale_50};
    // to fill border-radius of main rows' heading
    ${({ scope }) => scope && `left: -15px;`}
    // to prolong hover
    td:last-of-type > & {
        right: -15px;
        border-radius: 0 10px 10px 0;
    }
`;

const StyledTh = styled.th`
    position: relative;
    color: ${colors.main_400};
    padding: 15px 0 50px;
    &:after {
        content: '';
        height: 4px;
        position: absolute;
        bottom: 35px;
        left: 0;
        right: 0;
        background-color: currentColor;
    }
    & > span {
        display: inline-block;
        max-width: 150px;
        position: relative;
        z-index: 1;
    }
`;
const ColHeadingCellHint = styled(Hint)`
    position: absolute;
    top: 0;
    right: 13px;
    z-index: 1;
`;
export const ColHeadingCell = ({ children, hover, setHover, tariffIndex, hint, ...restProps }) => {
    const tariffHovered = getHoverStatus(hover, tariffIndex)[0];
    return (
        <StyledTh {...restProps} onMouseEnter={() => setHover([tariffIndex, -1])}>
            {tariffHovered && <ColHover role="presentation" />}
            <span>{children}</span>
            {hint && <ColHeadingCellHint>{hint}</ColHeadingCellHint>}
        </StyledTh>
    );
};

const RowHeadingCellHint = styled(Hint)`
    position: absolute;
    top: 50%;
    right: 40px;
    transform: translateY(-50%);
    z-index: 1;
`;
const StyledCellForRowHeading = styled(StyledCell)`
    padding-right: 80px;
    text-align: left;
    font-weight: 500;
    font-size: inherit;
    &:before {
        background: #f8f8f8;
        box-shadow: none;
    }
`;
export const RowHeadingCell = ({ children, hover, setHover, stageIndex, hint, ...restProps }) => {
    const stageHovered = getHoverStatus(hover, undefined, stageIndex)[1];
    const scope = 'row';
    return (
        <StyledCellForRowHeading
            {...{ stageHovered, scope, ...restProps }}
            onMouseEnter={() => setHover([-1, stageIndex])}>
            {stageHovered && <RowHover scope={scope} role="presentation" />}
            <span>{children}</span>
            {hint && <RowHeadingCellHint>{hint}</RowHeadingCellHint>}
        </StyledCellForRowHeading>
    );
};
RowHeadingCell.propTypes = {
    children: PropTypes.node,
    hint: PropTypes.node,
    hover: PropTypes.arrayOf(PropTypes.number),
    setHover: PropTypes.func,
    stageIndex: PropTypes.number
};

const RegularCellHint = styled(Hint)`
    position: absolute;
    top: 30px;
    right: 27px;
    z-index: 1;
    cursor: default;
`;
export const RegularCell = React.memo(
    ({ isSelected, hover, setHover, tariffIndex, stageIndex, hint, onChange, ...restProps }) => {
        const [tariffHovered, stageHovered] = getHoverStatus(hover, tariffIndex, stageIndex);
        const hintRef = useRef();
        let content = null;

        if (isSelected || (tariffHovered && stageHovered)) {
            content = (
                <>
                    <span>{String.fromCharCode(97 + tariffIndex) + (stageIndex + 1)}</span>
                    {hint && <RegularCellHint ref={hintRef}>{hint}</RegularCellHint>}
                </>
            );
        }

        const onClick = ({ target }) => {
            // don't count clicks on active item and hint icon
            if (
                !isSelected &&
                (!hintRef.current || (hintRef.current !== target && !hintRef.current.contains(target)))
            ) {
                onChange([tariffIndex, stageIndex]);
            }
        };

        return (
            <StyledCell
                {...{ tariffHovered, stageHovered, onClick, isSelected, ...restProps }}
                onMouseEnter={() => setHover([tariffIndex, stageIndex])}>
                {stageHovered && <RowHover role="presentation" />}
                {tariffHovered && <ColHover role="presentation" />}
                {content}
            </StyledCell>
        );
    }
);
RegularCell.propTypes = {
    hint: PropTypes.node,
    hover: PropTypes.arrayOf(PropTypes.number),
    setHover: PropTypes.func,
    stageIndex: PropTypes.number,
    tariffIndex: PropTypes.number,
    isSelected: PropTypes.bool
};

export const colgroup = (
    <colgroup>
        <col width={80} />
        <col width={390} />
    </colgroup>
);
