import { kea } from 'kea';
import axios from 'utils/axiosWrapper';
import { toast } from 'react-toastify';

import { renameKeysByFn } from 'utils/renameKeys';
import { isNil } from 'utils';

import calcLogic from 'store/calculation';

export default kea({
    path: () => ['scenes', 'calc', 'history'],

    connect: {
        actions: [calcLogic, ['fetchAddress']]
    },

    loaders: ({ actions }) => ({
        calculation: [
            {},
            {
                fetchCalculation: async (params) => {
                    const { id } = params || {};
                    if (isNil(id)) {
                        return {};
                    }
                    const { status, data: result = {} } = await axios({
                        method: 'get',
                        url: `/calculations/${id}`
                    }).catch((e) => e);

                    if (status === 200) {
                        if (result.address) {
                            actions.fetchAddress(result.address);
                        }
                        return renameKeysByFn(result || {});
                    } else {
                        const error = 'Ошибка получения расчета';
                        toast.error(error);
                        return {};
                    }
                }
            }
        ],
        reports: [
            {},
            {
                fetchReports: async (params) => {
                    const { id } = params || {};
                    if (!id) {
                        return [];
                    }
                    const { status, data: result = {} } = await axios({
                        method: 'get',
                        url: `/reports/${id}`
                    }).catch((e) => e);

                    if (status === 200) {
                        return result;
                    } else {
                        const error = 'Ошибка получения расчета';
                        toast.error(error);
                        return {};
                    }
                }
            }
        ]
    }),
    thunks: () => {
        return {
            fetchHistory: async ({ name = '' /*, page*/ }) => {
                const {
                    status,
                    data: { results = [] /*, count*/ }
                } = await axios({
                    method: 'get',
                    url: '/calculations',
                    params: {
                        search: name,
                        limit: 10 * 1000
                    }
                }).catch((e) => e);

                if (status === 200) {
                    return results || [];
                } else {
                    const error = 'Ошибка получения списка расчетов';
                    toast.error(error);
                    return [];
                }
            }
        };
    }
});
