const NODE_ENV = process.env.NODE_ENV;

export const IS_DEV = NODE_ENV === 'development';
export const REAL_BACK_ADR = '';
export const DADATA_TOKEN = '978d4a34ef9ea583de253203cf47d28290e04080';
export const BACK_ADR = NODE_ENV === 'development' ? '' : REAL_BACK_ADR;
export const INACTIVITY_TIMEOUT = 1000 * 60 * 60 * 1000;
export const TARIFFS = Object.freeze([
    { name: 'Лоукостер' },
    { name: 'Эконом' },
    { name: 'Бизнес' },
    { name: 'Бизнес +' },
    { name: 'Первый класс' }
]);

export const SQ_M = 'м²';
export const AREAS_FIELDS = Object.freeze({
    totalArea: {
        label: 'Общая площадь',
        suffix: SQ_M
    },
    livingArea: {
        label: 'Общая жилая площадь',
        suffix: SQ_M
    },
    nonlivingArea: {
        label: 'Общая нежилая площадь',
        suffix: SQ_M
    },
    parkingArea: {
        label: 'Площадь машиномест',
        suffix: SQ_M
    },
    cleaningArea1: {
        label: 'Общая наземная площадь МОП',
        suffix: SQ_M
    },
    cleaningArea2: {
        label: 'Площадь МОП паркинга',
        suffix: SQ_M
    },
    cleaningArea3: {
        label: 'Площадь придомовой территории',
        suffix: SQ_M
    },
    overgroundArea: {
        label: 'Общая площадь наземной части',
        suffix: SQ_M
    },
    fullUndergroundArea: {
        label: 'Общая площадь подземной части',
        suffix: SQ_M
    },
    houses: {
        label: 'Количество домов'
    },
    floors: {
        label: 'Средняя этажность'
    },
    entrances: {
        label: 'Количество подъездов'
    },
    elevators: {
        label: 'Количество лифтов'
    },
    securityPosts: {
        label: 'Количество постов охраны'
    },
    livingRooms: {
        label: 'Количество жилых помещений'
    },
    nonlivingRooms: {
        label: 'Количество нежилых помещений'
    },
    parkings: {
        label: 'Количество машиномест'
    },
    accounts: {
        label: 'Всего лицевых счетов'
    }
});

export const CALC_VISIBILITIES = Object.freeze({
    calc1: Object.freeze({
        guest: true,
        resident: true,
        manager: true
    }),
    calc2: Object.freeze({
        guest: true,
        resident: false,
        manager: true
    }),
    calc3: Object.freeze({
        guest: false,
        resident: true,
        manager: true
    }),
    calc4: Object.freeze({
        guest: true,
        resident: true,
        manager: true
    })
});

export const CURRENCY_SIGN = '₽';
