import styled from '@emotion/styled';

export const StyledLayoutWrap = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export const StyledContentWrap = styled.div`
    flex: 1;
    padding-top: 64px;
`;
