import React, { useEffect } from 'react';
import { useActions, useValues } from 'kea';
import styled from '@emotion/styled';
import { useParams, useHistory } from 'react-router-dom';

import { Loader } from 'components/Loader';
import authLogic from 'store/auth';

const StyledWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ActivationUser = () => {
    const { uid, token } = useParams();
    const history = useHistory();
    const { activateUser } = useActions(authLogic);
    const { isLoading } = useValues(authLogic);
    useEffect(() => {
        activateUser({
            uid,
            token,
            onSuccess: () => {
                history.replace('/?activate=1', {
                    hintMessage: 'Активация прошла успешно. Осталось только войти.',
                    success: true,
                    activation: true
                });
            },
            onFailure: (reason) => {
                const { response: { data: { detail = 'Ошибка активации', token } = {} } = {} } = reason;
                history.replace('/?activate=1', { hintMessage: token || detail, error: true, activation: true });
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return <StyledWrap>{isLoading && <Loader color={'#000'} />}</StyledWrap>;
};

export { ActivationUser };
