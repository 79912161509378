import React, { useEffect, useRef, useState } from 'react';
import { useQueryParam, BooleanParam } from 'use-query-params';
import { useLocation } from 'react-router-dom';
import { useActions, useValues } from 'kea';
import { Helmet } from 'react-helmet-async';

import { H3 } from 'components/index';
import { GlobalModal } from 'components/modals/GlobalModal';
import { Field } from 'components/Field';

import { StyledHeader, StyledSubmit, StyledText, StyledHintText, StyledForm } from './units';

import authLogic from 'store/auth';

const MODAL_CLOSE_TIMEOUT = 300;

const ActivationModal = ({ ...rest }) => {
    const location = useLocation();
    const { resendActivationUser } = useActions(authLogic);
    const { isLoading } = useValues(authLogic);

    const activationForm = useRef();

    const [email, setEmail] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [isOpen, setIsOpen] = useQueryParam('activate', BooleanParam);

    const { state: { hintMessage, error, success } = {} } = location;

    useEffect(() => {
        if (!location.state || !location.state.activation) {
            setIsOpen(undefined, 'replaceIn');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);
    const onRequestClose = () => {
        setTimeout(() => {
            setIsSuccess(false);
        }, MODAL_CLOSE_TIMEOUT);
        setIsOpen(undefined, 'replaceIn');
        location.state = { ...location.state, activation: false };
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        resendActivationUser({ email, onSuccess: () => setIsSuccess(true) });
    };
    return (
        <GlobalModal closeTimeoutMS={MODAL_CLOSE_TIMEOUT} {...{ isOpen: isOpen || false, onRequestClose, ...rest }}>
            <Helmet>
                <title>CommunalCa | Активация пользователя</title>
            </Helmet>
            <StyledHeader>Активация</StyledHeader>
            <StyledText error={error}>{hintMessage}</StyledText>
            {error &&
                (!isSuccess ? (
                    <>
                        <StyledHintText>Давайте отправим подтверждение повторно на вашу почту</StyledHintText>
                        <StyledForm ref={activationForm} onSubmit={handleSubmit} action={''} method={'POST'}>
                            <Field
                                required
                                isRequired
                                variant={'filled'}
                                style={{ marginBottom: 32 }}
                                label={'E-mail'}
                                name={'email'}
                                value={email}
                                onChange={setEmail}
                            />
                            <StyledSubmit rounded size={'md'} loading={isLoading}>
                                Отправить повторно
                            </StyledSubmit>
                        </StyledForm>
                    </>
                ) : (
                    <H3>На вашу почту повторно отправили сообщение</H3>
                ))}
            {success && (
                <StyledSubmit rounded size={'md'} onClick={onRequestClose}>
                    Закрыть
                </StyledSubmit>
            )}
        </GlobalModal>
    );
};

export { ActivationModal };
