import styled from '@emotion/styled';
import { Field } from 'components/Field';
import colors from 'colors';

export const StyledWrap = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledAvatarWrap = styled.div`
    margin-right: 16px;
`;

export const StyledField = styled(Field)`
    max-width: 400px;
    margin-bottom: 32px;
`;

export const FieldRow = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0 15px;
    margin-top: 32px;
`;

export const FieldCol = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    flex-direction: column;
    //align-items: flex-end;
`;

export const StyledSpacer = styled.div`
    display: flex;
    width: 100%;
    margin-top: 64px;
`;

export const StyledFieldOuter = styled.div`
    flex: 1;
    max-width: 400px;
    display: flex;
    flex-direction: column;
`;

export const StyledHint = styled.button`
    display: inline-block;
    appearance: none;
    border: none;
    background: none;
    text-align: left;
    align-self: flex-start;
    cursor: pointer;
    margin: -24px 0 0;
    padding: 0;
    span {
        color: ${colors.main_400};
        text-decoration: underline;
    }
`;
