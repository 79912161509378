import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useActions, useValues } from 'kea';

import { GlobalModal } from 'components/modals/GlobalModal';
import { LoginForm } from 'components/LoginForm';
import { RegistrationForm } from 'components/RegistrationForm';
import { InfoCalcPanel } from 'components/InfoCalcPanel';

import authLogic from 'store/auth';
import userLogic from 'store/user';

import guestImg from 'assets/img/guest.png';
import residentImg from 'assets/img/resident.png';
import managerImg from 'assets/img/manager.png';

import { StyledPanel, StyledLeftPanel, StyledRightPanel, StyledVerticalDivider } from './units';

export const LoginRegModal = ({
    isReg: _isReg,
    isNeedAuth: _isNeedAuth,
    extraInfo: _extraInfo,
    isOpen,
    isSuccessLabel,
    onRequestClose,
    ...rest
}) => {
    const { isAuth } = useValues(authLogic);
    const { clearError } = useActions(authLogic);
    const { userData: { role = '', is_superuser: isSuperuser = false } = {} } = useValues(userLogic);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const isNeedAuth = useMemo(() => _isNeedAuth || false, [isOpen]);

    const [isReg, setIsReg] = useState(_isReg || false);
    const [extraInfo, setExtraInfo] = useState(_extraInfo || 's');
    const setIsReqState = useCallback((value) => setIsReg(value || _isReg), [_isReg]);
    const setExtraInfoState = useCallback((value) => setExtraInfo(value || _extraInfo), [_extraInfo]);
    useEffect(() => {
        if (isOpen) {
            clearError();
            setIsReqState();
            setExtraInfoState();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    return (
        <GlobalModal closeTimeoutMS={300} {...{ isOpen, onRequestClose, ...rest }}>
            <StyledPanel>
                {isNeedAuth && (
                    <>
                        <StyledLeftPanel>
                            {isReg ? (
                                <RegistrationForm
                                    onSuccess={() => {
                                        onRequestClose && onRequestClose(false);
                                    }}
                                    onLoginRequest={() => {
                                        clearError();
                                        setIsReg(false);
                                    }}
                                />
                            ) : (
                                <LoginForm
                                    isSingleForm={!extraInfo}
                                    isSuccessLabel={isSuccessLabel}
                                    onClose={() => {
                                        onRequestClose && onRequestClose(false);
                                    }}
                                    onSuccess={() => {
                                        !isSuccessLabel && onRequestClose && onRequestClose(false);
                                    }}
                                    onRegistrationRequest={() => {
                                        clearError();
                                        setIsReg(true);
                                    }}
                                />
                            )}
                        </StyledLeftPanel>
                        {extraInfo && isAuth && <StyledVerticalDivider />}
                    </>
                )}
                {extraInfo && (
                    <StyledRightPanel>
                        {extraInfo === 'guest' && (
                            <InfoCalcPanel
                                disabled={!isAuth}
                                error={!isAuth}
                                header={'ГОСТЬ'}
                                image={({ style }) => <img src={guestImg} style={style} alt="Гость" />}
                                text={
                                    <span>
                                        Лицензия «Гость» доступна любому пользователю. Позволяет осуществить расчет
                                        ставки управления и технического обслуживания в многоквартирных домах в удельных
                                        (руб/м<sup>2</sup>; мес; год) и абсолютных величинах, в пересчете на
                                        определенное помещение в многоквартирном доме.
                                        <br />
                                        <br />
                                        Расчет носит нормативный характер.
                                    </span>
                                }
                            />
                        )}
                        {extraInfo === 'resident' && (
                            <InfoCalcPanel
                                disabled={!(isAuth && (isSuperuser || role === 'resident'))}
                                error={!isAuth}
                                header={'РЕЗИДЕНТ'}
                                image={({ style }) => <img src={residentImg} style={style} alt="Резидент" />}
                                text={
                                    <span>
                                        Лицензия «Резидент» доступна резидентам, владеющим имуществом в многоквартирных
                                        домах, информация об управлении которых внесена в CommunalCa.
                                        <br />
                                        <br />
                                        Позволяет осуществить расчет нормативной ставки за управление и техническую
                                        эксплуатацию многоквартирного дома. Сравнить нормативную и фактическую ставки
                                        обслуживания.
                                    </span>
                                }
                            />
                        )}
                        {extraInfo === 'manager' && (
                            <InfoCalcPanel
                                disabled={!(isAuth && (isSuperuser || role === 'manager'))}
                                error={!isAuth}
                                header={'МЕНЕДЖЕР'}
                                image={({ style }) => <img src={managerImg} style={style} alt="Менеджер" />}
                                text={
                                    <span>
                                        Лицензия «Менеджер» позволяет осуществить расчет нормативной ставки за
                                        управление и техническую эксплуатацию многоквартирного дома. Сравнить
                                        нормативную и фактическую ставки обслуживания.
                                        <br />
                                        <br />
                                        Лицензия «Менеджер» доступна сотрудникам Управляющей организации, с
                                        соответствующими корпоративными документами, позволяющими осуществить
                                        исследование ставки по управлению и техническому обслуживанию многоквартирного
                                        дома. Пользователю доступен расчетный пакет отчетных документов. Расчет
                                        нормативных ставок осуществляется для вышеупомянутых целей и является
                                        коммерческой тайной.
                                    </span>
                                }
                            />
                        )}
                    </StyledRightPanel>
                )}
            </StyledPanel>
        </GlobalModal>
    );
};
