import styled from '@emotion/styled';

import colors from 'colors';

export const StyledPanel = styled.div`
    display: flex;
    flex-wrap: nowrap;
    margin: -100px;
    overflow: hidden;
`;
export const StyledLeftPanel = styled.div`
    padding: 100px 80px 100px 100px;
    display: flex;
    align-items: center;
`;
export const StyledRightPanel = styled.div`
    position: relative;
    padding: 100px 100px 100px 80px;

    display: flex;
    align-items: center;
`;

export const StyledVerticalDivider = styled.div`
    margin: 200px 0;
    border-left: 1px solid ${colors.grayScale_300};
`;
