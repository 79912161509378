import styled from '@emotion/styled';
import { colors } from 'colors';

export const StyledWrap = styled.div`
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    transform: scale(0.5);
`;

export const StyledPart = styled.div`
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;

    &:after {
        content: ' ';
        display: block;
        position: absolute;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: ${({ color }) => colors[color] || color};
        margin: -4px 0 0 -4px;
    }

    &:nth-of-type(1) {
        animation-delay: -0.036s;
    }
    &:nth-of-type(1):after {
        top: 63px;
        left: 63px;
    }
    &:nth-of-type(2) {
        animation-delay: -0.072s;
    }

    &:nth-of-type(2):after {
        top: 68px;
        left: 56px;
    }
    &:nth-of-type(3) {
        animation-delay: -0.108s;
    }
    &:nth-of-type(3):after {
        top: 71px;
        left: 48px;
    }
    &:nth-of-type(4) {
        animation-delay: -0.144s;
    }
    &:nth-of-type(4):after {
        top: 72px;
        left: 40px;
    }
    :nth-of-type(5) {
        animation-delay: -0.18s;
    }
    &:nth-of-type(5):after {
        top: 71px;
        left: 32px;
    }
    &:nth-of-type(6) {
        animation-delay: -0.216s;
    }
    &:nth-of-type(6):after {
        top: 68px;
        left: 24px;
    }
    &:nth-of-type(7) {
        animation-delay: -0.252s;
    }
    &:nth-of-type(7):after {
        top: 63px;
        left: 17px;
    }
    &:nth-of-type(8) {
        animation-delay: -0.288s;
    }
    &:nth-of-type(8):after {
        top: 56px;
        left: 12px;
    }
    @keyframes lds-roller {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;
