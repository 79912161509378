import React from 'react';
import { useActions } from 'kea';

import { TestComponent } from 'components';
import { LoginForm } from 'components/LoginForm';
import { Button } from 'components/Button';

import authLogic from 'store/auth';

export const MainPage = () => {
    const { logOut } = useActions(authLogic);

    return (
        <div>
            <TestComponent />
            <LoginForm />
            <div style={{ display: 'flex', justifyContent: 'space-around', width: '100vw', padding: 32 }}>
                <Button>test primary</Button>
                <Button outlined>test outlined</Button>
                <Button rounded size={'sm'}>
                    test small and rounded
                </Button>
                <Button size={'lg'}>test big</Button>
            </div>
            <button onClick={logOut}>logOut</button>
        </div>
    );
};

export default MainPage;
