import React from 'react';
import styled from '@emotion/styled';

import { colors } from 'colors';

import { hexToRGBA } from 'utils';
import { Link, useLocation } from 'react-router-dom';

const LogoWrap = styled(Link)`
    text-decoration: none;
    color: ${colors.grayScale_900};
    display: flex;
    align-items: center;
    cursor: pointer;
    border-top: 4px solid ${hexToRGBA(colors.grayScale_900, 0.5)};
`;

const LogoText = styled.div`
    margin-top: 32px;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
`;

export const Logo = ({ onClick }) => {
    const location = useLocation();

    return (
        <LogoWrap {...{ to: '/', onClick, replace: location.pathname === '/' }}>
            <LogoText>CommunalCa</LogoText>
        </LogoWrap>
    );
};
