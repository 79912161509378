import React from 'react';

import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';

import { defaultStyles, defaultColors } from './utils';

export const AutoComplete = ({
    selectRef,
    isCreatable,
    getSuggestions,
    height,
    styles,
    variant = 'normal',
    ...rest
}) => {
    const Component = isCreatable ? AsyncCreatableSelect : AsyncSelect;
    return (
        <Component
            placeholder={'Выбрать...'}
            loadingMessage={() => 'Загрузка...'}
            noOptionsMessage={() => 'Не найдено'}
            cacheOptions
            defaultOptions
            loadOptions={getSuggestions}
            menuPortalTarget={document.body}
            isClearable
            styles={{
                ...defaultStyles,
                ...styles
            }}
            theme={(theme) => {
                return {
                    ...theme,
                    borderRadius: 4,
                    fontSize: 16,
                    spacing: {
                        ...theme.spacing,
                        controlHeight: 60
                    },
                    colors: {
                        ...theme.colors,
                        ...defaultColors
                    }
                };
            }}
            ref={selectRef}
            variant={variant}
            {...rest}
        />
    );
};
