import { kea } from 'kea';
import { toast } from 'react-toastify';

import axios from 'utils/axiosWrapper';

export default kea({
    path: () => ['scenes', 'dicts', 'data'],

    loaders: () => ({
        lifeCycles: [
            [],
            {
                fetchLifecycles: async () => {
                    const { status, data: result = [] } = await axios({
                        method: 'get',
                        url: '/dict/life_cycles'
                    }).catch((e) => e);

                    if (status === 200) {
                        return result || [];
                    } else {
                        const error = 'Ошибка получения словаря';
                        toast.error(error);
                        return [];
                    }
                }
            }
        ],
        rateClasses: [
            [],
            {
                fetchRateClasses: async () => {
                    const { status, data: result = [] } = await axios({
                        method: 'get',
                        url: '/dict/rate_classes'
                    }).catch((e) => e);

                    if (status === 200) {
                        return result || [];
                    } else {
                        const error = 'Ошибка получения словаря';
                        toast.error(error);
                        return [];
                    }
                }
            }
        ],
        services: [
            [],
            {
                fetchServices: async () => {
                    const { status, data: { services = [] } = {} } = await axios({
                        method: 'get',
                        url: '/services'
                    }).catch((e) => e);

                    if (status === 200) {
                        return services.map(({ id, label, editable }) => ({
                            id,
                            isEditable: editable ?? true,
                            heading: label,
                            milestones: [5000, 10000, 15000, 50000, 100000]
                        }));
                    } else {
                        const error = 'Ошибка получения списка услуг';
                        toast.error(error);
                    }
                }
            }
        ],
        housingComplexes: [
            [],
            {
                fetchHousingComplexes: async (value) => {
                    const { status, data = [] } = await axios({
                        method: 'get',
                        url: '/dict/housing_complexes',
                        params: { search: value, count: 1000 }
                    }).catch((e) => e);

                    if (status === 200) {
                        return data;
                    } else {
                        const error = 'Ошибка получения списка услуг';
                        toast.error(error);
                    }
                }
            }
        ]
    }),
    thunks: ({ actions, values }) => {
        return {
            callDicts: () => {
                actions.fetchLifecycles();
                actions.fetchRateClasses();
                actions.fetchServices();
                actions.fetchHousingComplexes();
            },
            getHousingComplexes: async (search) => {
                return Promise.resolve(
                    values.housingComplexes.filter(
                        (house) => house.name.toLowerCase().indexOf(search.toLowerCase()) >= 0
                    )
                );
            }
        };
    }
});
