import React, { useCallback, useEffect, useMemo } from 'react';
import { useActions, useValues } from 'kea';
import { toast } from 'react-toastify';

import { doBlurOnEnter, isNil } from 'utils';

import { H3, PaperBlock } from 'components';
import { ButtonNext } from 'components/ButtonNext';
import { MemoizedBriefData } from 'components/BriefData';

import { MemoizedCalcHeadings } from 'components/CalcHeadings';
import { CalcTiles } from 'components/CalcTiles';
import { Configurator } from 'components/Configurator';
import { ModalLoader } from 'components/ModalLoader';

import { StyledHarmonizationGrid, StyledHorizontalField, StyledNotice } from './units';

import { useAutoHarmValues, useConfiguratorValues } from './utils';

import calcLogic from 'store/calculation';
import dictsLogic from 'store/dicts';
import authLogic from 'store/auth';
import userLogic from 'store/user';
import { CALC_VISIBILITIES } from 'config';

export const Calc3 = ({ history }) => {
    const { isAuth } = useValues(authLogic);
    const { userData: { role, is_superuser: isSuperuser } = {} } = useValues(userLogic);
    const { rateClassIndex, harmonization, isLoading, expertData, normativeData, calc1Done, calc2Done } = useValues(
        calcLogic
    );
    const { rateClasses } = useValues(dictsLogic);
    const { setHarmonization, setCalc3Viewed, fetchExpertHarmonization } = useActions(calcLogic);

    const headings = useMemo(() => rateClasses.map(({ name }) => name), [rateClasses]);

    useEffect(() => {
        if (isAuth) {
            const roleVisibilities = Object.entries(CALC_VISIBILITIES).reduce((result, [entry, value]) => {
                result[entry] = isSuperuser || value[role];
                return result;
            }, {});
            if (!calc1Done && roleVisibilities['calc1']) {
                toast.error('Вы не завершили первый шаг, поэтому мы вернули вас на него');
                history.push('/calc1');
            } else if (!calc2Done && roleVisibilities['calc2']) {
                toast.error('Вы не завершили второй шаг, поэтому мы вернули вас на него');
                history.push('/calc2');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuth]);

    useEffect(() => {
        if (harmonization === null && !normativeData) fetchExpertHarmonization();
    }, [harmonization, normativeData, fetchExpertHarmonization]);

    const handleSubmit = useCallback(
        async (e) => {
            e.preventDefault();
            await setCalc3Viewed(true);
            history.push('/buttons');
        },
        [setCalc3Viewed, history]
    );

    const [autoHarmRate, autoHarmOnChange, autoHarmOnFocus, autoHarmOnBlur] = useAutoHarmValues();

    const [pricesForConfigurator, rowsForConfigurator, pricesOnChange, runExpertHarm] = useConfiguratorValues();

    return (
        <div>
            <MemoizedCalcHeadings />
            <PaperBlock>
                <MemoizedBriefData fetchExtra hideHarmonization isLoading={isLoading} />
            </PaperBlock>
            <PaperBlock>
                <H3>Таблица услуг относительно нормативной ставки</H3>
                <CalcTiles />
                <StyledNotice>
                    По итогам расчета резидент не доплачивает до уровня нормативной ставки исходя из понижения класса
                    ряда услуг
                </StyledNotice>
                <StyledHarmonizationGrid>
                    <StyledHorizontalField
                        min={0}
                        isBlocked={isLoading}
                        isLoading={isLoading && harmonization === 'auto'}
                        isActive={harmonization === 'auto'}
                        type="number"
                        label="Автоматическая гармонизированная ставка"
                        hint="Введите желаемую ставку для автоматической корректировки класса оказываемых услуг"
                        hintMaxWidth={300}
                        id="autoHarmonizationField"
                        value={autoHarmRate}
                        onChange={autoHarmOnChange}
                        onKeyPress={doBlurOnEnter}
                        onFocus={autoHarmOnFocus}
                        onBlur={autoHarmOnBlur}
                    />
                    <StyledHorizontalField
                        disabled
                        isBlocked={isLoading}
                        isLoading={isLoading && harmonization === 'expert'}
                        isActive={harmonization === 'expert'}
                        type="number"
                        label="Экспертная гармонизированная ставка"
                        hint="Управляйте бегунками с классами услуг вручную, изменение ставки будет отражено в данном окне"
                        hintMaxWidth={350}
                        id="expertHarmonizationField"
                        onLabelClick={() => {
                            setHarmonization('expert');
                            if (!expertData) runExpertHarm();
                        }}
                        value={expertData?.rate || null}
                    />
                </StyledHarmonizationGrid>
                {pricesForConfigurator && (
                    <Configurator
                        magnetMilestones
                        disableInputs
                        isBlocked={isLoading}
                        prices={pricesForConfigurator}
                        headings={headings}
                        rows={rowsForConfigurator}
                        linePosition={rateClassIndex}
                        onDragEnd={runExpertHarm}
                        onChange={pricesOnChange}
                    />
                )}
                <ButtonNext onClick={handleSubmit} />
            </PaperBlock>
            {isLoading && isNil(normativeData) && <ModalLoader />}
        </div>
    );
};

export default Calc3;
