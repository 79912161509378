import styled from '@emotion/styled';
import { colors } from 'colors';

import { ReactComponent as ArrowIcon } from 'assets/icons/sharpArrow.svg';

import { CalcCard } from './CalcCard';

export const StyledSlideWrap = styled.div`
    display: flex;
    position: relative;
    height: calc(100vh - 64px);
    z-index: 3;
    max-height: 800px;
`;

export const StyledSlideOne = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: ${({ isActive }) => (isActive ? 0 : '100%')};
    width: 100%;
    transition: right 500ms ease-in-out;
    overflow-y: visible;
`;

export const StyledBackgroundShape = styled.div`
    position: absolute;
    z-index: 1;
    background: ${colors.grayScale_50};
    width: 100%;
    height: 100%;
    transition: transform 500ms ease-in-out;
    transform: ${({ isActive }) => (isActive ? 'rotate(340deg) translate(20%, 85%)' : 'translate(-120%, 85%)')};
`;

export const StyledFirstTextWrap = styled.div`
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 160px;
    padding: 0 100px;
`;

export const StyledFirstHeadWrap = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 80px;
`;

export const StyledFirstHead = styled.div`
    font-weight: bold;
    font-size: 72px;
    line-height: 88px;
    color: ${colors.grayScale_700};
`;

export const StyledFirstCaption = styled.div`
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.015em;
    color: ${colors.grayScale_600};
`;

export const StyledFirstMainText = styled.div`
    font-weight: normal;
    font-size: 25px;
    line-height: 30px;
    max-width: 767px;
`;

export const StyledBackgroundWrap = styled.div`
    background: ${colors.grayScale_0};
    position: absolute;
    overflow: hidden;
    z-index: 1;
    width: 100vw;
    height: calc(100vh - 64px);
    max-height: 800px;
`;

export const StyledSlideTwo = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    bottom: 0;
    left: ${({ isActive }) => (isActive ? 0 : '100%')};
    width: 100%;
    transition: left 500ms ease-in-out;
`;

export const StyledCardsWrap = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    bottom: -64px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledCardsInner = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 100px;
    max-width: 1500px;
`;

export const StyledArrowWrap = styled.button`
    z-index: 3;
    text-decoration: none;
    background: transparent;
    border: none;
    position: absolute;
    top: 65%;
    left: 0;
`;

export const StyledArrowRightWrap = styled(StyledArrowWrap)`
    left: auto;
    right: 0;
`;

export const StyledCardImg = styled.img`
    width: 330px;
    @media (max-height: 680px) {
        width: 240px;
    }
`;

export const StyledLeftArrowIcon = styled(ArrowIcon)`
    cursor: pointer;
`;

export const StyledRightArrowIcon = styled(StyledLeftArrowIcon)`
    transform: rotate(180deg);
`;

export const StyledMainImg = styled.img`
    position: absolute;
    bottom: 4%;
    right: 5%;
    @media (max-width: 1440px) {
        left: 50%;
        bottom: 0;
    }
    @media (max-height: 680px) {
        left: 55%;
        bottom: 0;
        width: 40%;
    }
`;

export { CalcCard };
