import axios from 'axios';

import { BACK_ADR } from 'config';

import authLogic from 'store/auth';

const instanceOfAxios = axios.create({
    baseURL: BACK_ADR + '/api/v1',
    method: 'post',
    withCredentials: true,
    headers: {
        'Content-type': 'application/json; charset=utf-8'
    }
});

instanceOfAxios.interceptors.request.use(
    async (config) => {
        const refreshTokenPromise = authLogic.actions.getRefreshTokenPromise();
        if (config.url !== '/auth/jwt/refresh/') await refreshTokenPromise;

        const { token } = authLogic.values;
        if (token) config.headers.Authorization = `Bearer ${token}`;
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

instanceOfAxios.interceptors.response.use(
    function (response) {
        if (response.status === 200 && !Array.isArray(response.data) && response.data.access && response.data.refresh) {
            authLogic.actions.setToken(response.data.access);
            authLogic.actions.setRefresh(response.data.refresh);
        }
        return response;
    },
    function (error) {
        if (axios.isCancel(error)) {
            return Promise.reject(error);
        }
        error.errorText = 'Ошибка сети';

        if (error.code === 'ECONNRESET' || !error.response)
            return Promise.reject({
                errorText: 'Запрошенный функционал или сервер в данный момент недоступен'
            });
        // add 502 handle, because nginx send it on lost connect with aggrigate
        if (error.response.status === 504 || error.response.status === 502) {
            error.errorText = 'Запрошенный функционал или сервер в данный момент недоступен';
        }
        if (error.response.status === 404) {
            error.errorText = 'Не найдено';
        }
        if (error.response.status === 401) {
            error.errorText = 'Неавторизованный запрос';
            try {
                authLogic.actions.logOut();
            } catch (e) {
                console.error(e);
            }
        }
        // Do something with request error
        return Promise.reject(error);
    }
);

export default instanceOfAxios;
