import styled from '@emotion/styled';
import colors from 'colors';

export const StyledInput = styled.input`
    width: 0;
    height: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute;
`;

export const StyledLabel = styled.label`
    display: inline-flex;
    align-items: center;
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    font-size: 16px;
    overflow: hidden;
`;

export const StyledLabelText = styled.span`
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
    user-select: none;
    ${StyledInput}:disabled ~ & {
        color: ${colors.grayScale_300};
    }
`;

const StyledSquare = styled.div`
    display: inline-block;
    vertical-align: middle;
    width: 25px;
    height: 25px;
    position: relative;
    color: ${colors.grayScale_400};
    border: 1px solid ${colors.grayScale_300};
    border-radius: 2px;
    transition-property: border, color;
    transition-duration: 0.3s;
    transition-timing-function: ease;
    ${StyledLabel}:hover > ${StyledInput}:not(:disabled) + &
    ${({ nestingLabel }) => nestingLabel && `,label:hover ${StyledInput}:not(:disabled) + &`} {
        border-color: ${colors.grayScale_700};
    }
    ${StyledLabel} > ${StyledInput}:focus + & {
        border-color: ${colors.main_400};
        color: ${colors.main_400};
    }
    & > * {
        display: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    ${StyledInput}:checked ~ & > * {
        display: block;
    }
    ${StyledInput}:disabled ~ & {
        background-color: ${colors.grayScale_50};
    }
`;
export const Square = (props) => (
    <StyledSquare {...props}>
        <svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.91308 4.03516H1.91326C1.99256 4.03525 2.07081 4.05331 2.14213 4.08798C2.21341 4.12262 2.27591 4.17295 2.32497 4.2352L1.91308 4.03516ZM1.91308 4.03516H1.09394C0.890308 4.03516 0.776602 4.26975 0.902321 4.4292L0.902371 4.42927L4.11214 8.49567L4.11218 8.49573C4.32247 8.76188 4.72568 8.76114 4.93667 8.49617L4.93708 8.49566L10.6597 1.24398C10.6598 1.24378 10.66 1.24358 10.6602 1.24338C10.7884 1.08295 10.6699 0.85 10.4689 0.85H9.6498C9.48941 0.85 9.33668 0.9235 9.2376 1.05044C9.23751 1.05055 9.23742 1.05066 9.23734 1.05077L4.52403 7.02157M1.91308 4.03516L4.52403 7.02157M4.52403 7.02157L2.32506 4.23531L4.52403 7.02157Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="0.3"
            />
        </svg>
    </StyledSquare>
);
