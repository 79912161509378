import React from 'react';
import { H2, H3, PaperBlock } from 'components/index';
import { StyledWrap, StyledLink, StyledContactsWrap, StyledContactsInner, StyledContactsRow } from './units';

const Contacts = () => {
    return (
        <StyledWrap>
            <H2 as={'h1'}>Наши контакты</H2>
            <PaperBlock id="main-settings" style={{ paddingTop: 60 }}>
                <StyledContactsWrap>
                    <H3 as={'h2'}>Мы всегда доступны по следующим контактам</H3>
                    <StyledContactsInner>
                        <StyledContactsRow>
                            <span>Основной телефон</span>
                            <StyledLink href="tel:+74956883818">+7(495) 688-38-18</StyledLink>
                        </StyledContactsRow>
                        <StyledContactsRow>
                            <span>E-mail</span>
                            <StyledLink href="mailto:info@lab-cifra.ru">info@lab-cifra.ru</StyledLink>
                        </StyledContactsRow>
                    </StyledContactsInner>
                </StyledContactsWrap>
            </PaperBlock>
        </StyledWrap>
    );
};

export { Contacts };
