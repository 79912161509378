import React from 'react';
import { StyledLabel, StyledLabelText, StyledInput, Square } from './units';

export const Checkbox = ({
    className,
    style,
    children,
    disabled = false,
    checked = false,
    nestingLabel = false,
    callbackId,
    onChange
}) => {
    const handleChange = (event) => onChange?.(event.target.checked, { event, callbackId });

    return (
        <StyledLabel {...{ className, style, disabled }}>
            <StyledInput type="checkbox" onChange={handleChange} {...{ checked, disabled }} />
            <Square {...{ nestingLabel }} />
            {children && <StyledLabelText>{children}</StyledLabelText>}
        </StyledLabel>
    );
};
