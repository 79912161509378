import styled from '@emotion/styled';

import { Button, Loader } from 'components';

export const StyledFieldsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 25px;
`;

export const StyledButton = styled(Button)`
    display: block;
    width: calc(33% - 12px);
    margin: 60px 0 0 auto;
`;

export const StyledLoader = styled(Loader)`
    display: block;
    margin: 0 auto;
`;
