import React, { useState } from 'react';
import { useQueryParam, BooleanParam } from 'use-query-params';

import { H3 } from 'components/index';
import { GlobalModal } from 'components/modals/GlobalModal';

import { ForgotPasswordForm } from './Form/ForgotPasswordForm';

const MODAL_CLOSE_TIMEOUT = 300;

const ForgotPasswordModal = ({ ...rest }) => {
    const [isSuccess, setIsSuccess] = useState(false);

    const [isOpen, setIsOpen] = useQueryParam('forgot', BooleanParam);

    const onRequestClose = () => {
        setTimeout(() => {
            setIsSuccess(false);
        }, MODAL_CLOSE_TIMEOUT);
        setIsOpen(undefined, 'replaceIn');
    };

    return (
        <GlobalModal closeTimeoutMS={MODAL_CLOSE_TIMEOUT} {...{ isOpen: isOpen || false, onRequestClose, ...rest }}>
            {isSuccess ? (
                <H3 style={{ maxWidth: 550 }}>Вам на почту отправлено сообщение со ссылкой для сброса пароля</H3>
            ) : (
                <ForgotPasswordForm onSuccess={() => setIsSuccess(true)} />
            )}
        </GlobalModal>
    );
};

export { ForgotPasswordModal };
