import { resetContext, getContext } from 'kea';
import thunkPlugin from 'kea-thunk';
import localStoragePlugin from 'kea-localstorage';
import { loadersPlugin } from 'kea-loaders';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import reduxReset from 'redux-reset';
import { name } from '../../package.json';

const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;

const history = createBrowserHistory({
    basename: PUBLIC_URL ? PUBLIC_URL : undefined
});

const storeOptions = {
    plugins: [
        thunkPlugin,
        localStoragePlugin({
            prefix: name
        }),
        loadersPlugin({})
    ],
    createStore: {
        middleware: [routerMiddleware(history)],
        reducers: {
            router: connectRouter(history)
        },
        enhancers: [reduxReset()]
    }
};

resetContext(storeOptions);

export default getContext().store;

export { history, storeOptions };
