import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { HorizontalField } from 'components/HorizontalField';
import colors from 'colors';
import { Loader } from 'components/Loader';

export const StyledHarmonizationGrid = styled.div`
    margin: 40px 0 40px 537px;
    display: flex;
    justify-content: space-between;
`;

const wrapperStyles = css`
    min-width: 90px;
`;
const horizontalLabelStyles = css`
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    user-select: none;
    transition: 0.3s ease;
    transition-property: background-color, color, box-shadow;
    &:hover {
        background-color: ${colors.main_200};
        color: ${colors.grayScale_0};
    }
`;
const activeHorizontalLabelStyles = css`
    ${horizontalLabelStyles};
    box-shadow: 2px 2px 5px ${colors.grayScale_300};
    background-color: ${colors.main_300};
    color: ${colors.grayScale_0};
    cursor: initial;
    &:hover {
        background-color: ${colors.main_300};
        color: ${colors.grayScale_0};
    }
`;
const inputStyles = css`
    font-weight: 500;
`;
const HorizontalFieldWithProps = ({ isActive, ...props }) => (
    <HorizontalField
        horizontalLabelStyles={isActive ? activeHorizontalLabelStyles : horizontalLabelStyles}
        {...{ wrapperStyles, inputStyles, ...props }}
    />
);
export const StyledHorizontalField = styled(HorizontalFieldWithProps)`
    width: calc(50% - 12px);
`;

export const StyledNotice = styled.p`
    margin: 50px 0;
`;

export const StyledLoader = styled(Loader)`
    position: absolute;
    top: -50%;
    left: -50%;
    transform: translate(-50%, -50%) scale(0.4);
`;
