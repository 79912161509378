import { useMemo } from 'react';
import { useValues } from 'kea';

import { Tiles } from '../Tiles';
import calcLogic from 'store/calculation';

export const CalcTiles = () => {
    const { address, currentLifeCycle, currentRateClass } = useValues(calcLogic);

    const items = useMemo(
        () => [
            { title: address || 'Адрес введен', to: '/calc1#address' },
            { title: currentLifeCycle?.name, to: '/calc1#rate-n-class' },
            { title: currentRateClass?.name, to: '/calc1#rate-n-class' }
        ],
        [address, currentLifeCycle, currentRateClass]
    );

    return <Tiles items={items} />;
};
