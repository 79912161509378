import React, { useState, useReducer, useRef, useMemo } from 'react';
import { useActions, useValues } from 'kea';
import { toast } from 'react-toastify';

import authLogic from 'store/auth';

import {
    StyledForm,
    StyledHeader,
    StyledHint,
    StyledLink,
    StyledSubmit,
    StyledField,
    StyledCheckbox,
    StyledLabelWrapper,
    StyledLabelText
} from './units';

import { SQ_M } from '../../config';

const fieldsConfig = [
    { type: 'text', name: 'last_name', label: 'Фамилия', required: true },
    { type: 'text', name: 'first_name', label: 'Имя', required: true },
    { type: 'text', name: 'patronymic', label: 'Отчество' },
    { type: 'email', name: 'email', label: 'E-mail', required: true },
    { type: 'tel', name: 'phone_number', label: 'Телефон', required: true },
    { type: 'number', name: 'area', label: 'Площадь квартиры', required: true, suffix: SQ_M },
    { type: 'text', name: 'manager_key', label: 'Ключ' },
    // { type: 'text', name: 'account', label: 'Лицевой счет' },
    { type: 'password', name: 'password', label: 'Пароль', required: true }
];

const RegistrationForm = ({ onSuccess, onLoginRequest }) => {
    const [isChecked, setChecked] = useState(false);
    const loginForm = useRef();

    const [params, setParam] = useReducer(
        (state, { name, value } = {}) => (name ? { ...state, [name]: value } : state),
        {}
    );

    const { register, setValidationError } = useActions(authLogic);
    const { validationError, isLoading } = useValues(authLogic);
    const handleSubmit = async (e) => {
        e.preventDefault();
        await register({
            ...params,
            onRegFailure: () => {
                toast.error('Ошибка регистрации.');
                setValidationError({});
            },
            onSuccess: () => {
                toast.success(
                    'Заполненная форма направлена на верификацию. Ожидайте сообщения о подтверждении Вашей заявки.'
                );
                setValidationError({});
                onSuccess && onSuccess();
            }
        });
    };

    const renderField = (args) => {
        const { name } = args;
        return (
            <StyledField
                variant="filled"
                key={name}
                value={params[name] || null}
                isError={!!validationError[name]}
                hint={validationError[name] && validationError[name].join(' ')}
                onChange={(value) => setParam({ value, name })}
                {...args}
            />
        );
    };

    const isEverythingRequiredFilled = useMemo(
        () => isChecked && fieldsConfig.every(({ name, required }) => params[name] || !required),
        [isChecked, params]
    );

    return (
        <StyledForm ref={loginForm} onSubmit={handleSubmit}>
            <StyledHeader>Регистрация</StyledHeader>
            <StyledHint>
                Уже зарегистрированы? <StyledLink onClick={() => onLoginRequest && onLoginRequest()}>Войти</StyledLink>
            </StyledHint>
            {fieldsConfig.map(renderField)}
            <StyledLabelWrapper isCheckbox>
                <StyledCheckbox checked={isChecked} onChange={setChecked} />
                <StyledLabelText isCheckbox>
                    Я согласен(-а) с пользовательским соглашением и политикой конфиденциальности
                </StyledLabelText>
            </StyledLabelWrapper>
            <StyledSubmit disabled={!isEverythingRequiredFilled} rounded size={'md'} loading={isLoading}>
                Регистрация
            </StyledSubmit>
        </StyledForm>
    );
};

export { RegistrationForm };
