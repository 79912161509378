import React, { useEffect, useState } from 'react';
import debounce from 'debounce-async';
import { toast } from 'react-toastify';
import axiosDadata from 'utils/axiosDadataWrapper';
import colors from 'colors';

export const getPostalCode = (unrestrictedValue) => {
    const [postCode] = unrestrictedValue.split(',');
    return postCode;
};

export const getComplexAddresses = (addresses = [], complexName) => {
    return addresses.map((address) => ({
        unrestricted_value: address.name,
        housingComplexValue: `${address.name} (ЖК ${complexName})`,
        data: {
            postal_code: getPostalCode(address.name),
            housingComplex: complexName || true
        }
    }));
};

export const getAddresses = ({ additionalAddresses = [], errorText = 'Неудалось найти адреса' } = {}) => async (
    value
) => {
    const { data: { suggestions = [] } = {} } =
        (await axiosDadata({
            url: '/suggest/address',
            data: { query: value, count: additionalAddresses.length ? 3 : 10 }
        }).catch((e) => {
            toast.error(errorText);
            console.log(e);
        })) || {};
    return [
        ...additionalAddresses,
        ...suggestions.filter(
            (address) =>
                !additionalAddresses.find(
                    (additionalAddress) => additionalAddress.unrestricted_value === address.unrestricted_value
                )
        )
    ];
};

export const getAddressesById = debounce(async (value) => {
    const { data: { suggestions = [] } = {} } =
        (await axiosDadata({ url: '/findById/address', data: { query: value, count: 10 } }).catch((e) => {
            toast.error('Неудалось найти адрес по фиас id');
            console.log(e);
        })) || {};
    return suggestions;
}, 300);

export const useAddresses = ({ additionalAddresses = [], defaultValue: _defaultValue, defaultValueComplex } = {}) => {
    const [value, setValue] = useState();
    const [options, setOptions] = useState([]);
    const [isLoadingOptions, setIsLoadingOptions] = useState(false);
    const [visible, setVisible] = useState();
    const [defaultValue, setDefaultValue] = useState();
    const [loadingDefault, setLoadingDefault] = useState(true);
    const [menuIsOpen, setMenuIsOpen] = useState(false);

    useEffect(() => {
        (async () => {
            setIsLoadingOptions(true);
            setOptions(await getAddresses({ additionalAddresses })(value));
            setIsLoadingOptions(false);
        })();
    }, [additionalAddresses, value, visible]);

    useEffect(() => {
        if (_defaultValue) {
            if (defaultValueComplex) {
                setDefaultValue({
                    unrestricted_value: _defaultValue,
                    housingComplexValue: `${_defaultValue} (ЖК ${defaultValueComplex})`,
                    data: {
                        postal_code: getPostalCode(_defaultValue),
                        housingComplex: defaultValueComplex || true
                    }
                });
                setValue(_defaultValue);
                setLoadingDefault(false);
            } else {
                let active = true;
                (async () => {
                    const [address] = await getAddresses({ errorText: 'Неудалось найти текущий адресс' })(
                        _defaultValue
                    );
                    if (!active) {
                        setLoadingDefault(false);
                        return;
                    }

                    setValue(address?.unrestricted_value);
                    setDefaultValue(address);
                    setLoadingDefault(false);
                })();
                return () => {
                    active = false;
                };
            }
        } else {
            setLoadingDefault(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onAddressChange = (value) => {
        setValue(value?.unrestricted_value);
        setVisible(false);
        setMenuIsOpen(false);
    };
    const onSearchChange = (value, { action }) => {
        if (action === 'input-change') {
            setValue(value);
            setMenuIsOpen(true);
        }
    };

    const onBlur = () => {
        setVisible(false);
        setMenuIsOpen(false);
    };
    const onFocus = () => {
        setVisible(true);
        setMenuIsOpen(true);
    };

    return {
        clearAddressValue: () => setValue(),
        blurInputOnSelect: true,
        viewField: !loadingDefault,
        defaultValue,
        menuIsOpen,
        placeholder: (
            <span>
                Введите адрес<span style={{ color: colors.error_soft }}>*</span>
            </span>
        ),
        noOptionsMessage: () => (isLoadingOptions ? 'Загрузка...' : 'Начните вводить адресс'),
        inputValue: visible ? value : undefined,
        onAddressChange,
        onSearchChange,
        onBlur,
        onFocus,
        onMenuOpen: onFocus,
        onMenuClose: onBlur,
        onInputChange: onSearchChange,
        getOptionValue: (option) => option.unrestricted_value,
        getSuggestions: getAddresses({ additionalAddresses }),
        isLoading: isLoadingOptions,
        defaultOptions: options,
        getOptionLabel: (item) => {
            return item.housingComplexValue || item.unrestricted_value;
        }
    };
};
