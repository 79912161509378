import styled from '@emotion/styled';
import { Button } from 'components/Button';
import colors from 'colors';

export const StyledSubmit = styled(Button)`
    width: 280px;
`;

export const StyledHeader = styled.div`
    font-weight: bold;
    font-size: 64px;
    line-height: 78px;
    color: ${colors.grayScale_600};
    margin-bottom: 36px;
`;

export const StyledText = styled.div`
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 80px;
    max-width: 900px;
    color: ${({ error }) => error && colors.error_soft};
`;

export const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
`;
