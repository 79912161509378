import React, { useEffect, useRef } from 'react';
import { useValues } from 'kea';

import { TiltButton } from 'components/TiltButton';
import { PageSectionHeader } from 'components/PageSectionHeader';

import { StyledButtonsWrap, StyledPageWrap } from './units';

import calcLogic from 'store/calculation';
import userLogic from 'store/user';

import { CALC_VISIBILITIES } from 'config';

const FourButtons = () => {
    const btnsWrapRef = useRef();
    const { calc1Done, calc2Done, calc3Done } = useValues(calcLogic);
    const { userData: { role, is_superuser: isSuperuser } = {} } = useValues(userLogic);

    const roleVisibilities = Object.entries(CALC_VISIBILITIES).reduce((result, [entry, value]) => {
        result[entry] = isSuperuser || value[role];
        return result;
    }, {});

    useEffect(() => {
        btnsWrapRef.current?.scrollIntoView(false);
    }, [roleVisibilities]);

    return (
        <StyledPageWrap>
            <PageSectionHeader innerRef={btnsWrapRef}>Расчет нормативной ставки</PageSectionHeader>
            <StyledButtonsWrap>
                {roleVisibilities['calc1'] && (
                    <TiltButton
                        to="/calc1"
                        hintHeader={'Информация по данной стадии расчета'}
                        highlight={!calc1Done}
                        hintText={
                            <span>
                                Для расчета нормативной ставки нужно указывать стадию <strong>жизненного цикла</strong>{' '}
                                и класс услуги
                            </span>
                        }>
                        Выбор класса обслуживания многоквартирных домов
                    </TiltButton>
                )}
                {roleVisibilities['calc2'] && (
                    <TiltButton
                        to="/calc2"
                        highlight={calc1Done && !calc2Done}
                        disable={!calc1Done}
                        hintHeader={'Информация по данной стадии расчета'}
                        hintText={
                            <span>
                                Для расчета нормативной ставки нужно указывать стадию <strong>жизненного цикла</strong>{' '}
                                и класс услуги
                            </span>
                        }
                        shadowColor={'#59CFD0'}
                        color={'linear-gradient(180deg, #50B9A7 -41.75%, #59CFD0 100%)'}>
                        Ввод параметров многоквартирных домов
                    </TiltButton>
                )}
                {roleVisibilities['calc3'] && (
                    <TiltButton
                        highlight={calc2Done && !calc3Done}
                        disable={!calc2Done}
                        to="/calc3"
                        hintHeader={'Информация по данной стадии расчета'}
                        hintText={
                            <span>
                                Для расчета нормативной ставки нужно указывать стадию <strong>жизненного цикла</strong>{' '}
                                и класс услуги
                            </span>
                        }
                        shadowColor={'#4A79C1'}
                        color={'linear-gradient(180deg, #2C307D -41.75%, #4A79C1 100%)'}>
                        Нормированная ставка обслуживания
                    </TiltButton>
                )}
                {roleVisibilities['calc4'] && (
                    <TiltButton
                        to="/calc4"
                        highlight={calc3Done}
                        disable={!calc3Done}
                        hintHeader={'Информация по данной стадии расчета'}
                        hintText={
                            <span>
                                Для расчета нормативной ставки нужно указывать стадию <strong>жизненного цикла</strong>{' '}
                                и класс услуги
                            </span>
                        }
                        shadowColor={'#EA8D65'}
                        color={'linear-gradient(180deg, #EB535D -41.75%, #EA8D65 100%)'}>
                        Экспертная гармонизированная ставка ТО
                    </TiltButton>
                )}
            </StyledButtonsWrap>
        </StyledPageWrap>
    );
};

export { FourButtons };
