import React from 'react';
import { css, Global } from '@emotion/react';
import styled from '@emotion/styled';

import colors from 'colors';

export const StyledPriceWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 140px;
    padding: 10px 25px;
    position: absolute;
    z-index: 2;
    transform: translateX(-50%);
    background: ${colors.grayScale_0};
    box-shadow: 0 2px 12px ${colors.grayScale_300};
    min-height: 50px;
    border-radius: 50px;
    font-size: 18px;
    color: ${colors.main_400};
    user-select: none;
    transition: left 0.3s ease;
    cursor: ${({ isDrag }) => (isDrag ? 'grabbing' : 'grab')};
    ${({ isEditable }) =>
        !isEditable &&
        css`
            background: ${colors.grayScale_300};
            color: ${colors.grayScale_400};
            cursor: not-allowed;
        `};
`;

export const StyledInputWrapper = styled.span`
    position: relative;
    pointer-events: ${({ noEvents }) => (noEvents ? 'none' : 'initial')};
`;
export const StyledInput = styled.input`
    -webkit-appearance: none;
    border: none;
    padding: 0 0 0 5px;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    font-size: 0.8em;
    opacity: 0;
    cursor: ${({ isDrag }) => (isDrag ? 'grabbing' : 'initial')};
    pointer-events: ${({ disableInputs }) => (disableInputs ? 'none' : 'auto')};
    &:focus {
        outline-color: ${colors.main_300};
        opacity: 1;
    }
`;

export const cursorGlobal = (
    <Global
        styles={css`
            body {
                cursor: grabbing;
            }
        `}
    />
);
