import React, { useState, useCallback, useReducer, useMemo, useEffect } from 'react';
import { useValues, useActions } from 'kea';
import { toast } from 'react-toastify';

import { H2, H3, PaperBlock } from 'components';
import { MemoizedField } from 'components/Field';
import { CalcTiles } from 'components/CalcTiles';
import { ModalLoader } from 'components/ModalLoader';
import { MemoizedCalcHeadings } from 'components/CalcHeadings';

import calcLogic from 'store/calculation';
import userLogic from 'store/user';

import { isNil } from 'utils';

import {
    StyledFieldsGrid,
    StyledButton,
    StyledAgreeButton,
    StyledDeclineButton,
    StyledGlobalModal,
    StyledHint
} from './units';
import { getValidationStatus } from './utils';

import { AREAS_FIELDS, CALC_VISIBILITIES } from 'config';
import authLogic from 'store/auth';

export const Calc2 = ({ history }) => {
    const { isAuth } = useValues(authLogic);
    const { userData: { role, is_superuser: isSuperuser } = {} } = useValues(userLogic);
    const { data, isLoading, calc1Done } = useValues(calcLogic);
    const { setData, createCalculation, fetchAreasByTotal } = useActions(calcLogic);

    useEffect(() => {
        if (isAuth) {
            const roleVisibilities = Object.entries(CALC_VISIBILITIES).reduce((result, [entry, value]) => {
                result[entry] = isSuperuser || value[role];
                return result;
            }, {});
            if (!calc1Done && roleVisibilities['calc1']) {
                toast.error('Вы не завершили первый шаг, поэтому мы вернули вас на него');
                history.push('/calc1');
            }
        }
    }, [isAuth]);

    const [validity, dispatchValidity] = useReducer((prevState, event) => {
        if (event) {
            const { id } = event.target;
            return { ...prevState, [id]: getValidationStatus(data[id]) };
        } else {
            return Object.keys(AREAS_FIELDS).reduce((newValidity, id) => {
                newValidity[id] = getValidationStatus(data[id]);
                return newValidity;
            }, {});
        }
    }, {});
    const isAnyError = useMemo(() => Object.keys(AREAS_FIELDS).some((key) => isNil(data[key])), [data]);

    const handleChange = useCallback((value, { event }) => setData({ [event.target.id]: Math.abs(value) }), [setData]);

    const [finalizeForm, setFinalizeForm] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        dispatchValidity();
        if (isAnyError) {
            setFinalizeForm(true);
        } else {
            const response = await createCalculation();
            if (!response?.isError) history.push('/buttons');
        }
    };

    const handleAgree = async () => {
        await fetchAreasByTotal();
        setFinalizeForm(false);
        dispatchValidity();
    };
    const handleDecline = () => setFinalizeForm(false);

    return (
        <>
            <MemoizedCalcHeadings />
            <PaperBlock>
                <CalcTiles />
            </PaperBlock>
            <PaperBlock>
                <form onSubmit={handleSubmit}>
                    <StyledHint>
                        Вы можете внести только общую площадь объекта и нажать "Продолжить".
                        <br />В этом случае поля параметров объекта будут заменены усреденными справочными данными в
                        соответствии с выбранным классом.
                    </StyledHint>
                    <H3>Ввод площадей</H3>
                    <StyledFieldsGrid>
                        {Object.entries(AREAS_FIELDS).map(([key, config]) => (
                            <MemoizedField
                                key={key}
                                id={key}
                                name={key}
                                {...config}
                                type="number"
                                required={key === 'totalArea'}
                                value={!isNil(data[key]) ? Math.round(data[key]) : ''}
                                isSuccess={validity[key] === 'success'}
                                isError={validity[key] === 'error'}
                                onChange={handleChange}
                                onBlur={dispatchValidity}
                            />
                        ))}
                    </StyledFieldsGrid>
                    <StyledButton size="md-lg" rounded disabled={!data.totalArea}>
                        Продолжить
                    </StyledButton>
                </form>
            </PaperBlock>
            <StyledGlobalModal isOpen={finalizeForm}>
                <H2 color={'error_soft'}>Предупреждение!</H2>
                <p>
                    Информация введена не полностью или существуют математические неточности. Использовать информацию из
                    справочника для заполнения пустых ячеек?
                </p>
                <div>
                    <StyledAgreeButton size="sm" onClick={handleAgree} children="Да" />
                    <StyledDeclineButton size="sm" onClick={handleDecline} children="Нет" />
                </div>
            </StyledGlobalModal>
            {isLoading && <ModalLoader />}
        </>
    );
};

export default Calc2;
