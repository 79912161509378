import React from 'react';
import PropTypes from 'prop-types';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';

import { colors } from 'colors';
import { Loader } from 'components/Loader';

const getSize = (size) => {
    switch (size) {
        case 'sm':
            return '40px';
        case 'md':
            return '56px';
        case 'md-lg':
            return '60px';
        case 'lg':
            return '72px';
        default:
            return '56px';
    }
};

const StyledLoader = styled(Loader)`
    display: block;
    margin: 0 auto;
`;

const StyledBase = styled('button', {
    shouldForwardProp: (prop) => isPropValid(prop) && prop !== 'rounded' && prop !== 'outlined' && prop !== 'loading'
})`
    background: ${({ outlined, color }) => (outlined ? 'transparent' : colors[color] || color || colors.main_400)};
    color: ${({ outlined, color }) => (outlined ? colors[color] || color || colors.grayScale_900 : colors.grayScale_0)};
    height: ${({ size }) => getSize(size)};
    padding: 8px 32px;
    border-radius: ${({ rounded, size }) => (rounded ? getSize(size) : '6px')};
    border: ${({ outlined, color }) =>
        outlined ? '1px solid ' + (colors[color] || color || colors.grayScale_900) : 'none'};
    font-size: 18px;
    line-height: 22px;
    cursor: pointer;
    transition: color 0.3s ease, background 0.3s ease;
    ${({ disabled }) =>
        disabled &&
        css`
            background: ${colors.grayScale_300};
            color: ${colors.grayScale_400};
            cursor: not-allowed;
        `};
    ${({ loading }) =>
        loading &&
        css`
            display: flex;
            align-items: center;
        `};
    &[href] {
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
    }
    ${({ css }) => css};
`;

const Button = ({ children, rounded, outlined, color, size, onClick, css, loading, ...rest }) => {
    return (
        <StyledBase {...{ loading, rounded, outlined, color, size, onClick, css, ...rest }}>
            {loading ? <StyledLoader color={outlined ? undefined : colors.grayScale_0} /> : children}
        </StyledBase>
    );
};

Button.defaultProps = {
    size: 'md'
};

Button.propTypes = {
    size: PropTypes.oneOf(['sm', 'md', 'md-lg', 'lg']).isRequired,
    rounded: PropTypes.bool,
    outlined: PropTypes.bool,
    color: PropTypes.string,
    disabled: PropTypes.bool
};

export { Button };
