import React from 'react';

import { Header } from 'components/Header';
import { Footer } from 'components/Footer';
import { Container } from 'components';

import { StyledLayoutWrap, StyledContentWrap } from './units';

const Layout = ({ children, blankContainer = false }) => {
    return (
        <StyledLayoutWrap>
            <Header />
            <StyledContentWrap>{blankContainer ? children : <Container>{children}</Container>}</StyledContentWrap>
            <Footer />
        </StyledLayoutWrap>
    );
};

export { Layout };
