import React from 'react';
import styled from '@emotion/styled';

const ItemWrap = styled.div`
    display: flex;
    cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
    padding: 0 24px;
`;

const ItemText = styled.div`
    margin-left: 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
`;

export const InfoItem = ({ children, icon: Icon, onClick, style }) => (
    <ItemWrap onClick={onClick} style={style}>
        {Icon && <Icon />}
        <ItemText>{children}</ItemText>
    </ItemWrap>
);
