import React, { useEffect, useMemo, useState } from 'react';
import { useActions, useValues } from 'kea';
import { StringParam, useQueryParam } from 'use-query-params';

import { RoleHidden } from 'components/RoleHidden';

import {
    StyledDataButton,
    StyledDataButtonsGrid,
    StyledFieldsCaption,
    StyledFieldsGrid,
    StyledTwoColumns,
    StyledManual,
    StyledNotice,
    StyledSelect,
    SummaryFields
} from './units';

import calcLogic from 'store/calculation';

const summariesConfig = {
    normative: {
        rateKey: 'normativeRate',
        dataKey: 'normativeData',
        rateLabel: 'Нормативная ставка',
        toasterKey: 'toster'
    },
    auto: {
        rateKey: 'autoRate',
        dataKey: 'autoData',
        rateLabel: 'Автоматическая гармонизированная ставка',
        toasterKey: 'autoHarmToster'
    },
    expert: {
        rateKey: 'expertRate',
        dataKey: 'expertData',
        rateLabel: 'Экспертная гармонизированная ставка',
        toasterKey: 'manHarmToster'
    }
};

const createSelectOption = (value) => ({
    value,
    label: summariesConfig[value].rateLabel,
    view: summariesConfig[value].rateLabel
});

export const BriefData = ({
    fetchExtra = false,
    isLoading = false,
    hideHarmonization = false,
    historicData = false
}) => {
    const {
        address,
        data,
        normativeData,
        autoData,
        expertData,
        currentLifeCycle,
        currentRateClass,
        harmonization
    } = useValues(calcLogic);
    const { fetchExtraData } = useActions(calcLogic);

    const [, setCalcId] = useQueryParam('reports_modal', StringParam);

    const { id, date } = data;

    const formattedDate = useMemo(() => {
        const dateObj = date ? new Date(date) : new Date();
        return `${dateObj.getDate()}.${dateObj.getMonth() + 1}.${dateObj.getFullYear()}`;
    }, [date]);

    useEffect(() => {
        if (fetchExtra) fetchExtraData();
    }, [fetchExtra, fetchExtraData]);

    const dataSet = useMemo(
        () =>
            Object.entries(summariesConfig).reduce((result, [key, { rateKey, dataKey, ...props }]) => {
                props.rate = historicData ? data[rateKey] : { normativeData, autoData, expertData }?.[dataKey]?.rate;
                result[key] = props;
                return result;
            }, {}),
        [data, normativeData, autoData, expertData, historicData]
    );

    const autoRate = dataSet.auto?.rate;
    const expertRate = dataSet.expert?.rate;

    const [pickedHarmonization, setPickedHarmonization] = useState(
        harmonization || (autoRate && 'auto') || (expertRate && 'expert') || null
    );

    const options = useMemo(() => {
        let result = [];
        if (autoRate) result.push(createSelectOption('auto'));
        if (expertRate) result.push(createSelectOption('expert'));
        return result;
    }, [autoRate, expertRate]);

    const value = pickedHarmonization || (data.autoRate && 'auto') || (data.expertRate && 'expert');

    return (
        <>
            <StyledTwoColumns>
                <StyledDataButtonsGrid>
                    <StyledDataButton>Дата расчета: {formattedDate}</StyledDataButton>
                    <StyledDataButton>{currentLifeCycle?.name}</StyledDataButton>
                    <StyledDataButton>{address || 'Адрес объекта'}</StyledDataButton>
                    <StyledDataButton>{currentRateClass?.name}</StyledDataButton>
                    <StyledDataButton onClick={() => setCalcId(id)}>Отчеты и сметы</StyledDataButton>
                </StyledDataButtonsGrid>
                <StyledFieldsGrid>
                    <StyledFieldsCaption>Не предусматривает мероприятия по экономии средств</StyledFieldsCaption>
                    <SummaryFields isLoading={isLoading} {...dataSet.normative} />
                </StyledFieldsGrid>
            </StyledTwoColumns>
            {!hideHarmonization && !!options.length && !!value && (
                <RoleHidden roles="guest">
                    <StyledNotice>
                        По итогам расчета резидент не доплачивает до уровня нормативной ставки исходя из понижения
                        класса ряда услуг
                    </StyledNotice>
                    <StyledTwoColumns>
                        <StyledManual>
                            <p>
                                <strong>Нормативная ставка</strong> обеспечивает управление и техническую эксплуатацию
                                недвижимости, достаточную для сохранения стоимости квартиры на протяжении долгого
                                периода и поддержания самого высокого уровня безопасности, комфорта и жизнеобеспечения,
                                который позволяет обеспечить набор инженерных систем многоквартирного дома.
                            </p>
                            <p>
                                Если собственники МКД не готовы платить нормативную ставку и назначают тариф ниже
                                нормативного, то ряд услуг может быть представлен по меньшей стоимости, но
                                соответственно иметь более низкое качество.
                            </p>
                            <p>
                                <strong>Гармонизированная ставка</strong> – это компромисс между желанием собственника
                                платить меньше и уровнем обслуживания, который не обеспечивает сохранение вышеуказанных
                                параметров, но все еще приемлем с точки зрения потребителя.
                            </p>
                            <p>
                                Программа явно показывает, как потребность резидентов в дешёвом обслуживании снижает
                                качество и стоимость определенных услуг. Так же CommunalCa® отражает список услуг, от
                                которых придется отказаться, снижая стоимость обслуживания дома.
                            </p>
                        </StyledManual>
                        <StyledFieldsGrid style={{ alignSelf: 'flex-start' }}>
                            <StyledSelect
                                type="select"
                                variant="filled"
                                disabled={options.length < 2}
                                options={options}
                                value={value}
                                onChange={setPickedHarmonization}
                            />
                            <SummaryFields isLoading={isLoading} {...dataSet[value]} />
                        </StyledFieldsGrid>
                    </StyledTwoColumns>
                </RoleHidden>
            )}
        </>
    );
};

export const MemoizedBriefData = React.memo(BriefData);
