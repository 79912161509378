import React, { useEffect, useMemo, useReducer } from 'react';
import { useActions, useValues } from 'kea';
import { useHistory } from 'react-router-dom';
import { css } from '@emotion/react';
import { BooleanParam, useQueryParam } from 'use-query-params';

import { Loader, PaperBlock, H2, Button } from 'components';
import { Avatar } from 'components/Avatar';
import { ActivatePhoneModal } from 'components/modals/ActivatePhoneModal';
import { ChangePasswordModal } from 'components/modals/ChangePasswordModal';

import userLogic from 'store/user';

import { SQ_M } from '../../config';

import {
    StyledWrap,
    StyledAvatarWrap,
    StyledField,
    FieldRow,
    FieldCol,
    StyledSpacer,
    StyledFieldOuter,
    StyledHint
} from './units';
import { isNil } from 'utils/index';

const fieldsConfig = [
    { type: 'text', name: 'last_name', label: 'Фамилия', required: true },
    { type: 'text', name: 'first_name', label: 'Имя', required: true },
    { type: 'text', name: 'patronymic', label: 'Отчество' },
    { type: 'number', name: 'area', label: 'Площадь квартиры', suffix: SQ_M, required: true },
    { type: 'tel', name: 'phone_number', label: 'Мобильный телефон', required: true },
    { type: 'email', name: 'email', label: 'Электронная почта', required: true }
];
const Settings = () => {
    const history = useHistory();
    const { userData, userDataLoading } = useValues(userLogic);
    const { updateUserData } = useActions(userLogic);
    const [params, setParam] = useReducer(
        (state, { init, name, value } = {}) => (init ? value : name ? { ...state, [name]: value } : state),
        userData
    );
    const [, setIsProneActivationOpen] = useQueryParam('phone_activate', BooleanParam);
    const [, setIsChangePasswordOpen] = useQueryParam('change_password', BooleanParam);

    useEffect(() => {
        setParam({ init: true, value: userData });
    }, [userData]);

    const { first_name: firstName, last_name: lastName, email: emailInfo, is_verified_phone: isPhoneActive } = userData;

    const areRequiredFieldsFilled = useMemo(
        () => fieldsConfig.every(({ required, name }) => !isNil(params[name]) || !required),
        [params]
    );

    const onSave = () => areRequiredFieldsFilled && updateUserData({ params });

    const renderField = (args) => {
        const { name } = args;
        const isPhone = name === 'phone_number';

        const value = params[name] || null;
        const isError = userDataLoading ? false : !value || (isPhone && !isPhoneActive);

        const field = (
            <StyledField
                {...args}
                key={name}
                value={value}
                onChange={(value) => setParam({ value, name })}
                isError={isError}
            />
        );
        if (isPhone) {
            return (
                <StyledFieldOuter key={name}>
                    {field}
                    {!isPhoneActive && (
                        <StyledHint onClick={() => setIsProneActivationOpen(true)}>
                            Ваш телефон нужно <span>активировать</span>
                        </StyledHint>
                    )}
                </StyledFieldOuter>
            );
        }

        return field;
    };

    return (
        <StyledWrap>
            <ActivatePhoneModal />
            <ChangePasswordModal />
            <H2>Личные данные</H2>
            <PaperBlock id="main-settings" style={{ paddingTop: 60 }}>
                <StyledAvatarWrap>
                    {userDataLoading ? (
                        <Loader color={'#000'} />
                    ) : (
                        <Avatar
                            size={120}
                            name={emailInfo || firstName + lastName}
                            text={(firstName && firstName.charAt(0)) + (lastName && lastName.charAt(0))}
                        />
                    )}
                </StyledAvatarWrap>
                <FieldRow>{fieldsConfig.map(renderField)}</FieldRow>
                <StyledSpacer />
                <FieldRow>
                    <FieldCol>
                        <Button
                            css={css`
                                width: 100%;
                                max-width: 400px;
                                margin-bottom: 12px;
                            `}
                            size={'md'}
                            onClick={() => setIsChangePasswordOpen(true)}
                            outlined
                            rounded>
                            Сменить пароль
                        </Button>
                        <Button
                            disabled={!areRequiredFieldsFilled}
                            css={css`
                                width: 100%;
                                max-width: 400px;
                            `}
                            size={'md'}
                            onClick={onSave}
                            rounded>
                            Сохранить
                        </Button>
                    </FieldCol>
                    <div />
                    <Button
                        css={css`
                            width: 100%;
                            max-width: 400px;
                            align-self: flex-end;
                        `}
                        onClick={() => history.push('/calc-history')}
                        size={'md'}
                        outlined
                        rounded>
                        История расчетов
                    </Button>
                </FieldRow>
            </PaperBlock>
        </StyledWrap>
    );
};

export { Settings };
