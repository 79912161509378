import React from 'react';

import { Button } from 'components/Button';

import { StyledCardBase, StyledCardHeader, StyledHint } from './units';

const CalcCard = ({ title, image: Image, buttonColor, onClick, hint }) => {
    return (
        <StyledCardBase>
            <div>
                <StyledCardHeader>{title}</StyledCardHeader>
                {Image && <Image />}
            </div>
            {hint && <StyledHint>{hint}</StyledHint>}
            <Button
                disabled={!onClick}
                css={{ width: '100%', marginTop: 32 }}
                rounded
                size={'lg'}
                color={buttonColor}
                onClick={onClick}>
                Расчитать
            </Button>
        </StyledCardBase>
    );
};

export { CalcCard };
