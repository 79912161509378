import React, { useMemo } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import colors from 'colors';

import { hexToRGBA } from 'utils';

import { Hint } from 'components/Hint';
import { Tooltip } from 'components/Tooltip';
import { Checkbox } from 'components/Checkbox';

import { createPopperOptions } from './utils';
import { Loader } from 'components/Loader';

export const StyledWrapper = styled.div`
    display: inline-block;
    position: relative;
    overflow: hidden;
    min-width: 200px;
    border-radius: 4px;
    font-size: 16px;
    background-color: ${({ variant }) => (variant === 'filled' ? colors.grayScale_50 : colors.grayScale_0)};
    background-color: ${({ isError }) => isError && hexToRGBA(colors.error_soft, 0.1)};
    ${({ wrapperStyles }) => wrapperStyles};
`;

export const StyledInput = styled.input`
    -webkit-appearance: none;
    border: none;
    width: 100%;
    padding: 0;
    position: relative;
    z-index: 10;
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    color: ${colors.grayScale_800};
    background-color: transparent;
    &::placeholder {
        transition: color 0.3s ease;
        color: ${({ label }) => (label ? 'transparent' : colors.grayScale_300)};
    }
    &:focus {
        outline: none;
        &::placeholder {
            color: ${colors.grayScale_300};
        }
    }
    // remove arrows while unfocused
    //&:not(:focus) {
    -moz-appearance: textfield;
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    //}
    &:disabled {
        opacity: 0.5;
        user-select: none;
    }
    ${({ isLoading }) => isLoading && 'visibility: hidden'};
    ${({ inputStyles }) => inputStyles};
`;

const StyledSelect = styled.select`
    appearance: none;
    border: none;
    width: 100%;
    padding: 27px 12px 12px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    ${({ isLoading }) => isLoading && 'visibility: hidden'};
    &:focus {
        outline: none;
    }
`;
const StyledSelectView = styled('div', {
    shouldForwardProp: (prop) => isPropValid(prop) && prop !== 'disabled'
})`
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;
const StyledSelectArrow = styled.div`
    position: absolute;
    right: ${({ moveLeft }) => (moveLeft ? 50 : 18)}px;
    top: 50%;
    transform: translateY(-65%) rotate(-45deg);
    width: 10px;
    height: 10px;
    border-style: solid;
    border-color: ${colors.grayScale_400};
    border-width: 0 0 2px 2px;
    transition: border-color 0.3s ease;
    ${StyledSelect}:focus ~ & {
        border-color: ${colors.main_400};
    }
`;
export const Select = ({ onChange, value, options = [], disabled, hint, callbackId, ...rest }) => {
    const [viewsHash, finalOptions] = useMemo(() => {
        const viewsHash = {};
        const finalOptions = [];
        options.forEach((option) => {
            switch (typeof option) {
                case 'string':
                    finalOptions.push(<option key={option} value={option} label={option} children={option} />);
                    viewsHash[option] = option;
                    break;
                case 'object':
                    const { value, label, view, ...rest } = option;
                    const _label = label ?? value;
                    finalOptions.push(<option key={value} value={value} label={_label} children={_label} {...rest} />);
                    viewsHash[value] = view ?? value;
                    break;
                default:
            }
        });

        return [viewsHash, finalOptions];
    }, [options]);

    return (
        <>
            <StyledSelectView role="presentation" disabled={disabled}>
                {viewsHash[value] || <span>&nbsp;</span>}
            </StyledSelectView>
            <StyledSelect
                value={value}
                onChange={(event) => onChange?.(event.target.value, { event, callbackId })}
                {...{ disabled, ...rest }}>
                {finalOptions}
            </StyledSelect>
            <StyledSelectArrow role="presentation" moveLeft={!!hint} />
        </>
    );
};

export const StyledLabelWrapper = styled('label', {
    shouldForwardProp: (prop) => isPropValid(prop) && prop !== 'disabled'
})`
    display: block;
    height: 100%;
    position: relative;
    padding: ${({ label, isCheckbox }) => (isCheckbox ? '30px 12px' : label ? '29px 12px 12px' : '21px 12px 20px')};
    padding-right: ${({ hint }) => (hint ? 47 : 12)}px;
    cursor: ${({ isCheckbox }) => (isCheckbox ? 'pointer' : 'text')};
    cursor: ${({ disabled }) => disabled && 'default'};
    ${({ labelWrapperStyles }) => labelWrapperStyles};
`;

export const StyledBorder = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 1px solid ${({ borderColor }) => borderColor || colors.grayScale_400};
    border-radius: 4px;
    pointer-events: none;
    transition: border-color 0.3s ease;
    ${StyledLabelWrapper}:hover > & {
        border-color: ${({ borderHoverColor }) => borderHoverColor || colors.grayScale_700};
    }
    ${StyledInput}:focus ~ &,
    ${StyledSelect}:focus ~ & {
        border-color: ${({ borderFocusColor }) => borderFocusColor || colors.main_400};
    }
    ${StyledLabelWrapper} > ${StyledInput} ~ & {
        border-color: ${({ isError, isSuccess }) => (isError ? colors.error_soft : isSuccess && colors.success)};
    }
    ${StyledWrapper} > ${StyledLabelWrapper} > & {
        border-color: ${({ disabled }) => disabled && colors.grayScale_300};
    }
`;

export const StyledLabelText = styled('span', {
    shouldForwardProp: (prop) => isPropValid(prop) && prop !== 'value' && prop !== 'required' && prop !== 'disabled'
})(({ value, disabled, isError, required, isCheckbox }) => {
    const isEmptyValue = !value && value !== 0;

    let dominantColor;
    switch (true) {
        case isError:
            dominantColor = colors.error_soft;
            break;
        case disabled:
            dominantColor = colors.grayScale_300;
            break;
        default:
            dominantColor = null;
    }

    return css`
        position: absolute;
        z-index: 15;
        top: 50%;
        left: 12px;
        transform: translateY(-50%);
        transform-origin: top left;
        pointer-events: none;
        color: ${dominantColor || colors.grayScale_800};
        transition-property: top, transform, color;
        transition-duration: 0.3s;
        transition-timing-function: ease;
        ${typeof value !== 'boolean' && !isEmptyValue ? '&' : `${StyledInput}:focus ~ &`} {
            top: 32%;
            transform: translateY(-50%) scale(0.75);
            color: ${dominantColor || colors.grayScale_400};
        }
        ${StyledInput}:focus ~ &,
        ${StyledSelect}:focus ~ & {
            color: ${dominantColor || colors.main_400};
        }
        &:after {
            content: ${required && !isCheckbox && '"*"'};
            transition: inherit;
            color: ${isEmptyValue && colors.error_soft};
        }
    `;
});

export const StyledCheckbox = styled(Checkbox)`
    position: absolute;
    top: 50%;
    right: ${({ hint }) => (hint ? 47 : 10)}px;
    transform: translateY(-50%);
    ${({ isLoading }) => isLoading && 'visibility: hidden'};
`;

const StyledSuffixContainer = styled.div`
    user-select: none;
    display: none;
    height: 0;
    overflow: visible;
    ${StyledInput}:not(:placeholder-shown) ~ & {
        display: block;
    }
`;
const StyledSuffixSubContainer = styled.div`
    display: flex;
    overflow: hidden;
    transform: translateY(-100%);
`;
const StyledSuffixBlindValue = styled.div`
    flex-shrink: 0;
    opacity: 0;
`;
const StyledSuffix = styled.div`
    color: ${({ disabled }) => (disabled ? colors.grayScale_400 : colors.grayScale_800)};
    ${StyledSuffixBlindValue}:empty + & {
        display: none;
    }
`;
export const Suffix = ({ value, suffix, disabled, ...rest }) => (
    <StyledSuffixContainer role="presentation" {...rest}>
        <StyledSuffixSubContainer>
            <StyledSuffixBlindValue>{value}</StyledSuffixBlindValue>
            <StyledSuffix {...{ disabled }}>&nbsp;{suffix}</StyledSuffix>
        </StyledSuffixSubContainer>
    </StyledSuffixContainer>
);

export const StyledHint = styled(Hint)`
    position: absolute;
    z-index: 20;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    transition: color 0.3s ease;
    color: ${({ isOpen }) => isOpen && colors.success};
`;
export const FieldHint = ({ children, maxWidth, wrapperRef }) => {
    const popperOptions = useMemo(() => createPopperOptions(wrapperRef, maxWidth), [wrapperRef]);
    return (
        <Tooltip popperOptions={popperOptions} trigger={<StyledHint size={'M'} />}>
            {children}
        </Tooltip>
    );
};

export const StyledLoader = styled(Loader)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.5);
`;
