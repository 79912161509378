import React from 'react';
import { css, Global } from '@emotion/react';

export const ScrollHider = React.memo(() => (
    <Global
        styles={css`
            body {
                overflow: hidden;
            }
        `}
    />
));
