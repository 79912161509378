import React from 'react';
import { Global, css } from '@emotion/react';

import colors from '../colors';

export const GlobalStyles = () => (
    <Global
        styles={css`
            *,
            *:before,
            *:after {
                box-sizing: border-box;
            }
            html {
                height: 100%;
                font-size: 14px;
            }
            body {
                height: 100%;
                font-family: 'Montserrat', Arial, sans-serif;
                line-height: 1.2;
                letter-spacing: -0.015em;
                color: ${colors.grayScale_800};
                background: ${colors.grayScale_50};
            }
            #root {
                min-height: 100%;
                position: relative;
                overflow: hidden;
                display: flex;
                z-index: 0;
            }
            img {
                max-width: 100%;
            }

            a {
                outline: none;
            }
        `}
    />
);

export default GlobalStyles;
