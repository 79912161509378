import PropTypes from 'prop-types';
import { kea } from 'kea';

import axios from 'utils/axiosWrapper';
import throttle from 'utils/throttle';
import { INACTIVITY_TIMEOUT } from 'config';

import store from 'store/initStore';

import dictsLogic from 'store/dicts';
import userDataLogic from 'store/user';
import { toast } from 'react-toastify';

let refreshTokenPromise = Promise.resolve();

let refreshToken;

const authLogic = kea({
    path: () => ['scenes', 'user', 'auth'],

    connect: {
        actions: [userDataLogic, ['fetchUserData'], dictsLogic, ['callDicts']]
    },

    actions: () => ({
        startAuthorization: () => true,
        setAuthorizationValue: (isAuth) => ({ isAuth }),
        setAuthorizationExpired: (isAuth) => ({ isAuth }),
        setError: (message) => message,
        clearError: () => null,
        setValidationError: (error) => error,
        setToken: (token) => ({ token }),
        stopLoading: () => true,
        setRefresh: (token) => ({ token }),
        setBlockedUsers: (data) => ({ ...data })
    }),

    reducers: ({ actions }) => ({
        isAuth: [
            false,
            PropTypes.bool,
            { persist: true },
            {
                [actions.setAuthorizationValue]: (state, payload) => payload.isAuth,
                [actions.setAuthorizationExpired]: () => false
            }
        ],
        isAuthExpired: [
            false,
            PropTypes.bool,
            {
                [actions.setAuthorizationValue]: () => false,
                [actions.setAuthorizationExpired]: (state, payload) => payload.isAuth
            }
        ],
        isLoading: [
            false,
            PropTypes.bool,
            {
                [actions.startAuthorization]: () => true,
                [actions.setAuthorizationValue]: () => false,
                [actions.setAuthorizationExpired]: () => false,
                [actions.stopLoading]: () => false,
                [actions.setError]: () => false
            }
        ],
        error: [
            null,
            PropTypes.string,
            {
                [actions.startAuthorization]: () => null,
                [actions.setAuthorizationValue]: () => null,
                [actions.setAuthorizationExpired]: () => null,
                [actions.clearError]: () => null,
                [actions.setError]: (_, payload) => payload.errorText || 'Unknown error'
            }
        ],
        validationError: [
            {},
            PropTypes.object,
            {
                [actions.clearError]: () => ({}),
                [actions.setValidationError]: (_, payload) => payload
            }
        ],
        token: [
            '',
            PropTypes.string,
            { persist: true },
            {
                [actions.setToken]: (state, payload) => payload.token || null
            }
        ],
        refresh: [
            '',
            PropTypes.string,
            { persist: true },
            {
                [actions.setRefresh]: (state, payload) => payload.token || null
            }
        ]
    }),
    thunks: ({ actions, values }) => {
        return {
            createRefresh: () => {
                refreshToken = throttle(
                    async () => {
                        refreshTokenPromise = axios({
                            method: 'post',
                            url: '/auth/jwt/refresh/',
                            data: {
                                refresh: values.refresh
                            }
                        });
                        await refreshTokenPromise;
                    },
                    INACTIVITY_TIMEOUT / 2,
                    { firstCall: false }
                );
            },
            initDataCall: async (silent) => {
                // await actions.fetchAllDicts();
                await actions.fetchUserData({ silent });
            },
            tryToDoInit: async () => {
                await actions.initDataCall(true);
            },
            setAuthorization: (isAuth) => {
                const currentIsAuth = values.isAuth;
                if (currentIsAuth !== isAuth)
                    if (isAuth) {
                        actions.initDataCall().catch((e) => console.error(e));
                    }
                actions.setAuthorizationValue(isAuth);
            },
            updateAuthToken: ({ token }) => {
                actions.setToken(token);
            },
            refreshToken: () => refreshToken && refreshToken(),
            getRefreshTokenPromise: async () => await refreshTokenPromise.catch((e) => e && console.error(e)),
            expire: () => {
                const isAuth = values.isAuth;
                actions.setAuthorizationExpired(true);
                if (isAuth) {
                    actions.logOut();
                }
            },
            logIn: async ({ login, password, onSuccess, onAuthFailure } = {}) => {
                actions.startAuthorization();

                try {
                    const res = await axios({
                        method: 'post',
                        url: '/auth/jwt/create/',
                        data: { email: login, password }
                    });
                    actions.setAuthorization(true);
                    actions.callDicts();
                    onSuccess && onSuccess({ result: res });
                } catch (reason) {
                    console.error(reason);
                    actions.setError({ errorText: 'Неверный логин или пароль' });
                    actions.setValidationError(reason.response.data);
                    onAuthFailure && onAuthFailure(reason);
                }
            },
            resetPassword: async ({ email, onSuccess, onResetFailure } = {}) => {
                actions.startAuthorization();

                try {
                    const res = await axios({
                        method: 'post',
                        url: '/users/reset_password/',
                        data: { email }
                    });
                    actions.stopLoading();
                    onSuccess && onSuccess({ result: res });
                } catch (reason) {
                    console.error(reason);
                    actions.setError({ errorText: 'Неверный e-mail или логин' });
                    onResetFailure && onResetFailure(reason);
                }
            },
            activateUser: async ({ uid, token, onSuccess, onFailure } = {}) => {
                actions.startAuthorization();

                try {
                    await axios({
                        method: 'post',
                        url: '/users/activation/',
                        data: { uid, token }
                    });
                    actions.stopLoading();
                    onSuccess && onSuccess();
                } catch (reason) {
                    console.error(reason);
                    actions.setError({ errorText: 'Неверный e-mail или логин' });
                    onFailure && onFailure(reason);
                }
            },
            resendActivationUser: async ({ email, onSuccess, onFailure } = {}) => {
                actions.startAuthorization();

                try {
                    await axios({
                        method: 'post',
                        url: '/users/resend_activation/',
                        data: { email }
                    });
                    actions.stopLoading();
                    onSuccess && onSuccess();
                } catch (reason) {
                    console.error(reason);
                    actions.setError({ errorText: 'Неверный e-mail или логин' });
                    onFailure && onFailure(reason);
                }
            },
            sendOTP: async ({ userId, onSuccess, onFailure } = {}) => {
                actions.startAuthorization();

                try {
                    const { data } = await axios({
                        method: 'post',
                        url: '/users/send_otp/',
                        data: { id: userId }
                    });
                    actions.stopLoading();
                    onSuccess && onSuccess(data);
                } catch (reason) {
                    console.error(reason);
                    actions.setError({ errorText: 'Неверный e-mail или логин' });
                    onFailure && onFailure(reason.response?.data);
                }
            },
            phoneActivation: async ({ uid, otp, onSuccess, onFailure } = {}) => {
                actions.startAuthorization();

                try {
                    await axios({
                        method: 'post',
                        url: '/users/phone_activation/',
                        data: { uid, otp }
                    });
                    actions.stopLoading();
                    toast.success('Телефон активирован');
                    onSuccess && onSuccess();
                } catch (reason) {
                    console.error(reason);
                    onFailure && onFailure(reason.response?.data);
                }
            },
            resetPasswordConfirm: async ({ uid, token, password, onSuccess, onFailure } = {}) => {
                actions.startAuthorization();

                try {
                    await axios({
                        method: 'post',
                        url: '/users/reset_password_confirm/',
                        data: { uid, token, new_password: password }
                    });
                    actions.stopLoading();
                    onSuccess && onSuccess();
                } catch (reason) {
                    console.error(reason);
                    actions.setError({ errorText: 'Неверный e-mail' });
                    onFailure && onFailure(reason.response?.data);
                }
            },
            changePassword: async ({ passwordOld, password, onSuccess, onFailure } = {}) => {
                actions.startAuthorization();

                try {
                    await axios({
                        method: 'post',
                        url: '/users/set_password/',
                        data: { current_password: passwordOld, new_password: password }
                    });
                    actions.stopLoading();
                    onSuccess && onSuccess();
                } catch (reason) {
                    console.error(reason);
                    actions.setError({ errorText: 'Неверный e-mail' });
                    onFailure && onFailure(reason.response?.data);
                }
            },
            register: async ({
                first_name,
                last_name,
                patronymic,
                email,
                phone_number,
                password,
                account,
                manager_key,
                area,
                onSuccess,
                onRegFailure
            } = {}) => {
                actions.startAuthorization();

                try {
                    const res = await axios({
                        method: 'post',
                        url: '/users/',
                        data: {
                            first_name,
                            last_name,
                            patronymic,
                            email,
                            phone_number,
                            password,
                            account,
                            manager_key,
                            area
                        }
                    });
                    actions.stopLoading();
                    onSuccess && onSuccess({ result: res });
                } catch (reason) {
                    if (reason.response && reason.response.status === 400) {
                        actions.setValidationError(reason.response.data);
                    }
                    console.error(reason);
                    actions.setError({ errorText: 'Неверные данные' });
                    onRegFailure && onRegFailure(reason);
                }
            },
            logOut: () => {
                // reset all subscribes

                // reset all mean values
                actions.setAuthorization(false);
                actions.setToken('');

                // On RESET we need to hardcode init state of isAuth, because it store at localstore,
                // and redux-reset think what init state gets from localstore, not from reducer
                store.dispatch({
                    type: 'RESET',
                    state: { scenes: { user: { auth: { isAuth: false, token: null } } } }
                });

                actions.createRefresh();
            }
        };
    },
    events: ({ actions, values }) => ({
        afterMount: () => {
            values.isAuth && actions.callDicts();
        }
    })
});

// authLogic.mount().actions.createRefresh();

export default authLogic;
