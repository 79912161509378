import styled from '@emotion/styled';

export const StyledPageWrap = styled.div`
    padding: 0 100px 100px;
`;
export const StyledButtonsWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
    counter-reset: tilt-button;
`;
