import styled from '@emotion/styled';

import { colors } from 'colors';

export const StyledHeadWrap = styled.div`
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    padding: 0 100px;
    margin-top: 110px;
    margin-bottom: 120px;
`;

export const StyledHeader = styled.div`
    font-weight: 600;
    font-size: 48px;
    line-height: 72px;
    letter-spacing: -0.015em;
    color: ${colors.main_400};
`;
