import styled from '@emotion/styled';

import { colors } from 'colors';
import { hexToRGBA } from 'utils';

import { Logo } from './Logo';
import { InfoItem } from './InfoItem';

export const StyledFooterWrapper = styled.div`
    position: relative;
    z-index: 999;
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    height: 262px;
    padding: 70px 100px 0;
    background: ${colors.grayScale_0};
    box-shadow: 0 0 8px ${hexToRGBA(colors.grayScale_900, 0.1)};
`;

export const StyledFooterLeftGroup = styled.div`
    flex-wrap: nowrap;
    width: 260px;
`;

export const StyledFooterRightGroup = styled.div`
    display: flex;
`;

export const StyledLink = styled.a`
    text-decoration: none;
    color: ${colors.grayScale_900};
    &:hover {
        text-decoration: underline;
    }
    &:focus {
        text-decoration: underline;
    }
`;

export { Logo, InfoItem };
