import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button, GlobalModal } from 'components';
import colors from 'colors';

export const StyledFieldsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 25px 125px;
`;

export const StyledButton = styled(Button)`
    display: block;
    min-width: 315px;
    margin: 40px 0 0 auto;
`;

const modalStyles = css`
    max-width: 600px;
    padding: 20px 40px 35px;
`;

export const StyledGlobalModal = (props) => <GlobalModal contentStyles={modalStyles} closeIcon={false} {...props} />;

export const StyledAgreeButton = styled(Button)`
    margin-right: 20px;
    background-color: ${colors.success};
    color: ${colors.grayScale_0};
    font-size: inherit;
`;

export const StyledDeclineButton = styled(Button)`
    background-color: ${colors.grayScale_200};
    color: ${colors.grayScale_800};
    font-size: inherit;
`;

export const StyledHint = styled.div`
    color: ${colors.grayScale_400};
    text-align: center;
    padding: 24px;
    background: ${colors.grayScale_50};
`;
