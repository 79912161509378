import { isNil } from 'utils/index';

import { RowHeading, Milestone, Line, Price } from './units';
import { RowBase } from '../RowBase';

const getSortedNumbers = (array) =>
    array.map(Number).sort((a, b) => {
        switch (true) {
            case a < b:
                return -1;
            case a > b:
                return 1;
            default:
                return 0;
        }
    });

export const MainRow = ({
    milestones,
    id,
    heading,
    linePosition,
    isFirstLine,
    isLastLine,
    magnetMilestones,
    price,
    priceToShow,
    onChange,
    onDragEnd,
    disableInputs,
    isEditable
}) => {
    if (isNil(price)) return null;
    const sortedMilestones = getSortedNumbers(milestones);

    return (
        <RowBase key={id} rowHeader={<RowHeading>{heading}</RowHeading>}>
            {sortedMilestones.map((milestone, milestoneIndex) => (
                <Milestone key={milestone}>
                    {linePosition === milestoneIndex && <Line {...{ isFirstLine, isLastLine }} />}
                </Milestone>
            ))}
            <Price
                milestones={sortedMilestones}
                {...{ id, price, priceToShow, onChange, onDragEnd, magnetMilestones, disableInputs, isEditable }}
            />
        </RowBase>
    );
};
