import { toCamelCase } from 'utils/case-convert';

/**
 * Default rename func
 * @typedef RenameFunc
 * @param {string} name
 * @return {string}
 */
export const defaultRenameFunction = (name) => toCamelCase(name);

export const renameKeys = (obj = {}, keysMap = {}) =>
    Object.keys(obj).reduce(
        (acc, key) => ({
            ...acc,
            ...{ [keysMap[key] || key]: obj[key] }
        }),
        {}
    );

export const renameKeysByFn = (obj, keysMap = defaultRenameFunction) =>
    Object.keys(obj || {}).reduce(
        (acc, key) => ({
            ...acc,
            ...{ [keysMap(key) || key]: obj[key] }
        }),
        {}
    );
