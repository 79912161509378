import PropTypes from 'prop-types';
import { useValues } from 'kea';

import userLogic from 'store/user';

export const RoleHidden = ({ roles = [], showByDefault = false, children }) => {
    const { userData: { role } = {} } = useValues(userLogic);
    if (typeof roles === 'string') roles = [roles];
    return (!role && !showByDefault) || roles.some((roleForHide) => roleForHide === role) ? null : children;
};

RoleHidden.propTypes = {
    roles: PropTypes.oneOfType([
        PropTypes.oneOf(['guest', 'resident', 'manager']),
        PropTypes.arrayOf(PropTypes.oneOf(['guest', 'resident', 'manager']))
    ]),
    children: PropTypes.node,
    showByDefault: PropTypes.bool
};
