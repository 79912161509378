import React, { useRef, useState } from 'react';
import { StyledButtonWrap, StyledErrorLabel, StyledField, StyledForm, StyledHeader, StyledSubmit } from './index';
import { useActions, useValues } from 'kea';
import authLogic from 'store/auth';

const ForgotPasswordForm = ({ onSuccess /*, onLoginRequest*/ }) => {
    const resetPasswordForm = useRef();
    const [email, setEmail] = useState('');
    const { resetPassword } = useActions(authLogic);
    const { error, isLoading } = useValues(authLogic);

    const handleSubmit = async (e) => {
        e.preventDefault();
        await resetPassword({
            email,
            onSuccess: () => {
                onSuccess && onSuccess();
                setEmail('');
            }
        });
    };
    return (
        <StyledForm ref={resetPasswordForm} onSubmit={handleSubmit} action={''} method={'POST'}>
            <StyledHeader>Восстановление пароля</StyledHeader>
            <StyledField
                style={{ marginBottom: 22 }}
                type="text"
                name="email"
                label={'E-mail'}
                variant={'filled'}
                value={email}
                onChange={setEmail}
            />
            {error && <StyledErrorLabel>{error}</StyledErrorLabel>}
            <StyledButtonWrap>
                <StyledSubmit rounded size={'md'} loading={isLoading}>
                    Восстановить пароль
                </StyledSubmit>
            </StyledButtonWrap>
        </StyledForm>
    );
};

export { ForgotPasswordForm };
