import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import Modal from 'react-modal';

import { HelmetProvider } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';

import store from './store/initStore';

import { default as Router } from './router';
import GlobalStyles from './components/GlobalStyles';

import reportWebVitals from './reportWebVitals';

import 'react-toastify/dist/ReactToastify.css';

const helmetContext = {};
Modal.setAppElement('#root');

ReactDOM.render(
    <React.StrictMode>
        <HelmetProvider context={helmetContext}>
            <ReduxProvider store={store}>
                <GlobalStyles />
                <Router />
                <ToastContainer position="bottom-right" />
            </ReduxProvider>
        </HelmetProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
