import React, { useState } from 'react';
import { Container, Field, Paper } from 'components';
import { Checkbox } from 'components/Checkbox';
import { HorizontalField } from 'components/HorizontalField';

export const FieldsPlayground = () => {
    const [text, setText] = useState('default text');
    const [checked, setChecked] = useState(false);
    const [checked2, setChecked2] = useState(true);
    const [select, setSelect] = useState('one');
    const [horizontal, setHorizontal] = useState('horizontal');

    return (
        <Container>
            <Paper>
                <Field
                    // disabled
                    required
                    // variant={'filled'}
                    style={{ width: 300 }}
                    hint={'Подсказка тут'}
                    label={'Заголовок поля'}
                    placeholder={'33 м²'}
                    value={text}
                    suffix={'м²'}
                    onChange={(value) => setText(value)}
                />
                <br />
                <Field
                    required
                    // variant={'filled'}
                    style={{ width: 300 }}
                    // hint={'Подсказка тут'}
                    label={'Заголовок поля'}
                    placeholder={'33 м²'}
                    type={'checkbox'}
                    value={checked2}
                    suffix={'м²'}
                    onChange={(value) => setChecked2(value)}
                />
                <br />
                <Field
                    required
                    type={'select'}
                    options={[
                        { label: 'nothing', value: '', disabled: true },
                        'one',
                        'two',
                        {
                            value: 'three',
                            view: (
                                <div>
                                    three <span style={{ color: 'red' }}>3</span>
                                </div>
                            )
                        }
                    ]}
                    variant={'filled'}
                    // disabled
                    style={{ width: 300 }}
                    // hint={'Подсказка тут'}
                    label={'Заголовок поля'}
                    placeholder={'33 м²'}
                    value={select}
                    suffix={'м²'}
                    onChange={(value) => setSelect(value)}
                />
                <br />
                <Checkbox checked={checked} onChange={(value) => setChecked(value)}>
                    this is checkbox
                </Checkbox>
                <div>
                    <HorizontalField
                        type="number"
                        label="Some text"
                        id="idididi"
                        value={horizontal}
                        onChange={(value) => setHorizontal(value)}
                    />
                </div>
            </Paper>
        </Container>
    );
};
