import React, { useState } from 'react';
import { useValues, useActions } from 'kea';
import { useLocation, useHistory } from 'react-router-dom';

import authLogic from 'store/auth';
import userLogic from 'store/user';

import { Button } from 'components/Button';
import { Avatar } from 'components/Avatar';
import { LoginRegModal } from 'components/modals/LoginRegModal';
import { Loader } from 'components/Loader';

import {
    StyledHeaderWrapper,
    StyledHeaderLeftGroup,
    StyledHeaderRightGroup,
    StyledUserInfoWrap,
    StyledAvatarWrap,
    Logo,
    MenuItem
} from './units';

const Header = () => {
    const [loginModal, setLoginModal] = useState('');
    const { isAuth } = useValues(authLogic);
    const { logOut } = useActions(authLogic);
    const { userData, userDataLoading } = useValues(userLogic);
    const location = useLocation();
    const history = useHistory();

    const { first_name: firstName, last_name: lastName, email } = userData;
    return (
        <StyledHeaderWrapper>
            <StyledHeaderLeftGroup>
                <Logo />
                <MenuItem to={'/'}>Главная</MenuItem>
                <MenuItem to={'/about'}>О программе</MenuItem>
                <MenuItem to={'/contacts'}>Контакты</MenuItem>
            </StyledHeaderLeftGroup>
            <StyledHeaderRightGroup>
                {isAuth ? (
                    <>
                        <StyledUserInfoWrap to={'/settings'} replace={location.pathname === '/settings'}>
                            <StyledAvatarWrap>
                                {userDataLoading ? (
                                    <Loader color={'#000'} />
                                ) : (
                                    <Avatar
                                        name={email || firstName + lastName}
                                        text={(firstName && firstName.charAt(0)) + (lastName && lastName.charAt(0))}
                                    />
                                )}
                            </StyledAvatarWrap>
                            {firstName || lastName ? `${firstName} ${lastName}` : 'Неизвестный'}
                        </StyledUserInfoWrap>
                        <Button
                            size={'sm'}
                            outlined
                            onClick={() => {
                                logOut();
                                if (location.pathname !== '/') history.push('/');
                            }}>
                            Выйти
                        </Button>
                    </>
                ) : (
                    <div>
                        <Button style={{ marginRight: 40 }} size={'sm'} onClick={() => setLoginModal('reg')}>
                            Регистрация
                        </Button>
                        <Button size={'sm'} outlined onClick={() => setLoginModal('login')}>
                            Вход
                        </Button>
                    </div>
                )}
                <LoginRegModal
                    isNeedAuth
                    isOpen={!!loginModal}
                    isReg={loginModal === 'reg'}
                    onRequestClose={() => setLoginModal('')}
                />
            </StyledHeaderRightGroup>
        </StyledHeaderWrapper>
    );
};

export { Header };
