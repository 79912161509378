import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useValues, useActions } from 'kea';
import { toast } from 'react-toastify';
import 'react-dadata/dist/react-dadata.css';

import { Field, H3, PaperBlock } from 'components';
import { Matrix } from 'components/Matrix';
import { MemoizedCalcHeadings } from 'components/CalcHeadings';

import { CALC_VISIBILITIES } from 'config';
import { isNil, useAnchor } from 'utils';

import dictsLogic from 'store/dicts';
import calcLogic from 'store/calculation';

import { StyledFieldsGrid, StyledButton, StyledLoader } from './units';
import { AutoComplete } from 'components/AutoComplete';
import { ModalLoader } from 'components/ModalLoader';

import { useAddresses, getComplexAddresses } from 'utils/addressUtils';

import userLogic from 'store/user';

export const Calc1 = ({ history }) => {
    const selectInputRef = useRef();
    const { userData: { role } = {} } = useValues(userLogic);
    const { lifeCycles, rateClasses, housingComplexes } = useValues(dictsLogic);
    const { data, lifeCycleIndex, rateClassIndex, isLoading, calc1Done } = useValues(calcLogic);
    const { setData, createCalculation } = useActions(calcLogic);
    const { getHousingComplexes } = useActions(dictsLogic);

    const [complexAddress, setComplexAddress] = useState();
    const [complex, setComplex] = useState();

    const defaultComplexValue = useMemo(() => {
        const value = data.hcId ? housingComplexes.find((complex) => complex.id === data.hcId) : undefined;
        setComplexAddress(getComplexAddresses(value?.addresses, value?.name));
        return value;
    }, [data.hcId, housingComplexes]);

    const { clearAddressValue, onAddressChange, viewField, ...props } = useAddresses({
        additionalAddresses: complexAddress,
        defaultValue: data.address,
        defaultValueComplex: data.hcId ? data.hcName : undefined
    });

    const activeItem = isNil(lifeCycleIndex) || isNil(rateClassIndex) ? null : [rateClassIndex, lifeCycleIndex];

    const handleMatrixPick = useCallback(
        ([rateClassIndex, lifeCycleIndex]) => {
            setData({ lifeCycle: lifeCycles[lifeCycleIndex].id, rateClass: rateClasses[rateClassIndex].id });
        },
        [lifeCycles, rateClasses, setData]
    );

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!CALC_VISIBILITIES.calc2[role]) {
            const response = await createCalculation();
            if (response?.isError) history.push('/buttons');
        } else {
            history.push('/buttons');
        }
    };

    useAnchor();

    return (
        <form onSubmit={handleSubmit}>
            <MemoizedCalcHeadings />
            <PaperBlock id="rate-n-class">
                <H3>Матрица услуг по управлению и технической эксплуатации МКД</H3>
                {!(lifeCycles.length || rateClasses.length) && <StyledLoader />}
                {!!(lifeCycles.length && rateClasses.length) && (
                    <Matrix
                        activeItem={activeItem}
                        onChange={handleMatrixPick}
                        stages={lifeCycles}
                        tariffs={rateClasses}
                    />
                )}
            </PaperBlock>
            <PaperBlock id="address">
                <H3>Адрес объекта</H3>
                {housingComplexes.length ? (
                    <StyledFieldsGrid>
                        <AutoComplete
                            isCreatable
                            isValidNewOption={(inputValue) =>
                                inputValue &&
                                !housingComplexes.find(
                                    (complex) => complex.name?.toLowerCase().indexOf(inputValue?.toLowerCase()) >= 0
                                )
                            }
                            getNewOptionData={(inputValue) => ({
                                id: inputValue,
                                name: 'Внести новый ЖК "' + inputValue + '"'
                            })}
                            onCreateOption={(value) => {
                                if (value) {
                                    clearAddressValue();
                                    selectInputRef.current?.select.select.clearValue();
                                    document.querySelector('#address-input').focus();
                                }
                                setData({
                                    hcId: undefined,
                                    hcName: value
                                });
                                setComplex({
                                    id: value,
                                    name: value,
                                    created: true
                                });
                                setComplexAddress(getComplexAddresses(undefined, undefined));
                            }}
                            value={complex}
                            defaultValue={defaultComplexValue}
                            getOptionValue={(option) => option.id}
                            placeholder={'Жилой комплекс'}
                            variant={'filled'}
                            onChange={(value) => {
                                if (value) {
                                    clearAddressValue();
                                    selectInputRef.current?.select.select.clearValue();
                                    document.querySelector('#address-input').focus();
                                }
                                setComplex(value);
                                setData({
                                    hcId: value?.id,
                                    hcName: value?.name
                                });
                                setComplexAddress(getComplexAddresses(value?.addresses, value?.name));
                            }}
                            getOptionLabel={(option) => option.name}
                            cacheOptions={false}
                            getSuggestions={getHousingComplexes}
                        />
                        <Field
                            value={data.zipCode}
                            variant="filled"
                            style={{ pointerEvents: 'none' }}
                            {...{ key: 'zipCode', label: 'Индекс (поле заполнится автоматически)', tabIndex: '-1' }}
                        />
                        <div style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
                            {viewField ? (
                                <AutoComplete
                                    selectRef={selectInputRef}
                                    inputId="address-input"
                                    id="address-select"
                                    instanceId="address-auto-complete"
                                    variant={'filled'}
                                    required={true}
                                    onChange={(value) => {
                                        onAddressChange(value);

                                        const { house, postal_code, housingComplex } = value?.data || {};

                                        if (!housingComplex && data.hcId && value) {
                                            toast.error(
                                                `Данного адреса нет в ЖК ${
                                                    complex?.name || defaultComplexValue.name
                                                }, поэтому мы сбросили ЖК`
                                            );
                                            setComplex(null);
                                            setData({
                                                hcName: undefined,
                                                hcId: undefined
                                            });
                                            setComplexAddress(getComplexAddresses(undefined, undefined));
                                        }

                                        setData({ zipCode: postal_code || null });

                                        if (house || housingComplex) {
                                            setData({
                                                address: value?.unrestricted_value
                                            });
                                        } else {
                                            setData({ address: null });
                                        }
                                    }}
                                    {...props}
                                />
                            ) : (
                                <StyledLoader />
                            )}
                        </div>
                    </StyledFieldsGrid>
                ) : (
                    <StyledLoader />
                )}
                <StyledButton disabled={!calc1Done} size="md-lg" rounded>
                    Продолжить
                </StyledButton>
            </PaperBlock>
            {isLoading && <ModalLoader />}
        </form>
    );
};

export default Calc1;
