import React from 'react';
import { withRouter } from 'react-router-dom';
import { Layout } from '../../components/Layout';

export default withRouter(({ children, location }) => {
    return location && location.state && location.state.notFoundError ? (
        <Layout>
            <div>404 - Не найдено</div>
        </Layout>
    ) : (
        children
    );
});
