import styled from '@emotion/styled';
import colors from 'colors';

export const StyledWrap = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledAboutWrap = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 800px;
    font-size: 16px;
    line-height: 24px;
`;

export const StyledLink = styled.a`
    display: inline-block;
    text-decoration: none;
    font-weight: bold;
    color: ${colors.main_400};
    &:hover {
        text-decoration: underline;
    }
    &:focus {
        text-decoration: underline;
    }
`;

export const StyledSectionHeader = styled.div`
    font-weight: 600;
    font-size: 24px;
    line-height: 64px;
    color: ${colors.main_600};
    //padding: 0 100px;
    ${({ isFirst }) => {
        return !isFirst && { marginTop: 32 };
    }}
`;

export const StyledSectionText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 16px;
    line-height: 24px;
    & ol,
    & ul {
        margin-bottom: 24px;
    }
    & li {
        margin-top: 24px;
    }
    //padding: 0 100px;
`;
