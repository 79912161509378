import React from 'react';
import styled from '@emotion/styled';

import { colors } from 'colors';
import { hexToRGBA } from 'utils';

import { ScrollHider } from 'components/ScrollHider';
import { Modal } from 'components/modals/Modal';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

const StyledModal = styled(Modal)`
    &__Content {
        position: relative;
        background: ${colors.grayScale_0};
        overflow: auto;
        outline: none;
        border-radius: 5px;
        padding: 100px;
        margin: auto;
        ${({ contentStyles }) => contentStyles};
    }
    &__Overlay {
        position: fixed;
        z-index: 999999;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: ${hexToRGBA(colors.grayScale_800, 0.5)};
        display: flex !important;
        align-items: center;
        overflow-y: scroll;
        padding: 100px;
        opacity: 0;
        transition: opacity 300ms ease-in-out;
    }

    &__Overlay.ReactModal__Overlay--after-open {
        opacity: 1;
    }

    &__Overlay.ReactModal__Overlay--before-close {
        opacity: 0;
    }
`;

const StyledCloseIcon = styled(CloseIcon)`
    position: absolute;
    top: 35px;
    right: 35px;
    cursor: pointer;
    z-index: 100;
`;

export const GlobalModal = React.forwardRef(({ children, closeIcon = true, onRequestClose, ...props }, ref) => (
    <StyledModal {...{ ref, onRequestClose, ...props }}>
        {children}
        <ScrollHider />
        {closeIcon && onRequestClose && <StyledCloseIcon onClick={onRequestClose} />}
    </StyledModal>
));
